import { english, type Localisation } from "./en";

export const englishUS: Localisation = {
  ...english,
  dateFormats: {
    ...english.dateFormats,
    datePickerLocale: "en",
    timeFormat: "h:mm a", // 2:30 pm
    dayMonthFormat: "MMMM do", // April 1st
    dayMonthYearFormat: "MMMM do yyyy", // January 11th 1988
    dayDateMonthYearFormat: "E, MMMM do yyyy", // Mon, January 11th 1988
    dayMonthYearTimeFormat: "MMMM do yyyy, h:mm a", // January 11th 1988, 2:30 pm
  },
  Layout: {
    ...english.Layout,
    "Organisation settings": "Organization settings",
    "Switch organisation": "Switch organization",
  },
  DevicesScreen: {
    ...english.DevicesScreen,
    "Switch organisation": "Switch organization",
  },
  ThemeScreen: {
    ...english.ThemeScreen,
    "Themes allow you to customise the colours of your configurations.":
      "Themes allow you to customize the colors of your configurations.",
    "Themes allow you to customise the colours used by your templates":
      "Themes allow you to customize the colors used by your templates",
    "Themes allow you to customise the appearance of your configurations":
      "Themes allow you to customize the appearance of your configurations",
    "Some configurations make use of a background image. If an image is not uploaded, the background colour selected below will be used.":
      "Some configurations make use of a background image. If an image is not uploaded, the background color selected below will be used.",
    "Customise the colours you want to use":
      "Customize the colors you want to use",
    "The 'text' options should be a contrasting colour, to ensure it can be easily read.":
      "The 'text' options should be a contrasting color, to ensure it can be easily read.",
    "Some content appears in containers that are semi transparent and overlayed on your chosen background colour.":
      "Some content appears in containers that are semi transparent and overlayed on your chosen background color.",
    "Use organisation logo": "Use organization logo",
  },
  ConfigsScreen: {
    ...english.ConfigsScreen,
    "Configurations allow you to customise the look and content of your displays.":
      "Configurations allow you to customize the look and content of your displays.",
  },
  OrganisationSwitcher: {
    ...english.OrganisationSwitcher,
    "Fetching organisations": "Fetching organizations",
    "Select an organisation": "Select an organization",
  },
  SettingsScreen: {
    ...english.SettingsScreen,
    "Organisation settings": "Organization settings",
  },
  ScreenConfigDrawer: {
    ...english.ScreenConfigDrawer,
    "The theme will set colours and logo for your configuration":
      "The theme will set colors and logo for your configuration",
  },
};
