/**
 * @generated SignedSource<<5f92635ad28b42a4c047bb63c24e52c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AccessibleWebApp = "academics" | "ams" | "arms" | "communities" | "gm" | "hub" | "inflcr" | "nutrition" | "pathways" | "pulse" | "strength" | "trac" | "tv" | "wallet" | "%future added value";
export type Role = "admin" | "superadmin" | "%future added value";
export type CurrentProviderQuery$variables = Record<PropertyKey, never>;
export type CurrentProviderQuery$data = {
  readonly currentOrganisation: {
    readonly activeFeatureFlags: ReadonlyArray<string>;
    readonly id: string;
    readonly name: string;
    readonly navbarBottomColor: string;
    readonly navbarImageUrl: string | null | undefined;
    readonly navbarTopColor: string;
    readonly permittedIpAddresses: ReadonlyArray<string>;
  };
  readonly currentUser: {
    readonly accessibleWebApps: ReadonlyArray<AccessibleWebApp>;
    readonly avatarUrl: string | null | undefined;
    readonly fullName: string;
    readonly hubAccess: boolean;
    readonly id: string;
    readonly initials: string;
    readonly nutritionAccess: boolean;
    readonly role: Role;
  };
};
export type CurrentProviderQuery = {
  response: CurrentProviderQuery$data;
  variables: CurrentProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "initials",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "avatarUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accessibleWebApps",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nutritionAccess",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hubAccess",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "currentOrganisation",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "navbarBottomColor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "navbarTopColor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "navbarImageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permittedIpAddresses",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "activeFeatureFlags",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CurrentProviderQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CurrentProviderQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fc42e65961106cfb80178e2430670cef",
    "id": null,
    "metadata": {},
    "name": "CurrentProviderQuery",
    "operationKind": "query",
    "text": "query CurrentProviderQuery {\n  currentUser {\n    id\n    fullName\n    initials\n    avatarUrl\n    accessibleWebApps\n    role\n    nutritionAccess\n    hubAccess\n  }\n  currentOrganisation {\n    id\n    name\n    navbarBottomColor\n    navbarTopColor\n    navbarImageUrl\n    permittedIpAddresses\n    activeFeatureFlags\n  }\n}\n"
  }
};
})();

(node as any).hash = "cc8895e36378a9f2476244760acd5b31";

export default node;
