import { type Localisation } from "./en";

export const german: Localisation = {
  dateFormats: {
    datePickerLocale: "de",
    timeFormat: "HH:mm", // 14:30
    dayFormat: "do", // 12th
    dayMonthFormat: "do MMMM", // 1st April
    dayMonthYearFormat: "do MMMM yyyy", // 11th January 1988
    dayDateMonthYearFormat: "E, do MMMM yyyy", // Mon, 1st April 2024
    dayMonthYearTimeFormat: "do MMMM yyyy, HH:mm", // 11th January 1988, 14:30
  },
  App: {
    "Digital Displays": "Digitale Anzeigen",
    Loading: "die Beladung",
    "Checking authentication": "Authentifizierung prüfen",
    "Redirecting to login": "Weiterleitung zum Login",
    "Sign in with Teamworks": "Melden Sie sich mit Teamworks an",
    Initializing: "Initialisierung",
    "Verifing authentication": "Authentifizierung überprüfen",
    Redirecting: "Umleiten",
    "Welcome back!": "Willkommen zurück!",
    "Login to get started": "Melden Sie sich an, um loszulegen",
  },
  AppSwitcher: {
    arms: "Compliance + Recruiting",
    hub: "Hub",
    inflcr: "Influencer",
    academics: "Academics",
    communities: "Communities",
    pulse: "Pulse",
    pathways: "Pathways",
    nutrition: "Nutrition",
    ams: "AMS",
    tv: "Digital Displays",
    trac: "Inventory Management",
    strength: "Strength + Conditioning",
    wallet: "Wallet",
    gm: "General Manager",
  },
  Layout: {
    "Checking authentication": "Authentifizierung prüfen",
    Dashboard: "Armaturenbrett",
    Configurations: "Konfigurationen",
    Devices: "Geräte",
    Themes: "Themen",
    Settings: "Einstellungen",
    Announcements: "Ankündigungen",
    "Organisation settings": "Organisationseinstellungen",
    "Teamworks Help": "Teamworks-Hilfe",
    "Teamworks Apps": "Teamworks-Apps",
    "Privacy policy": "Datenschutzrichtlinie",
    Logout: "Abmelden",
    "Loading, please wait...": "Laden, bitte warten...",
    Users: "Benutzer",
    "Switch organisation": "Organisation wechseln",
    "Signing you out": "Ich melde dich ab",
    "Open Menu": "Menü öffnen",
    "Close Menu": "Menü schließen",
    Playlists: "Wiedergabelisten",
  },
  ConfigsScreen: {
    "{{count}} Configuration": "{{count}} Konfiguration",
    "{{count}} Configuration_other": "{{count}} Konfigurationen",
    "New configuration": "Neue Konfiguration",
    "Configurations allow you to customise the look and content of your displays.":
      "Mit Konfigurationen können Sie das Aussehen und den Inhalt Ihrer Anzeigen anpassen.",
    "Loading configurations": "Konfigurationen werden geladen",
    Configurations: "Konfigurationen",
    Name: "Name",
    Tags: "Tags",
    Template: "Vorlage",
    Theme: "Thema",
    "Device count": "Geräteanzahl",
    "Created at": "Erstellt bei",
    "No configurations have been setup yet":
      "Es wurden noch keine Konfigurationen eingerichtet",
    "No configurations found for '{{searchText}}'":
      "Keine Konfigurationen für '{{searchText}}' gefunden",
    "Displays allow you to cast scheduled events and media to any device with an internet browser":
      "Mit Displays können Sie geplante Ereignisse und Medien auf jedes Gerät mit einem Internetbrowser übertragen",
    "Create one now": "Erstellen Sie jetzt eines",
    "Create configuration": "Konfiguration erstellen",
    "Add custom videos, YouTube videos and images":
      "Fügen Sie benutzerdefinierte Videos, YouTube-Videos und Bilder hinzu",
    "Filter calendar to present a live schedule":
      "Filtern Sie den Kalender, um einen Live-Zeitplan anzuzeigen",
    "Display weather for multiple locations":
      "Zeigen Sie das Wetter für mehrere Standorte an",
    "A general overview for up to a week in advance":
      "Ein allgemeiner Überblick für bis zu eine Woche im Voraus",
    "Show temperature, wind and probability of precipitation":
      "Temperatur, Wind und Niederschlagswahrscheinlichkeit anzeigen",
    "Cycle through important updates on cards":
      "Blättern Sie durch wichtige Kartenaktualisierungen",
    "Includes team crest, time and date":
      "Enthält Teamwappen, Uhrzeit und Datum",
    "Display a schedule over multiple days":
      "Zeigen Sie einen Zeitplan über mehrere Tage an",
    "Display daily schedule for different teams and groups.":
      "Zeigen Sie den Tagesplan für verschiedene Teams und Gruppen an.",
    "Configuration deleted": "Konfiguration gelöscht",
    "Are you sure you want to delete this configuration?":
      "Sind Sie sicher, dass Sie diese Konfiguration löschen möchten?",
    "Delete configuration": "Konfiguration löschen",
    "Fetching configuration": "Konfiguration abrufen",
    "Something went wrong": "Etwas ist schief gelaufen",
    "Edit configuration": "Konfiguration bearbeiten",
    "Includes background image from theme":
      "Enthält ein Hintergrundbild aus dem Thema",
    "Last active at {{lastActiveAt}}": "Zuletzt aktiv um {{lastActiveAt}}",
    "Loading screen devices": "Bildschirmgeräte werden geladen",
    "Search configurations...": "Konfigurationen suchen...",
    "Save changes": "Änderungen speichern",
    "Last updated": "Zuletzt aktualisiert",
    "Clear template": "Klare Vorlage",
    "No template selected": "Keine Vorlage ausgewählt",
    "Display a full menu or an individual station":
      "Zeige ein vollständiges Menü oder eine einzelne Station",
    "Optionally show images, descriptions and macros":
      "Optional Bilder, Beschreibungen und Makronährstoffe anzeigen",
  },
  DashboardScreen: { Dashboard: "Armaturenbrett" },
  DevicesScreen: {
    Devices: "Geräte",
    Groups: "Gruppen",
    "{{count}} Device": "{{count}} Gerät",
    "{{count}} Device_other": "{{count}} Geräte",
    "New device": "Neues Gerät",
    "Devices are the physical displays you have linked to your account.":
      "Geräte sind die physischen Displays, die Sie mit Ihrem Konto verknüpft haben.",
    "No devices have been setup yet":
      "Es wurden noch keine Geräte eingerichtet",
    "No devices found for '{{searchText}}'":
      "Keine Geräte für '{{searchText}}' gefunden",
    "Device name": "Gerätename",
    Facility: "Einrichtung",
    Configuration: "Konfiguration",
    "Facility not set": "Einrichtung nicht eingestellt",
    "Config not set": "Konfiguration nicht festgelegt",
    "Create device": "Gerät erstellen",
    "Update device": "Gerät aktualisieren",
    "Screen device updated": "Bildschirmgerät aktualisiert",
    "Something went wrong": "Etwas ist schief gelaufen",
    "Adding new display device": "Neues Anzeigegerät hinzufügen",
    addNewDeviceDescription:
      "Um ein neues Gerät hinzuzufügen, besuchen Sie <0>{{url}}</0> auf dem Gerät, das Sie verknüpfen möchten.",
    "You will be shown a QR code which can be scanned by your camera.":
      "Ihnen wird ein QR-Code angezeigt, der von Ihrer Kamera gescannt werden kann.",
    "Start scanning": "Beginnen Sie mit dem Scannen",
    Ok: "OK",
    "Are you sure you want to delete this device?":
      "Möchten Sie dieses Gerät wirklich löschen?",
    "Delete device": "Gerät löschen",
    "Fetching devices": "Geräte abrufen",
    Timezone: "Zeitzone",
    "Display name is required": "Anzeigename ist erforderlich",
    "Display name must be at least 3 characters long":
      "Der Anzeigename muss mindestens 3 Zeichen lang sein",
    "Device setup successful": "Geräteeinrichtung erfolgreich",
    "Switch organisation": "Organisation wechseln",
    "Search devices...": "Geräte suchen...",
    "Device not found": "Gerät nicht gefunden",
    "It may have been deleted, or you do not have permission to view it.":
      "Möglicherweise wurde es gelöscht oder Sie haben keine Berechtigung, es anzuzeigen.",
    "Loading device": "Ladevorrichtung",
    "Last updated": "Zuletzt aktualisiert",
    "Playlist not set": "Playlist nicht eingestellt",
    "Bulk actions": "Bulk Aktionen",
    "Edit selected": "Ausgewählte bearbeiten",
    "Delete selected": "Ausgewählte löschen",
    "Delete {{count}} devices": "Löschen Sie {{count}} Geräte",
    "Are you sure you want to delete these devices?":
      "Sind Sie sicher, dass Sie diese Geräte löschen möchten?",
    "Devices deleted": "Gelöschte Geräte",
    "Screen devices updated": "Bildschirmgeräte aktualisiert",
    "Update {{count}} devices": "Aktualisieren Sie {{count}} Geräte",
    "Device deleted": "Gerät gelöscht",
    "Select all": "Alle auswählen",
    "Deselect all": "Alle abwählen",
    "Create virtual device": "Virtuelles Gerät erstellen",
    "Virtual device URL": "URL des virtuellen Geräts",
    "This URL can be used to integrate with systems that do not support our QR code setup.":
      "Diese URL kann verwendet werden, um mit Systemen zu integrieren, die unser QR-Code-Setup nicht unterstützen.",
    "Note: This URL will only be accessable from networks with an approved IP address.":
      "Hinweis: Diese URL ist nur von Netzwerken mit zugelassener IP-Adresse zugänglich.",
    or: "oder",
    "Provides a hardcoded URL, for use with systems which do not support the QR setup":
      "Stellt eine festgelegte URL bereit, für die Verwendung mit Systemen, die die QR-Einrichtung nicht unterstützen",
    "Manage schedule": "Zeitplan verwalten",
    "Please correct any errors and try again":
      "Bitte korrigieren Sie alle Fehler und versuchen Sie es erneut",
    "Skip interaction screen": "Interaktionsbildschirm überspringen",
    skip_interaction_description:
      "Der Interaktionsbildschirm zeigt eine Schaltfläche an, die gedrückt werden muss, bevor Inhalte angezeigt werden. Dies hilft, Autoplay-Probleme in modernen Browsern zu verhindern, ist jedoch möglicherweise für einige Systeme nicht erforderlich.",
  },
  DeviceModePicker: {
    "Device mode": "Gerätemodus",
    "Should this device display a configuration or a playlist?":
      "Soll dieses Gerät eine Konfiguration oder eine Wiedergabeliste anzeigen?",
    "Should this device display a configuration or a playlist?_other":
      "Sollen diese Geräte eine Konfiguration oder eine Wiedergabeliste anzeigen?",
    config: "Konfiguration",
    playlist: "Wiedergabeliste",
  },
  NotFoundScreen: {
    "Oops! You seem to be lost.": "Hoppla! Du scheinst verloren zu sein.",
    "Return to dashboard": "Zurück zum Dashboard",
  },
  ThemeScreen: {
    "New theme": "Neues Thema",
    "Themes allow you to customise the colours of your configurations.":
      "Mit Themes können Sie die Farben Ihrer Konfigurationen anpassen.",
    Themes: "Themen",
    "You don't have any themes yet": "Sie haben noch keine Themen",
    "Themes allow you to customise the colours used by your templates":
      "Mit Themes können Sie die von Ihren Vorlagen verwendeten Farben anpassen",
    "Create one now": "Erstellen Sie jetzt eines",
    "Theme name": "Themenname",
    "Last updated": "Zuletzt aktualisiert",
    "Create theme": "Thema erstellen",
    "Update theme": "Thema aktualisieren",
    background: "Hintergrund",
    backgroundText: "Hintergrundtext",
    accent: "Akzent",
    accentText: "Akzenttext",
    announcementBar: "Ankündigungsleiste",
    announcementBarText: "Text der Ankündigungsleiste",
    "Invalid hex code": "Ungültiger Hex-Code",
    "New theme created": "Neues Theme erstellt",
    "Theme updated": "Thema aktualisiert",
    "Screen theme deleted": "Bildschirmthema gelöscht",
    "Something went wrong": "Etwas ist schief gelaufen",
    "Are you sure you want to delete this theme?":
      "Möchten Sie dieses Thema wirklich löschen?",
    "Delete theme": "Theme löschen",
    "Loading themes": "Themen werden geladen",
    "Upload logo": "Logo hochladen",
    "Remove logo": "Logo entfernen",
    "Upload background image": "Hintergrundbild hochladen",
    "Remove image": "Bild entfernen",
    "Theme not found": "Thema nicht gefunden",
    "It may have been deleted, or you do not have permission to view it":
      "Möglicherweise wurde es gelöscht oder Sie haben keine Berechtigung, es anzuzeigen",
    "Loading theme": "Thema wird geladen",
    "Search themes...": "Themen durchsuchen...",
    "{{count}} Theme": "{{count}} Thema",
    "{{count}} Theme_other": "{{count}} Themen",
    "What's a theme?": "Was ist ein Thema?",
    "Themes allow you to customise the appearance of your configurations":
      "Mit Themes können Sie das Erscheinungsbild Ihrer Konfigurationen anpassen",
    "Clicking this button will begin the process of creating a new one.":
      "Wenn Sie auf diese Schaltfläche klicken, wird mit der Erstellung eines neuen Formulars begonnen.",
    "Give your theme a memorable name":
      "Geben Sie Ihrem Thema einen einprägsamen Namen",
    "This will help you find it later when assigning it to a configuration.":
      "Dies erleichtert Ihnen das spätere Auffinden, wenn Sie es einer Konfiguration zuweisen.",
    "Upload a logo (optional)": "Laden Sie ein Logo hoch (optional)",
    "If you do not upload a logo, a Teamworks logo will appear in its place.":
      "If you do not upload a logo, a Teamworks logo will appear in its place.",
    "Upload a background image (optional)":
      "Upload a background image (freiwillig)",
    "Some configurations make use of a background image. If an image is not uploaded, the background colour selected below will be used.":
      "Einige Konfigurationen nutzen ein Hintergrundbild. Wenn kein Bild hochgeladen wird, wird die unten ausgewählte Hintergrundfarbe verwendet.",
    "Customise the colours you want to use":
      "Passen Sie die Farben an, die Sie verwenden möchten",
    "The 'text' options should be a contrasting colour, to ensure it can be easily read.":
      "Die 'Textoptionen' sollten eine Kontrastfarbe haben, um eine gute Lesbarkeit zu gewährleisten.",
    "Once you are ready, save the theme by clicking this button":
      "Wenn Sie fertig sind, speichern Sie das Thema, indem Sie auf diese Schaltfläche klicken",
    "Your theme will then be available to be selected when managing a configuration.":
      "Ihr Thema steht dann zur Auswahl beim Verwalten einer Konfiguration zur Verfügung.",
    "Theme contrast": "Themenkontrast",
    "Some content appears in containers that are semi transparent and overlayed on your chosen background colour.":
      "Einige Inhalte werden in halbtransparenten Containern angezeigt, die über der von Ihnen gewählten Hintergrundfarbe liegen.",
    "Would you like to lighten or darken your background?":
      "Möchten Sie Ihren Hintergrund aufhellen oder abdunkeln?",
    Dark: "Dunkel",
    Light: "Licht",
    "Theme preview": "Theme preview",
    Close: "Close",
    Preview: "Preview",
    "No themes found for '{{searchText}}'":
      "Keine Themen für '{{searchText}}' gefunden",
    or: "oder",
    "Use organisation logo": "Organisationslogo verwenden",
  },
  ScreenTemplateNames: {
    "L shape with media": "L-Form mit Medien",
    "Media only": "Nur Medien",
    "Schedule only": "Nur Zeitplan",
    "Multi day schedule": "Mehrtägiger Zeitplan",
    "Multi column schedule": "Mehrspaltiger Zeitplan",
    Basic: "Basic",
    Nutrition: "Ernährung",
    Weather: "Wetter",
  },
  DialogProvider: {
    Cancel: "Stornieren",
    Confirm: "Bestätigen",
    Close: "Schließen",
    Ok: "OK",
    "Close without saving?": "Schließen ohne zu speichern?",
    "Close without saving": "Schließen ohne zu speichern",
    "Any unsaved changes will be lost":
      "Alle nicht gespeicherten Änderungen gehen verloren",
    "Back to form": "Zurück zur Form",
  },
  ConfigurationPicker: {
    Configuration: "Konfiguration",
    "No configurations found for '{{searchText}}'":
      "Keine Konfigurationen für'{{searchText}}' gefunden",
    "No configurations found": "Keine Konfigurationen gefunden",
    "No configuration selected": "Keine Konfiguration ausgewählt",
    "Clear configuration": "Übersichtliche Konfiguration",
    "Searching configurations": "Suche nach Konfigurationen",
  },
  ThemePicker: {
    Theme: "Thema",
    "Clear theme": "Klares Thema",
    "Screen theme": "Bildschirmthema",
    "No theme selected": "Kein Thema ausgewählt",
    "No themes found matching '{{searchText}}'":
      "Es wurden keine Themen gefunden, die zu '{{searchText}}' passen",
    "No themes found": "Keine Themen gefunden",
    "Loading themes...": "Themen werden geladen...",
  },
  MarkdownField: {
    Preview: "Vorschau",
    Remove: "Entfernen",
    "Nothing to preview": "Nichts zur Vorschau",
    Write: "Schreiben",
  },
  ErrorScreen: {
    "Something went wrong": "Etwas ist schief gelaufen",
    "Sorry for the inconvenience, our engineers have been notified and will be working on a fix":
      "Entschuldigung für die Unannehmlichkeiten, unser Team hat dieses Problem bemerkt und wird es schnellstmöglich beheben",
    "If this issue persists, please contact Teamworks customer support":
      "Wenn das Problem weiterhin besteht, wenden Sie sich bitte an den Teamworks-Kundendienst",
    Reload: "Neu laden",
    "Customer support": "Kundendienst",
  },
  OrganisationSwitcher: {
    "Fetching organisations": "Organisationen abrufen",
    "Select an organisation": "Wählen Sie eine Organisation aus",
    "Switching profile": "Profil wechseln",
  },
  TimeZonePicker: {
    Timezone: "Zeitzone",
  },
  MatchesDrawer: {
    "Add match": "Übereinstimmung hinzufügen",
    "Fetching event": "Ereignis abrufen",
    "Event title": "Veranstaltungstitel",
    "Starts at": "Beginnt um",
    "Create event": "Veranstaltung erstellen",
  },
  FileUploadField: {
    "Upload file": "Datei hochladen",
    "Remove file": "Datei entfernen",
  },
  HubProfilePicker: {
    Search: "Suchen",
    "Clear search text": "Suchtext löschen",
    "Search text too short": "Suchtext zu kurz",
    "Please add at least 3 characters to begin a search":
      "Bitte fügen Sie mindestens 3 Zeichen hinzu, um eine Suche zu starten",
    "No calendars found": "Keine Kalender gefunden",
    "We couldn't find any calendars matching '{{searchText}}'":
      "Wir konnten keine Kalender finden, die mit '{{searchText}}' übereinstimmen",
    "Search...": "Suchen...",
    "Confirm selection": "Auswahl bestätigen",
    Close: "Schließen",
    "Add Teamworks calendars": "Fügen Sie Teamworks-Kalender hinzu",
    "Loading profiles": "Profile laden",
    "Search text must have at least 3 characters":
      "Der Suchtext muss mindestens 3 Zeichen lang sein",
    "No results found": "Keine Ergebnisse gefunden",
    "Calendar details": "Kalenderdetails",
    "Remove calendar": "Kalender entfernen",
    "Are you sure you want to remove this calendar?":
      "Möchten Sie diesen Kalender wirklich entfernen?",
    "No calendars added": "Keine Kalender hinzugefügt",
    "Unknown profile": "Unbekanntes Profil",
    "You don't have access to this hub profile":
      "Sie haben keinen Zugriff auf dieses Hub-Profil",
    "Your administrator may need to review your permission groups.":
      "Ihr Administrator muss möglicherweise Ihre Berechtigungsgruppen überprüfen.",
    "Show {{count}} more": "Zeige {{count}} mehr",
  },
  ScreenEventsPicker: {
    "Loading events": "Ereignisse werden geladen",
    "Event countdown": "Countdown zum Ereignis",
    "Create event": "Veranstaltung erstellen",
    "Update event": "Ereignis aktualisieren",
    "Event title": "Veranstaltungstitel",
    "Starts at": "Beginnt um",
    "Add opponent badge": "Gegnerabzeichen hinzufügen",
    "Remove opponent badge": "Gegnerabzeichen entfernen",
    "Delete event": "Veranstaltung löschen",
    "Are you sure you want to delete this event?":
      "Sind Sie sicher, dass Sie dieses Ereignis löschen möchten?",
    "Delete now": "Jetzt löschen",
    "Opponent badge position": "Position des gegnerischen Abzeichens",
    "Should the opponent badge be on the left or right?":
      "Sollte das Gegnerabzeichen links oder rechts angebracht sein?",
    "i.e are you playing home or away?":
      "d.h. spielst du zu Hause oder auswärts?",
    "Edit event": "Veranstaltung bearbeiten",
  },
  ScreenConfigDrawer: {
    "Loading configuration": "Konfiguration wird geladen",
    "Number of days the schedule should span. Max 5":
      "Anzahl der Tage, die der Zeitplan umfassen soll. Maximal 5",
    "Number of days": "Anzahl der Tage",
    "Delete configuration": "Konfiguration löschen",
    "Are you sure you want to delete this configuration?":
      "Sind Sie sicher, dass Sie diese Konfiguration löschen möchten?",
    "Configuration deleted": "Konfiguration gelöscht",
    "Something went wrong": "Etwas ist schief gelaufen",
    "Save changes": "Änderungen speichern",
    "Configuration updated": "Konfiguration aktualisiert",
    cards: "Karten",
    cards_desc: "Wählen Sie Karten aus, um Text oder Bilder hinzuzufügen",
    media: "Medien",
    media_desc: "Wählen Sie aus Videos, Bildern und YouTube-Inhalten",
    multi_column_schedule: "Mehrspaltiger Zeitplan",
    multi_column_schedule_desc:
      "Wählen Sie Teamworks-Kalender aus, die auf dem Bildschirm angezeigt werden sollen",
    multi_day_schedule: "Mehrtägiger Zeitplan",
    multi_day_schedule_desc:
      "Wählen Sie Teamworks-Kalender aus, die auf dem Bildschirm angezeigt werden sollen",
    schedule: "Zeitplan",
    schedule_desc:
      "Wählen Sie Teamworks-Kalender aus, die auf dem Bildschirm angezeigt werden sollen",
    nutrition_menu: "Ernährungsmenü",
    nutrition_menu_desc: "Wählen Sie das Menü aus, das Sie anzeigen möchten",
    nutrition_dining_station: "Ernährungsstation",
    nutrition_dining_station_desc:
      "Wählen Sie die Essensstation aus, die Sie anzeigen möchten",
    weather: "Wetter",
    weather_desc: "Zeigen Sie das Wetter für mehrere Standorte an",
    "%future added value": "Unbekannte Komponente",
    "%future added value_desc": "Unbekannte Komponente",
    "Configuration name": "Konfigurationsname",
    "Add event countdown": "Ereignis-Countdown hinzufügen",
    "A countdown will be displayed until the event starts":
      "Bis zum Beginn der Veranstaltung wird ein Countdown angezeigt",
    "Not saved": "Nicht gespeichert",
    "Please fix any errors and try again":
      "Bitte beheben Sie alle Fehler und versuchen Sie es erneut",
    "Give the configuration a name": "Geben Sie der Konfiguration einen Namen",
    "Try to give it a relevant name, to help you quickly find it":
      "Geben Sie ihm einen passenden Namen, damit Sie ihn schnell finden",
    "Pick a theme": "Wählen Sie ein Thema",
    "The theme will set colours and logo for your configuration":
      "Das Thema legt Farben und Logo für Ihre Konfiguration fest",
    "Add an event (optional)": "Eine Veranstaltung hinzufügen (fakultativ)",
    "A countdown will be displayed on the configuration until the event starts":
      "Auf der Konfiguration wird ein Countdown angezeigt, bis die Veranstaltung beginnt",
    "Add a Teamworks schedule": "Fügen Sie einen Teamworks-Zeitplan hinzu",
    "Upload media": "Medien hochladen",
    "Add cards (optional)": "Karten hinzufügen (fakultativ)",
    "Add multiple Teamworks schedules":
      "Fügen Sie mehrere Teamworks-Zeitpläne hinzu",
    "Unknown component": "Unbekannte Komponente",
    "Events from the selected profiles will be shown on the configuration":
      "Ereignisse aus den ausgewählten Profilen werden in der Konfiguration angezeigt",
    "You can upload images, videos or add YouTube videos":
      "Sie können Bilder und Videos hochladen oder YouTube-Videos hinzufügen",
    "The configuration will rotate through the cards one at a time":
      "Die Konfiguration wechselt nacheinander durch die Karten",
    "Each schedule will have its own column on the configuration":
      "Jeder Zeitplan verfügt über eine eigene Spalte in der Konfiguration",
    "A multiday schedule will display the events from the selected profiles across multiple days":
      "Ein mehrtägiger Zeitplan zeigt die Ereignisse der ausgewählten Profile über mehrere Tage hinweg an",
    "Save the configuration": "Speichern Sie die Konfiguration",
    "Once you are happy with your updates, save the changes":
      "Sobald Sie mit Ihren Aktualisierungen zufrieden sind, übernehmen Sie die Änderungen",
    "Take a tour": "Machen Sie eine Tour",
    "Remove component": "Komponente entfernen",
    "Are you sure you want to remove this component?":
      "Sind Sie sicher, dass Sie diese Komponente entfernen möchten?",
    "Add weather": "Wetter hinzufügen",
    "A weather widget will be displayed to show current weather":
      "Ein Wetter-Widget wird angezeigt, um das aktuelle Wetter anzuzeigen",
    "Hide past events": "Vergangene Ereignisse ausblenden",
    "Announcements with matching tags will be displayed on screen":
      "Ankündigungen mit passenden Tags werden auf dem Bildschirm angezeigt",
    "Display weather information for multiple locations across different days":
      "Wetterinformationen für mehrere Standorte an verschiedenen Tagen anzeigen",
    "Grace period (minutes)": "Verzögerung (Minuten)",
    "Number of minutes to keep events on screen after they have ended":
      "Anzahl der Minuten, um Ereignisse auf dem Bildschirm nach Ende der Veranstaltung zu halten",
  },
  AnnouncementsScreen: {
    "Announcement text is required": "Ankündigungstext ist erforderlich",
    "Announcements updated": "Ankündigungen aktualisiert",
    "Something went wrong": "Etwas ist schief gelaufen",
    "Announcements allow you to post important information to be displayed in banners across all devices.":
      "Mit Ankündigungen können Sie wichtige Informationen veröffentlichen, die in Bannern auf allen Geräten angezeigt werden.",
    Announcements: "Ankündigungen",
    "Loading announcements": "Ankündigungen werden geladen",
    "Manage announcements": "Ankündigungen verwalten",
    "Announcements are displayed on all screens, at all times":
      "Ankündigungen werden jederzeit auf allen Bildschirmen angezeigt",
    "No announcements yet": "Noch keine Ankündigungen",
    "New announcement": "Neue Ankündigung",
    "Save announcement": "Ankündigung speichern",
    "Note: announcements must be a single line. New lines will be ignored.":
      "Hinweis: Ankündigungen müssen einzeilig sein. Neue Zeilen werden ignoriert.",
    "Delete announcement": "Ankündigung löschen",
    "Are you sure you want to remove this announcement?":
      "Möchten Sie diese Ankündigung wirklich entfernen?",
    "{{count}} Announcement": "{{count}} Ankündigung",
    "{{count}} Announcement_other": "{{count}} Ankündigungen",
    Content: "Inhalt",
    "Last updated": "Zuletzt aktualisiert",
    "Display mode": "Anzeigemodus",
    "Should the announcement be displayed on all playlists and configurations, or only on those with matching tags?":
      "Soll die Ankündigung in allen Wiedergabelisten und Konfigurationen angezeigt werden oder nur in denen mit passenden Tags?",
    "Display on all": "Auf allen anzeigen",
    Tagged: "Getaggt",
    Tags: "Tags",
    "Add start and end times": "Start- und Endzeit hinzufügen",
    "Set the start and end time of the announcement":
      "Start- und Endzeit der Ansage festlegen",
    "Starts at": "Beginnt um",
    "Ends at": "Endet um",
    "End time should be after the start time":
      "Endzeit sollte nach der Startzeit liegen",
    "End time cannot be in the past":
      "Endzeit darf nicht in der Vergangenheit liegen",
  },
  CardsFormField: {
    "Add card": "Karte hinzufügen",
    "Add image": "Bild hinzufügen",
    "Remove image": "Bild entfernen",
    "Remove card": "Karte entfernen",
    "Are you sure you want to remove this card?":
      "Möchten Sie diese Karte wirklich entfernen?",
    "No cards added": "Keine Karten hinzugefügt",
    "Card title": "Kartentitel",
    "Drag and drop to re-order cards":
      "Ziehen und ablegen, um Karten neu anzuordnen",
    "Move up": "Nach oben verschieben",
    "Move down": "Nach unten verschieben",
    Scheduled: "Geplant",
    "Starts at": "Beginnt um",
    "Ends at": "Endet um",
  },
  MediaFormField: {
    "Invalid YouTube link, please try again":
      "Ungültiger YouTube-Link, bitte versuchen Sie es erneut",
    "File not supported, please upload an image or video":
      "Datei wird nicht unterstützt. Bitte laden Sie ein Bild oder Video hoch",
    "Upload image/video": "Bild/Video hochladen",
    "Add YouTube": "YouTube hinzufügen",
    "Add YouTube video": "YouTube-Video hinzufügen",
    "Video title": "Videotitel",
    "YouTube link": "YouTube-Link",
    Image: "Bild",
    Video: "Video",
    YouTube: "YouTube",
    "Something went wrong, file not uploaded":
      "Es ist ein Fehler aufgetreten, die Datei wurde nicht hochgeladen",
    "Cancel file upload?": "Datei-Upload abbrechen?",
    "Are you sure you want to cancel this upload?":
      "Are you sure you want to cancel this upload?",
    "Upload image": "Bild hochladen",
    "Upload video": "Video hochladen",
    "Uploading {{uploadPct}}%": "Hochladen {{uploadPct}}%",
    "Upload complete": "Hochladen abgeschlossen",
    Name: "Name",
    "Number seconds image displays before moving to next media item":
      "Anzahl der Sekunden, in denen das Bild angezeigt wird, bevor zum nächsten Medienelement gewechselt wird",
    "Duration (seconds)": "Dauer (Sekunden)",
    "Add image": "Bild hinzufügen",
    "Add video": "Video hinzufügen",
    "Delete media": "Medien löschen",
    "Are you sure you want to delete this media?":
      "Sind Sie sicher, dass Sie dieses Medium löschen möchten?",
    "Update duration": "Aktualisierungsdauer",
    "Edit duration": "Dauer bearbeiten",
    "Remove media": "Medien entfernen",
    "No media added": "Keine Medien hinzugefügt",
  },
  MultiColumnScheduleFormField: {
    "Add column": "Spalte hinzufügen",
    "Column title": "Spaltentitel",
    "Remove column": "Spalte entfernen",
    "Are you sure you want to remove this column?":
      "Möchten Sie diese Spalte wirklich entfernen?",
    "Hide past events": "Vergangene Ereignisse ausblenden",
    "Grace period (minutes)": "Verzögerung (Minuten)",
    "Number of minutes to keep events on screen after they have ended":
      "Anzahl der Minuten, um Ereignisse auf dem Bildschirm nach Ende der Veranstaltung zu halten",
  },
  AuthProvider: {
    "Fetching active users": "Aktive Benutzer abrufen",
    "Setting organisations": "Organisationen festlegen",
    "Logging you in": "Ich melde mich an",
  },
  AccessDeniedScreen: {
    "You don't have access to Digital Displays":
      "Sie haben keinen Zugriff auf Digitalanzeigen",
    "Please contact Teamworks customer support to request access":
      "Bitte wenden Sie sich an den Teamworks-Kundensupport, um Zugriff anzufordern",
    "Sign out": "Abmelden",
    "Customer support": "Kundenbetreuung",
    Reload: "Neu laden",
  },
  DeviceGroupsTab: {
    "Delete group": "Gruppe löschen",
    "Are you sure you want to delete this group?":
      "Sind Sie sicher, dass Sie diese Gruppe löschen möchten?",
    "Device group deleted successfully": "Gerätegruppe erfolgreich gelöscht",
    "Something went wrong": "Etwas ist schief gelaufen",
    "Device group created successfully": "Gerätegruppe erfolgreich erstellt",
    "Group updated": "Gruppe aktualisiert",
    "New group": "Neue Gruppe",
    "Device groups allow you to group your display devices under one name.":
      "Mit Gerätegruppen können Sie Ihre Anzeigegeräte unter einem Namen gruppieren.",
    "Device groups": "Gerätegruppen",
    "{{count}} Device group": "{{count}} Gerätegruppe",
    "{{count}} Device group_other": "{{count}} Gerätegruppen",
    "Search groups...": "Gruppen durchsuchen...",
    "Group name": "Gruppenname",
    "Device count": "Geräteanzahl",
    "Fetching groups": "Gruppen abrufen",
    "No groups": "Keine Gruppen",
    "Group name is required": "Gruppenname ist erforderlich",
    "Please select at least 2 devices":
      "Bitte wählen Sie mindestens 2 Geräte aus",
    "Create group": "Gruppe erstellen",
    "Update group": "Gruppe aktualisieren",
    "Fetching devices...": "Geräte werden abgerufen...",
    "Select devices": "Wählen Sie Geräte aus",
    Devices: "Geräte",
    Groups: "Gruppen",
    "Number devices": "Anzahl der Geräte",
  },
  ErrorState: {
    "Something went wrong": "Etwas ist schief gelaufen",
    "Please try again, or contact Teamworks support if the issue persists":
      "Bitte versuchen Sie es erneut oder wenden Sie sich an den Teamworks-Support, wenn das Problem weiterhin besteht",
  },
  ProductToursProvider: {
    Back: "Zurück",
    Next: "Nächste",
    Finish: "Beenden",
  },
  Drawer: {
    Close: "Schließen",
  },
  InitialSearchParamsProvider: {
    "Initializing URL params": "URL-Parameter werden initialisiert",
  },
  PreviewsProvider: {
    "Redirecting to preview": "Weiterleitung zur Vorschau",
  },
  PlaylistsScreen: {
    Playlists: "Wiedergabelisten",
    "New playlist": "Neue Playlist",
    "{{count}} Playlist": "{{count}} Wiedergabeliste",
    "{{count}} Playlist_other": "{{count}} Wiedergabelisten",
    Name: "Name",
    Tags: "Tags",
    "Loading playlists": "Playlists werden geladen",
    "No playlists have been setup yet":
      "Es wurden noch keine Playlists eingerichtet",
    "Playlists allow you to join multiple configurations together.":
      "Mit Playlists können Sie mehrere Konfigurationen zusammenfügen.",
    "No configurations selected": "Keine Konfigurationen ausgewählt",
    "Add configuration": "Konfiguration hinzufügen",
    Cancel: "Stornieren",
    "Create playlist": "Playlist erstellen",
    "Delete playlist": "Playlist löschen",
    "Are you sure you want to delete this playlist?":
      "Sind Sie sicher, dass Sie diese Playlist löschen möchten?",
    "Loading playlist": "Playlist wird geladen",
    "Save changes": "Änderungen speichern",
    "Configuration already in playlist":
      "Konfiguration bereits in der Playlist",
    "The selected configuration is already part of this playlist, do you want to add it again?":
      "Die ausgewählte Konfiguration ist bereits Teil dieser Playlist. Möchten Sie sie erneut hinzufügen?",
    "Remove from playlist": "Aus Playlist entfernen",
    Configuration: "Konfiguration",
    "Number loops": "Zahlenschleifen",
    Active: "Aktiv",
    "Playlist updated": "Playlist aktualisiert",
    "Something went wrong": "Etwas ist schief gelaufen",
    "Last updated": "Zuletzt aktualisiert",
    l_layout_loop_description:
      "Medienelemente durchlaufen eine bestimmte Anzahl von Malen, bevor sie zur nächsten Konfiguration wechseln",
    media_only_loop_description:
      "Medienelemente durchlaufen eine bestimmte Anzahl von Malen, bevor sie zur nächsten Konfiguration wechseln",
    basic_loop_description:
      "Der Zeitplan durchläuft eine bestimmte Anzahl von Wiederholungen, bevor zur nächsten Konfiguration gewechselt wird",
    multi_column_schedule_loop_description:
      "Der Zeitplan durchläuft eine bestimmte Anzahl von Wiederholungen, bevor zur nächsten Konfiguration gewechselt wird",
    multi_day_schedule_loop_description:
      "Der Zeitplan durchläuft eine bestimmte Anzahl von Wiederholungen, bevor zur nächsten Konfiguration gewechselt wird",
    schedule_only_loop_description:
      "Der Zeitplan durchläuft eine bestimmte Anzahl von Wiederholungen, bevor zur nächsten Konfiguration gewechselt wird",
    "%future added value_loop_description": "Unbekannter Konfigurationstyp",
    mvp_loop_description: "Unbekannter Konfigurationstyp",
    nutrition_loop_description:
      "Das Menü oder die Essensstation wird eine bestimmte Anzahl von Malen wiederholt, bevor zur nächsten Konfiguration gewechselt wird",
    weather_loop_description: "Zeigen Sie das Wetter für mehrere Standorte an",
    timed_description:
      "Die Konfiguration bleibt für eine bestimmte Zeit auf dem Bildschirm, bevor zur nächsten Konfiguration gewechselt wird",
    Looped: "Geschleift",
    Timed: "Zeitgesteuert",
    "Duration (minutes)": "Dauer (Minuten)",
    "Search playlists...": "Playlists durchsuchen...",
    "Edit configuration": "Konfiguration bearbeiten",
    "New configuration": "Neue Konfiguration",
    "Loading configuration": "Konfiguration wird geladen",
    "Add an existing configuration to the playlist":
      "Vorhandene Konfiguration zur Wiedergabeliste hinzufügen",
    "Create a new configuration to add to the playlist":
      "Neue Konfiguration erstellen, um sie zur Wiedergabeliste hinzuzufügen",
    "No playlists found for '{{searchText}}'":
      "Keine Wiedergabelisten für '{{searchText}}' gefunden",
    "Last active at {{lastActiveAt}}": "Zuletzt aktiv am {{lastActiveAt}}",
    "Device count": "Geräteanzahl",
    "Loading screen devices": "Bildschirmgeräte werden geladen",
    "Announcements with matching tags will be displayed on screen":
      "Ankündigungen mit passenden Tags werden auf dem Bildschirm angezeigt",
  },
  PlaylistPicker: {
    Playlist: "Wiedergabeliste",
    "Clear playlist": "Klare Playlist",
    "No playlist selected": "Keine Playlist ausgewählt",
    "Searching playlists": "Durchsuchen von Wiedergabelisten",
    "No playlists found for '{{searchText}}'":
      "Keine Playlists für '{{searchText}}' gefunden",
    "No playlists found": "Keine Playlists gefunden",
  },
  TableSearchField: {
    "Clear search text": "Suchtext löschen",
  },
  SettingsScreen: {
    "Organisation settings": "Organisationseinstellungen",
    "Permission groups": "Berechtigungsgruppen",
    "User access": "Benutzerzugriff",
    "IP Addresses": "IP-Adressen",
    "Loading settings": "Einstellungen werden geladen",
  },
  PermissionGroupsTab: {
    "New permission group": "Neue Berechtigungsgruppe",
    "Permission groups allow you to specify which hub profiles a user has access to when setting up a schedule":
      "Berechtigungsgruppen ermöglichen es Ihnen, festzulegen, auf welche Hub-Profile ein Benutzer beim Erstellen eines Zeitplans zugreifen kann",
    "{{count}} Permission group": "{{count}} Berechtigungsgruppe",
    "{{count}} Permission group_other": "{{count}} Berechtigungsgruppen",
    "Search permission groups...": "Berechtigungsgruppen suchen...",
    Name: "Name",
    "Last updated": "Zuletzt aktualisiert",
    "Loading permission groups": "Berechtigungsgruppen werden geladen",
    "No permission groups yet": "Noch keine Berechtigungsgruppen",
    "No permission groups found for '{{searchText}}'":
      "Keine Berechtigungsgruppen für '{{searchText}}' gefunden",
    "Permission groups allow you set what hub profiles a user has access to":
      "Berechtigungsgruppen ermöglichen es Ihnen, festzulegen, auf welche Hub-Profile ein Benutzer zugreifen kann",
    "{{count}} hub profile": "{{count}} Hub-Profil",
    "{{count}} hub profile_other": "{{count}} Hub-Profile",
    "Permission group created": "Berechtigungsgruppe erstellt",
    "Something went wrong": "Etwas ist schiefgelaufen",
    "Loading permission group": "Berechtigungsgruppe wird geladen",
    "Delete permission group": "Berechtigungsgruppe löschen",
    "Are you sure you want to delete this permission group?":
      "Möchten Sie diese Berechtigungsgruppe wirklich löschen?",
    "Permission group deleted": "Berechtigungsgruppe gelöscht",
    "Permission group updated": "Berechtigungsgruppe aktualisiert",
    "Save changes": "Änderungen speichern",
    "Permission group name": "Name der Berechtigungsgruppe",
    "Permitted hub profiles": "Zugelassene Hub-Profile",
    "Only permitted profiles will appear for users to select when managing display schedules":
      "Nur zugelassene Profile werden den Benutzern zur Auswahl angezeigt, wenn sie Anzeigefahrpläne verwalten",
    "Hub access": "Hub-Zugriff",
    "Nutrition access": "Zugang Nutrition",
    Hub: "Hub",
    Nutrition: "Nutrition",
    "Accessible apps": "Zugängliche Apps",
  },
  UsersTab: {
    "Contact Teamworks support to add or remove users":
      "Kontaktieren Sie den Teamworks-Support, um Benutzer hinzuzufügen oder zu entfernen",
    "New user": "Neuer Benutzer",
    "This is a list of users who have access to Digital Displays":
      "Dies ist eine Liste von Benutzern, die Zugriff auf Digitale Anzeigen haben",
    "{{count}} User": "{{count}} Benutzer",
    "{{count}} User_other": "{{count}} Benutzer",
    "Search users...": "Benutzer suchen...",
    Name: "Name",
    "Last updated": "Zuletzt aktualisiert",
    "Loading users": "Benutzer werden geladen",
    "Loading user": "Benutzer wird geladen",
    "No users yet": "Noch keine Benutzer",
    "No users found for '{{searchText}}'":
      "Keine Benutzer für '{{searchText}}' gefunden",
    "Super admin": "Superadministrator",
    "{{count}} permission group": "{{count}} Berechtigungsgruppe",
    "{{count}} permission group_other": "{{count}} Berechtigungsgruppen",
    "User updated": "Benutzer aktualisiert",
    "Something went wrong": "Etwas ist schiefgelaufen",
    "Save changes": "Änderungen speichern",
    superadmin_description:
      "Dieser Benutzer ist ein Superadministrator. Er hat Zugriff auf alle Hub-Profile, unabhängig von den zugewiesenen Berechtigungsgruppen.",
    admin_description:
      "Dieser Benutzer hat nur Zugriff auf die Hub-Profile, die ihm über Berechtigungsgruppen zugewiesen wurden.",
    "%future added value_description": "Unbekannte Rolle",
  },
  ManageIpAddressesTab: {
    "Edit IP addresses": "IP-Adressen bearbeiten",
    "For systems which do not support our QR setup, these permitted IP addresses can be used to allow you access to hardcoded URLs.":
      "Für Systeme, die unsere QR-Einrichtung nicht unterstützen, können diese zugelassenen IP-Adressen verwendet werden, um Ihnen Zugriff auf fest codierte URLs zu gewähren.",
    "{{count}} Permitted IP address": "{{count}} Zulässige IP-Adresse",
    "{{count}} Permitted IP address_other": "{{count}} Zugelassene IP-Adressen",
    "No IP addresses": "Keine IP-Adressen",
    "You have not added any permitted IP addresses yet":
      "Sie haben noch keine zugelassenen IP-Adressen hinzugefügt",
    "Permitted IP addresses updated": "Zugelassene IP-Adressen aktualisiert",
    "Something went wrong": "Etwas ist schiefgelaufen",
    "Save changes": "Änderungen speichern",
    "No permitted IPs yet": "Noch keine zugelassenen IPs",
    "Add another": "Weitere hinzufügen",
  },
  PermissionGroupPicker: {
    "Add permission groups": "Berechtigungsgruppen hinzufügen",
    "No permission groups added": "Keine Berechtigungsgruppen hinzugefügt",
    "Show {{count}} more": "Zeige {{count}} mehr",
    "Clear search text": "Suchtext löschen",
    "Search permission groups...": "Berechtigungsgruppen suchen...",
    "Loading permission groups": "Lade Berechtigungsgruppen",
    "{{count}} hub profile": "{{count}} Hub-Profil",
    "{{count}} hub profile_other": "{{count}} Hub-Profile",
    "Remove permission group": "Berechtigungsgruppe entfernen",
    "No users found": "Keine Benutzer gefunden",
    "We couldn't find any users matching '{{searchText}}'":
      "Wir konnten keine Benutzer finden, die '{{searchText}}' entsprechen",
    "No permission groups found": "Keine Berechtigungsgruppen gefunden",
    "Confirm selection": "Auswahl bestätigen",
  },
  TableProvider: {
    "{{count}} Selected": "{{count}} Ausgewählt",
  },
  ComponentPicker: {
    "Pick a component": "Wählen Sie eine Komponente",
    media: "Medien",
    schedule: "Zeitplan",
    cards: "Karten",
    multi_day_schedule: "Mehrtägiger Zeitplan",
    multi_column_schedule: "Mehrspaltiger Zeitplan",
    nutrition_dining_station: "Ernährungsanzeigestation",
    nutrition_menu: "Ernährungsmenü",
    weather: "Wetter",
    "%future added value": "Unbekannte Komponente",
    Add: "Hinzufügen",
  },
  TagPicker: {
    "Add tags": "Tags hinzufügen",
    Clear: "Löschen",
    "Searching tags": "Tags werden gesucht",
    "No tags selected": "Keine Tags ausgewählt",
    "Remove tag": "Tag entfernen",
    "No tags found matching '{{searchText}}'":
      "Keine Tags gefunden, die '{{searchText}}' entsprechen",
    "Something went wrong": "Etwas ist schief gelaufen",
    "Create tag '{{tagName}}'": "Tag '{{tagName}}' erstellen",
    "Start typing to create a new tag":
      "Beginnen Sie mit der Eingabe, um einen neuen Tag zu erstellen",
    "Delete tag": "Tag löschen",
    "Are you sure you want to delete the tag '{{tagName}}'?":
      "Sind Sie sicher, dass Sie den Tag '{{tagName}}' löschen möchten?",
    "Tag deleted": "Tag gelöscht",
  },
  DeviceScheduleScreen: {
    Devices: "Geräte",
    "Device schedule": "Gerätezeitplan",
    "Schedule item created": "Zeitplanelement erstellt",
    "Something went wrong": "Etwas ist schief gelaufen",
    "Please correct any errors and try again":
      "Bitte korrigieren Sie alle Fehler und versuchen Sie es erneut",
    "Create schedule item": "Zeitplanelement erstellen",
    "Schedule item updated": "Zeitplanelement aktualisiert",
    "Update schedule item": "Zeitplanelement aktualisieren",
    "End time should be after the start time":
      "Endzeit sollte nach der Startzeit liegen",
    "Schedule item cannot be in the past":
      "Zeitplanelement darf nicht in der Vergangenheit liegen",
    "Starts at": "Beginnt um",
    "Ends at": "Endet um",
    "Add content": "Inhalt hinzufügen",
    "Loading schedule": "Zeitplan wird geladen",
    "Delete schedule item": "Zeitplanelement löschen",
    "Are you sure you want to delete this schedule item?":
      "Sind Sie sicher, dass Sie dieses Zeitplanelement löschen möchten?",
    "Schedule item deleted": "Zeitplanelement gelöscht",
    "Saving this event will shorten or replace {{count}} item. Are you sure you want to continue?":
      "Das Speichern dieses Ereignisses wird {{count}} Element verkürzen oder ersetzen. Sind Sie sicher, dass Sie fortfahren möchten?",
    "Saving this event will shorten or replace {{count}} item. Are you sure you want to continue?_other":
      "Das Speichern dieses Ereignisses wird {{count}} Elemente verkürzen oder ersetzen. Sind Sie sicher, dass Sie fortfahren möchten?",
    "Conflicts found": "Konflikte gefunden",
  },
  Calendar: {
    Previous: "Zurück",
    Today: "Heute",
    Next: "Nächste",
    View: "Ansicht",
    month: "Monat",
    week: "Woche",
    work_week: "Arbeitswoche",
    day: "Tag",
    agenda: "Agenda",
  },
  ScheduleConflictChecker: {
    "Checking for conflicts": "Auf Konflikte prüfen",
    "No conflicts found": "Keine Konflikte gefunden",
    "{{count}} conflict found": "{{count}} Konflikt gefunden",
    "{{count}} conflict found_other": "{{count}} Konflikte gefunden",
    "Conflicts found": "Konflikte gefunden",
  },
  LocationPicker: {
    "Search locations": "Standorte suchen",
    "Clear search text": "Suchtext löschen",
    "Searching locations": "Standorte werden gesucht",
    "No results": "Keine Ergebnisse",
  },
  NutritionMenuField: {
    "Please select a menu": "Bitte wählen Sie ein Menü aus",
    "Include images": "Bilder einbeziehen",
    "Include descriptions": "Beschreibungen einbeziehen",
    "Include macros": "Makronährstoffe einbeziehen",
    "Unknown menu": "Unbekanntes Menü",
    "Please select a dining station": "Bitte wählen Sie eine Essensstation aus",
    "Unknown dining station": "Unbekannte Essensstation",
  },
  NutritionMenuPicker: {
    "Unknown menu date": "Unbekanntes Menüdatum",
    "Must be later than the 'after' time": "Muss nach der 'nach'-Zeit liegen",
    "Menu date": "Menüdatum",
    "Search menus": "Menüs durchsuchen",
    "Nutrition menu": "Ernährungsmenü",
    "Remove menu": "Menü entfernen",
    "Searching menus": "Menüs durchsuchen",
    "{{count}} menu found": "{{count}} Menü gefunden",
    "{{count}} menu found_other": "{{count}} Menüs gefunden",
    "No menus found": "Keine Menüs gefunden",
    "Try changing the date range and search again":
      "Versuchen Sie, den Datumsbereich zu ändern, und suchen Sie erneut",
    "Dining station": "Essensstation",
    "Loading stations": "Ladestationen",
    "Failed to fetch menus": "Menüs konnten nicht abgerufen werden",
    "Something went wrong, please try again":
      "Etwas ist schiefgelaufen, bitte versuchen Sie es erneut",
    "Failed to fetch menu dining stations":
      "Essensstationen des Menüs konnten nicht abgerufen werden",
  },
  WeatherFormField: {
    "Number of days ahead to fetch weather. Max 7":
      "Anzahl der Tage im Voraus, um das Wetter abzurufen. Maximal 7",
    "Number of days": "Anzahl der Tage",
    "Add location": "Standort hinzufügen",
    "Remove location": "Standort entfernen",
    "Are you sure you want to remove this location?":
      "Sind Sie sicher, dass Sie diesen Standort entfernen möchten?",
    Date: "Datum",
    "Default location": "Standardstandort",
    Location: "Standort",
    "Below, you can add different locations for certain dates, which will override the default location for those days.":
      "Unten können Sie für bestimmte Daten verschiedene Standorte hinzufügen, die den Standardstandort für diese Tage überschreiben.",
    "This is the default location for displaying weather, unless overridden by another location on a given date.":
      "Dies ist der Standardstandort zur Anzeige des Wetters, es sei denn, er wird an einem bestimmten Datum durch einen anderen Standort ersetzt.",
  },
  ScreenConfigFrom: {
    "Dates must be unique": "Dates must be unique",
    "Card text must be at least 4 characters long":
      "Card text must be at least 4 characters long",
  },
};
