import { type Localisation } from "./en";

export const italian: Localisation = {
  dateFormats: {
    datePickerLocale: "it",
    timeFormat: "HH:mm", // 14:30
    dayFormat: "do", // 12th
    dayMonthFormat: "do MMMM", // 1st April
    dayMonthYearFormat: "do MMMM yyyy", // 11th January 1988
    dayDateMonthYearFormat: "E, do MMMM yyyy", // Mon, 1st April 2024
    dayMonthYearTimeFormat: "do MMMM yyyy, HH:mm", // 11th January 1988, 14:30
  },
  App: {
    "Digital Displays": "Display Digitali",
    Loading: "Caricamento",
    "Checking authentication": "Verifica dell'autenticazione",
    "Redirecting to login": "Reindirizzamento al login",
    "Sign in with Teamworks": "Accedi con Teamworks",
    Initializing: "Inizializzazione",
    "Verifing authentication": "Verifica dell'autenticazione",
    Redirecting: "Reindirizzamento",
    "Welcome back!": "Bentornato!",
    "Login to get started": "Accedi per iniziare",
  },
  AppSwitcher: {
    arms: "Compliance + Recruiting",
    hub: "Hub",
    inflcr: "Influencer",
    academics: "Academics",
    communities: "Communities",
    pulse: "Pulse",
    pathways: "Pathways",
    nutrition: "Nutrition",
    ams: "AMS",
    tv: "Digital Displays",
    trac: "Inventory Management",
    strength: "Strength + Conditioning",
    wallet: "Wallet",
    gm: "General Manager",
  },
  Layout: {
    "Checking authentication": "Verifica dell'autenticazione",
    Dashboard: "Pannello di controllo",
    Configurations: "Configurazioni",
    Devices: "Dispositivi",
    Themes: "Temi",
    Settings: "Impostazioni",
    Announcements: "Announcements",
    "Organisation settings": "Impostazioni dell'organizzazione",
    "Teamworks Help": "Aiuto di Teamworks",
    "Teamworks Apps": "App di Teamworks",
    "Privacy policy": "Politica sulla riservatezza",
    Logout: "Disconnettersi",
    "Loading, please wait...": "Caricamento in corso, attendere...",
    Users: "Utenti",
    "Switch organisation": "Cambia organizzazione",
    "Signing you out": "Disconnessione in corso",
    "Open Menu": "Apri Menu",
    "Close Menu": "Chiudi Menu",
    Playlists: "Playlist",
  },
  ConfigsScreen: {
    "{{count}} Configuration": "{{count}} Configurazione",
    "{{count}} Configuration_other": "{{count}} Configurazioni",
    "New configuration": "Nuova configurazione",
    "Configurations allow you to customise the look and content of your displays.":
      "Le configurazioni ti permettono di personalizzare l'aspetto e il contenuto dei tuoi display.",
    "Loading configurations": "Caricamento configurazioni",
    Configurations: "Configurazioni",
    Name: "Nome",
    Tags: "Tag",
    Template: "Modello",
    Theme: "Tema",
    "Device count": "Conteggio dispositivi",
    "Created at": "Creato il",
    "No configurations have been setup yet":
      "Non sono state ancora configurate configurazioni",
    "No configurations found for '{{searchText}}'":
      "Nessuna configurazione trovata per '{{searchText}}'",
    "Displays allow you to cast scheduled events and media to any device with an internet browser":
      "I display ti permettono di trasmettere eventi programmati e contenuti multimediali su qualsiasi dispositivo con un browser internet",
    "Create one now": "Creane uno ora",
    "Create configuration": "Crea configurazione",
    "Add custom videos, YouTube videos and images":
      "Aggiungi video personalizzati, video di YouTube e immagini",
    "Filter calendar to present a live schedule":
      "Filtra il calendario per presentare un programma live",
    "Display weather for multiple locations":
      "Visualizza il meteo per più località",
    "A general overview for up to a week in advance":
      "Una panoramica generale fino a una settimana in anticipo",
    "Show temperature, wind and probability of precipitation":
      "Mostra temperatura, vento e probabilità di precipitazioni",
    "Cycle through important updates on cards":
      "Scorri gli aggiornamenti importanti sulle schede",
    "Includes team crest, time and date":
      "Include lo stemma della squadra, l'ora e la data",
    "Display a schedule over multiple days":
      "Mostra un programma su più giorni",
    "Display daily schedule for different teams and groups.":
      "Mostra il programma giornaliero per diverse squadre e gruppi.",
    "Configuration deleted": "Configurazione eliminata",
    "Are you sure you want to delete this configuration?":
      "Sei sicuro di voler eliminare questa configurazione?",
    "Delete configuration": "Elimina configurazione",
    "Fetching configuration": "Recupero configurazione",
    "Something went wrong": "Qualcosa è andato storto",
    "Edit configuration": "Modifica configurazione",
    "Includes background image from theme":
      "Include l'immagine di sfondo dal tema",
    "Last active at {{lastActiveAt}}": "Ultimo attivo alle {{lastActiveAt}}",
    "Loading screen devices": "Caricamento dei dispositivi dello schermo",
    "Search configurations...": "Cerca configurazioni...",
    "Save changes": "Salva le modifiche",
    "Last updated": "Ultimo aggiornamento",
    "Clear template": "Cancella modello",
    "No template selected": "Nessun modello selezionato",
    "Display a full menu or an individual station":
      "Visualizza un menu completo o una singola stazione",
    "Optionally show images, descriptions and macros":
      "Mostra opzionalmente immagini, descrizioni e macronutrienti",
  },
  DashboardScreen: { Dashboard: "Pannello di controllo" },
  DevicesScreen: {
    Devices: "Dispositivi",
    Groups: "Gruppi",
    "{{count}} Device": "{{count}} Dispositivo",
    "{{count}} Device_other": "{{count}} Dispositivi",
    "New device": "Nuovo dispositivo",
    "Devices are the physical displays you have linked to your account.":
      "I dispositivi sono i display fisici che hai collegato al tuo account.",
    "No devices have been setup yet":
      "Nessun dispositivo è stato ancora configurato",
    "No devices found for '{{searchText}}'":
      "Nessun dispositivo trovato per '{{searchText}}'",
    "Device name": "Nome del dispositivo",
    Facility: "Facilità",
    Configuration: "Configurazione",
    "Facility not set": "Struttura non impostata",
    "Config not set": "Configurazione non impostata",
    "Create device": "Crea dispositivo",
    "Update device": "Aggiorna dispositivo",
    "Screen device updated": "Dispositivo schermo aggiornato",
    "Something went wrong": "Qualcosa è andato storto",
    "Adding new display device":
      "Aggiunta di un nuovo dispositivo di visualizzazione",
    addNewDeviceDescription:
      "Per aggiungere un nuovo dispositivo, visita <0>{{url}}</0> sul dispositivo che desideri collegare.",
    "You will be shown a QR code which can be scanned by your camera.":
      "Ti verrà mostrato un codice QR che può essere scansionato dalla tua fotocamera.",
    "Start scanning": "Inizia a scansionare",
    Ok: "OK",
    "Are you sure you want to delete this device?":
      "Sei sicuro di voler eliminare questo dispositivo?",
    "Delete device": "Elimina dispositivo",
    "Fetching devices": "Recupero dispositivi",
    Timezone: "Fuso orario",
    "Display name is required": "Il nome visualizzato è obbligatorio",
    "Display name must be at least 3 characters long":
      "Il nome visualizzato deve essere lungo almeno 3 caratteri",
    "Device setup successful": "Configurazione del dispositivo riuscita",
    "Switch organisation": "Cambia organizzazione",
    "Search devices...": "Cerca dispositivi...",
    "Device not found": "Dispositivo non trovato",
    "It may have been deleted, or you do not have permission to view it.":
      "Potrebbe essere stato eliminato o non hai il permesso di visualizzarlo.",
    "Loading device": "Caricamento dispositivo",
    "Last updated": "Ultimo aggiornamento",
    "Playlist not set": "Playlist non impostata",
    "Bulk actions": "Azioni collettive",
    "Edit selected": "Modifica selezionato",
    "Delete selected": "Elimina selezionato",
    "Delete {{count}} devices": "Elimina {{count}} dispositivi",
    "Are you sure you want to delete these devices?":
      "Sei sicuro di voler eliminare questi dispositivi?",
    "Devices deleted": "Dispositivi eliminati",
    "Screen devices updated": "Dispositivi schermo aggiornati",
    "Update {{count}} devices": "Aggiorna {{count}} dispositivi",
    "Device deleted": "Dispositivo eliminato",
    "Select all": "Seleziona tutto",
    "Deselect all": "Deseleziona tutto",
    "Create virtual device": "Crea dispositivo virtuale",
    "Virtual device URL": "URL del dispositivo virtuale",
    "This URL can be used to integrate with systems that do not support our QR code setup.":
      "Questo URL può essere utilizzato per l'integrazione con sistemi che non supportano la nostra configurazione con codice QR.",
    "Note: This URL will only be accessable from networks with an approved IP address.":
      "Nota: Questo URL sarà accessibile solo da reti con un indirizzo IP approvato.",
    or: "o",
    "Provides a hardcoded URL, for use with systems which do not support the QR setup":
      "Fornisce un URL codificato, da utilizzare con sistemi che non supportano la configurazione QR",
    "Manage schedule": "Gestire la pianificazione",
    "Please correct any errors and try again":
      "Correggi eventuali errori e riprova",
    "Skip interaction screen": "Salta la schermata di interazione",
    skip_interaction_description:
      "La schermata di interazione mostra un pulsante che deve essere premuto prima che venga visualizzato qualsiasi contenuto. Questo aiuta a prevenire problemi di riproduzione automatica nei browser moderni, ma potrebbe non essere necessario per alcuni sistemi.",
  },
  DeviceModePicker: {
    "Device mode": "Modalità del dispositivo",
    "Should this device display a configuration or a playlist?":
      "Questo dispositivo deve mostrare una configurazione o una playlist?",
    "Should this device display a configuration or a playlist?_other":
      "Questi dispositivi devono mostrare una configurazione o una playlist?",
    config: "Configurazione",
    playlist: "Playlist",
  },
  NotFoundScreen: {
    "Oops! You seem to be lost.": "Ops! Sembra che tu sia perso.",
    "Return to dashboard": "Ritorna alla dashboard",
  },
  ThemeScreen: {
    "New theme": "Nuovo tema",
    "Themes allow you to customise the colours of your configurations.":
      "I temi ti consentono di personalizzare i colori delle tue configurazioni.",
    Themes: "Temi",
    "You don't have any themes yet": "Non hai ancora nessun tema",
    "Themes allow you to customise the colours used by your templates":
      "I temi ti consentono di personalizzare i colori utilizzati dai tuoi modelli",
    "Create one now": "Creane uno adesso",
    "Theme name": "Nome del tema",
    "Last updated": "Ultimo aggiornamento",
    "Create theme": "Crea tema",
    "Update theme": "Aggiorna tema",
    background: "Sfondo",
    backgroundText: "Testo di sfondo",
    accent: "Accento",
    accentText: "Testo in accento",
    announcementBar: "Barra degli annunci",
    announcementBarText: "Testo della barra degli annunci",
    "Invalid hex code": "Codice esadecimale non valido",
    "New theme created": "Nuovo tema creato",
    "Theme updated": "Tema aggiornato",
    "Screen theme deleted": "Tema dello schermo eliminato",
    "Something went wrong": "Qualcosa è andato storto",
    "Are you sure you want to delete this theme?":
      "Sei sicuro di voler eliminare questo tema?",
    "Delete theme": "Elimina tema",
    "Loading themes": "Caricamento temi",
    "Upload logo": "Carica logo",
    "Remove logo": "Rimuovi logo",
    "Upload background image": "Carica l'immagine di sfondo",
    "Remove image": "Rimuovi l'immagine",
    "Theme not found": "Tema non trovato",
    "It may have been deleted, or you do not have permission to view it":
      "Potrebbe essere stato eliminato o non hai il permesso di visualizzarlo",
    "Loading theme": "Caricamento tema",
    "Search themes...": "Cerca temi...",
    "{{count}} Theme": "{{count}} Tema",
    "{{count}} Theme_other": "{{count}} Temi",
    "What's a theme?": "Cos'è un tema?",
    "Themes allow you to customise the appearance of your configurations":
      "I temi ti permettono di personalizzare l'aspetto delle tue configurazioni",
    "Clicking this button will begin the process of creating a new one.":
      "Fare clic su questo pulsante avvierà il processo di creazione di un nuovo tema.",
    "Give your theme a memorable name": "Dai al tuo tema un nome memorabile",
    "This will help you find it later when assigning it to a configuration.":
      "Questo ti aiuterà a trovarlo in seguito quando lo assegnerai a una configurazione.",
    "Upload a logo (optional)": "Carica un logo (opzionale)",
    "If you do not upload a logo, a Teamworks logo will appear in its place.":
      "Se non carichi un logo, al suo posto apparirà un logo di Teamworks.",
    "Upload a background image (optional)":
      "Carica un'immagine di sfondo (opzionale)",
    "Some configurations make use of a background image. If an image is not uploaded, the background colour selected below will be used.":
      "Alcune configurazioni utilizzano un'immagine di sfondo. Se non viene caricata un'immagine, verrà utilizzato il colore di sfondo selezionato di seguito.",
    "Customise the colours you want to use":
      "Personalizza i colori che vuoi usare",
    "The 'text' options should be a contrasting colour, to ensure it can be easily read.":
      "Le opzioni di 'testo' dovrebbero essere di un colore contrastante, per garantire che possano essere facilmente lette.",
    "Once you are ready, save the theme by clicking this button":
      "Una volta pronto, salva il tema facendo clic su questo pulsante",
    "Your theme will then be available to be selected when managing a configuration.":
      "Il tuo tema sarà quindi disponibile per essere selezionato durante la gestione di una configurazione.",
    "Theme contrast": "Theme contrast",
    "Some content appears in containers that are semi transparent and overlayed on your chosen background colour.":
      "Alcuni contenuti vengono visualizzati in contenitori semitrasparenti e sovrapposti al colore di sfondo scelto.",
    "Would you like to lighten or darken your background?":
      "Desideri schiarire o scurire lo sfondo?",
    Dark: "Buio",
    Light: "Leggero",
    "Theme preview": "Anteprima del tema",
    Close: "Vicino",
    Preview: "Anteprima",
    "No themes found for '{{searchText}}'":
      "Nessun tema trovato per '{{searchText}}'",
    or: "o",
    "Use organisation logo": "Usa il logo dell'organizzazione",
  },
  ScreenTemplateNames: {
    "L shape with media": "Forma a L con supporti",
    "Media only": "Solo media",
    "Schedule only": "Solo programma",
    "Multi day schedule": "Programma multigiornaliero",
    "Multi column schedule": "Programmazione multicolonna",
    Basic: "Di base",
    Nutrition: "Nutrizione",
    Weather: "Tempo atmosferico",
  },
  DialogProvider: {
    Cancel: "Annulla",
    Confirm: "Conferma",
    Close: "Chiudi",
    Ok: "Ok",
    "Close without saving?": "Chiudere senza salvare?",
    "Close without saving": "Chiudere senza salvare",
    "Any unsaved changes will be lost":
      "Tutte le modifiche non salvate verranno perse",
    "Back to form": "Torna al modulo",
  },
  ConfigurationPicker: {
    Configuration: "Configurazione",
    "No configurations found for '{{searchText}}'":
      "Nessuna configurazione trovata per '{{searchText}}'",
    "No configurations found": "Nessuna configurazione trovata",
    "No configuration selected": "Nessuna configurazione selezionata",
    "Clear configuration": "Cancella configurazione",
    "Searching configurations": "Ricerca di configurazioni",
  },
  ThemePicker: {
    Theme: "Tema",
    "Clear theme": "Cancella tema",
    "Screen theme": "Tema dello schermo",
    "No theme selected": "Nessun tema selezionato",
    "No themes found matching '{{searchText}}'":
      "Nessun tema trovato corrispondente a '{{searchText}}'",
    "No themes found": "Nessun tema trovato",
    "Loading themes...": "Caricamento dei temi...",
  },
  MarkdownField: {
    Preview: "Anteprima",
    Remove: "Rimuovere",
    "Nothing to preview": "Niente da vedere in anteprima",
    Write: "Scrivere",
  },
  ErrorScreen: {
    "Something went wrong": "Qualcosa è andato storto",
    "Sorry for the inconvenience, our engineers have been notified and will be working on a fix":
      "Siamo spiacenti per il disagio, i nostri ingegneri hanno preso nota del problema e lavoreranno per risolverlo",
    "If this issue persists, please contact Teamworks customer support":
      "Se questo problema persiste, si prega di contattare il supporto clienti di Teamworks",
    Reload: "Ricarica",
    "Customer support": "Supporto clienti",
  },
  OrganisationSwitcher: {
    "Fetching organisations": "Recupero delle organizzazioni",
    "Select an organisation": "Seleziona un'organizzazione",
    "Switching profile": "Cambio profilo",
  },
  TimeZonePicker: {
    Timezone: "Fuso orario",
  },
  MatchesDrawer: {
    "Add match": "Aggiungi partita",
    "Fetching event": "Recupero evento",
    "Event title": "Titolo dell'evento",
    "Starts at": "Inizia alle",
    "Create event": "Crea evento",
  },
  FileUploadField: {
    "Upload file": "Carica file",
    "Remove file": "Rimuovi file",
  },
  HubProfilePicker: {
    Search: "Ricerca",
    "Clear search text": "Cancella il testo di ricerca",
    "Search text too short": "Testo di ricerca troppo corto",
    "Please add at least 3 characters to begin a search":
      "Si prega di aggiungere almeno 3 caratteri per iniziare una ricerca",
    "No calendars found": "Nessun calendario trovato",
    "We couldn't find any calendars matching '{{searchText}}'":
      "Non abbiamo trovato nessun calendario corrispondente a '{{searchText}}'",
    "Search...": "Ricerca...",
    "Confirm selection": "Conferma la selezione",
    Close: "Vicino",
    "Add Teamworks calendars": "Aggiungi calendari Teamworks",
    "Loading profiles": "Caricamento profili",
    "Search text must have at least 3 characters":
      "Il testo di ricerca deve contenere almeno 3 caratteri",
    "No results found": "Nessun risultato trovato",
    "Calendar details": "Dettagli del calendario",
    "Remove calendar": "Rimuovi calendario",
    "Are you sure you want to remove this calendar?":
      "Sei sicuro di voler rimuovere questo calendario?",
    "No calendars added": "Nessun calendario aggiunto",
    "Unknown profile": "Profilo sconosciuto",
    "You don't have access to this hub profile":
      "Non hai accesso a questo profilo hub",
    "Your administrator may need to review your permission groups.":
      "Il tuo amministratore potrebbe dover rivedere i tuoi gruppi di autorizzazioni.",
    "Show {{count}} more": "Mostra altri {{count}}",
  },
  ScreenEventsPicker: {
    "Loading events": "Caricamento eventi",
    "Event countdown": "Conto alla rovescia dell'evento",
    "Create event": "Crea evento",
    "Update event": "Aggiorna evento",
    "Event title": "Titolo dell'evento",
    "Starts at": "Inizia alle",
    "Add opponent badge": "Aggiungi distintivo avversario",
    "Remove opponent badge": "Rimuovi distintivo avversario",
    "Delete event": "Elimina evento",
    "Are you sure you want to delete this event?":
      "Sei sicuro di voler eliminare questo evento?",
    "Delete now": "Elimina ora",
    "Opponent badge position": "Posizione del distintivo dell'avversario",
    "Should the opponent badge be on the left or right?":
      "Il distintivo dell'avversario deve essere a sinistra o a destra?",
    "i.e are you playing home or away?":
      "cioè, stai giocando in casa o fuori casa?",
    "Edit event": "Modifica evento",
  },
  ScreenConfigDrawer: {
    "Loading configuration": "Caricamento configurazione",
    "Number of days the schedule should span. Max 5":
      "Numero di giorni che lo schedule dovrebbe coprire. Max 5",
    "Number of days": "Numero di giorni",
    "Delete configuration": "Elimina configurazione",
    "Are you sure you want to delete this configuration?":
      "Sei sicuro di voler eliminare questa configurazione?",
    "Configuration deleted": "Configurazione eliminata",
    "Something went wrong": "Qualcosa è andato storto",
    "Save changes": "Salva modifiche",
    "Configuration updated": "Configurazione aggiornata",
    cards: "Schede",
    cards_desc: "Seleziona schede per aggiungere testo o immagini",
    media: "Media",
    media_desc: "Seleziona da video, immagini e contenuti di YouTube",
    multi_column_schedule: "Orario a colonne multiple",
    multi_column_schedule_desc:
      "Seleziona i calendari Teamworks da visualizzare sullo schermo",
    multi_day_schedule: "Orario di più giorni",
    multi_day_schedule_desc:
      "Seleziona i calendari Teamworks da visualizzare sullo schermo",
    schedule: "Orario",
    schedule_desc:
      "Seleziona i calendari Teamworks da visualizzare sullo schermo",
    nutrition_menu: "Menu nutrizionale",
    nutrition_menu_desc: "Seleziona il menu che vuoi visualizzare",
    nutrition_dining_station: "Stazione nutrizionale",
    nutrition_dining_station_desc:
      "Seleziona la stazione di ristorazione che vuoi visualizzare",
    weather: "Tempo atmosferico",
    weather_desc: "Visualizza il meteo per più località",
    "%future added value": "Componente sconosciuto",
    "%future added value_desc": "Componente sconosciuto",
    "Configuration name": "Nome configurazione",
    "Add event countdown": "Aggiungi conto alla rovescia dell'evento",
    "A countdown will be displayed until the event starts":
      "Verrà visualizzato un conto alla rovescia fino all'inizio dell'evento",
    "Not saved": "Non salvato",
    "Please fix any errors and try again":
      "Si prega di correggere gli errori e riprovare",
    "Give the configuration a name": "Dai un nome alla configurazione",
    "Try to give it a relevant name, to help you quickly find it":
      "Cerca di dare un nome pertinente per aiutarti a trovarlo rapidamente",
    "Pick a theme": "Scegli un tema",
    "The theme will set colours and logo for your configuration":
      "Il tema imposterà i colori e il logo per la tua configurazione",
    "Add an event (optional)": "Aggiungi un evento (opzionale)",
    "A countdown will be displayed on the configuration until the event starts":
      "Un conto alla rovescia verrà visualizzato sulla configurazione fino all'inizio dell'evento",
    "Add a Teamworks schedule": "Aggiungi un programma di Teamworks",
    "Upload media": "Carica media",
    "Add cards (optional)": "Aggiungi schede (opzionale)",
    "Add multiple Teamworks schedules": "Aggiungi più programmi di Teamworks",
    "Unknown component": "Componente sconosciuto",
    "Events from the selected profiles will be shown on the configuration":
      "Gli eventi dei profili selezionati verranno mostrati nella configurazione",
    "You can upload images, videos or add YouTube videos":
      "Puoi caricare immagini, video o aggiungere video di YouTube",
    "The configuration will rotate through the cards one at a time":
      "La configurazione ruoterà attraverso le schede una alla volta",
    "Each schedule will have its own column on the configuration":
      "Ogni programma avrà la propria colonna nella configurazione",
    "A multiday schedule will display the events from the selected profiles across multiple days":
      "Un programma di più giorni mostrerà gli eventi dei profili selezionati su più giorni",
    "Save the configuration": "Salva la configurazione",
    "Once you are happy with your updates, save the changes":
      "Una volta che sei soddisfatto delle tue modifiche, salva i cambiamenti",
    "Take a tour": "Fai un tour",
    "Remove component": "Rimuovere componente",
    "Are you sure you want to remove this component?":
      "Sei sicuro di voler rimuovere questo componente?",
    "Add weather": "Aggiungi meteo",
    "A weather widget will be displayed to show current weather":
      "Verrà visualizzato un widget meteo per mostrare il tempo attuale",
    "Hide past events": "Nascondi eventi passati",
    "Announcements with matching tags will be displayed on screen":
      "Gli annunci con tag corrispondenti saranno visualizzati sullo schermo",
    "Display weather information for multiple locations across different days":
      "Mostra informazioni meteorologiche per più località in giorni diversi",
    "Grace period (minutes)": "Periodo di gracia (minuti)",
    "Number of minutes to keep events on screen after they have ended":
      "Numero di minuti per mantenere gli eventi sullo schermo dopo che sono terminati",
  },
  AnnouncementsScreen: {
    "Announcement text is required": "Il testo dell'annuncio è obbligatorio",
    "Announcements updated": "Annunci aggiornati",
    "Something went wrong": "Qualcosa è andato storto",
    "Announcements allow you to post important information to be displayed in banners across all devices.":
      "Gli annunci ti consentono di pubblicare informazioni importanti da visualizzare nei banner su tutti i dispositivi.",
    Announcements: "Annunci",
    "Loading announcements": "Caricamento annunci",
    "Manage announcements": "Gestisci gli annunci",
    "Announcements are displayed on all screens, at all times":
      "Gli annunci vengono visualizzati su tutti gli schermi, in ogni momento",
    "No announcements yet": "Nessun annuncio ancora",
    "New announcement": "Nuovo annuncio",
    "Save announcement": "Salva annuncio",
    "Note: announcements must be a single line. New lines will be ignored.":
      "Nota: gli annunci devono essere su una sola riga. Le nuove righe verranno ignorate.",
    "Delete announcement": "Elimina annuncio",
    "Are you sure you want to remove this announcement?":
      "Sei sicuro/a di voler rimuovere questo annuncio?",
    "{{count}} Announcement": "{{count}} Annuncio",
    "{{count}} Announcement_other": "{{count}} Annunci",
    Content: "Contenuto",
    "Last updated": "Ultimo aggiornamento",
    "Display mode": "Modalità di visualizzazione",
    "Should the announcement be displayed on all playlists and configurations, or only on those with matching tags?":
      "L'annuncio deve essere visualizzato su tutte le playlist e configurazioni o solo su quelle con tag corrispondenti?",
    "Display on all": "Mostra su tutto",
    Tagged: "Etichettato",
    Tags: "Tag",
    "Add start and end times": "Aggiungi ora di inizio e fine",
    "Set the start and end time of the announcement":
      "mposta l'ora di inizio e di fine dell'annuncio",
    "Starts at": "Inizia alle",
    "Ends at": "Termina alle",
    "End time should be after the start time":
      "L'ora di fine dovrebbe essere successiva all'ora di inizio",
    "End time cannot be in the past":
      "L'ora di fine non può essere nel passato",
  },
  CardsFormField: {
    "Add card": "Aggiungi scheda",
    "Add image": "Aggiungi immagine",
    "Remove image": "Rimuovi immagine",
    "Remove card": "Rimuovi carta",
    "Are you sure you want to remove this card?":
      "Sei sicuro di voler rimuovere questa carta?",
    "No cards added": "Nessuna scheda aggiunta",
    "Card title": "Titolo della carta",
    "Drag and drop to re-order cards":
      "Trascina e rilascia per riordinare le carte",
    "Move up": "Sposta in alto",
    "Move down": "Sposta in basso",
    Scheduled: "Pianificato",
    "Starts at": "Inizia alle",
    "Ends at": "Termina alle",
  },
  MediaFormField: {
    "Invalid YouTube link, please try again":
      "Link YouTube non valido, per favore riprova",
    "File not supported, please upload an image or video":
      "File non supportato, per favore carica un'immagine o un video",
    "Upload image/video": "Carica immagine/video",
    "Add YouTube": "Aggiungi YouTube",
    "Add YouTube video": "Aggiungi video di YouTube",
    "Video title": "Titolo del video",
    "YouTube link": "Link YouTube",
    Image: "Immagine",
    Video: "Video",
    YouTube: "YouTube",
    "Something went wrong, file not uploaded":
      "Qualcosa è andato storto, file non caricato",
    "Cancel file upload?": "Annullare il caricamento del file?",
    "Are you sure you want to cancel this upload?":
      "Sei sicuro di voler annullare questo caricamento?",
    "Upload image": "Carica immagine",
    "Upload video": "Carica video",
    "Uploading {{uploadPct}}%": "Caricamento {{uploadPct}}%",
    "Upload complete": "Caricamento completato",
    Name: "Nome",
    "Number seconds image displays before moving to next media item":
      "Numero di secondi in cui l'immagine viene visualizzata prima di passare al prossimo elemento multimediale",
    "Duration (seconds)": "Durata (secondi)",
    "Add image": "Aggiungi immagine",
    "Add video": "Aggiungi video",
    "Delete media": "Elimina media",
    "Are you sure you want to delete this media?":
      "Sei sicuro di voler eliminare questi media?",
    "Update duration": "Aggiorna durata",
    "Edit duration": "Modifica durata",
    "Remove media": "Rimuovi media",
    "No media added": "Nessun media aggiunto",
  },
  MultiColumnScheduleFormField: {
    "Add column": "Aggiungi colonna",
    "Column title": "Titolo della colonna",
    "Remove column": "Rimuovi colonna",
    "Are you sure you want to remove this column?":
      "Sei sicuro di voler rimuovere questa colonna?",
    "Hide past events": "Nascondi eventi passati",
    "Grace period (minutes)": "Periodo di gracia (minuti)",
    "Number of minutes to keep events on screen after they have ended":
      "Numero di minuti per mantenere gli eventi sullo schermo dopo che sono terminati",
  },
  AuthProvider: {
    "Fetching active users": "Recupero utenti attivi",
    "Setting organisations": "Impostazione organizzazioni",
    "Logging you in": "Accesso in corso",
  },
  AccessDeniedScreen: {
    "You don't have access to Digital Displays":
      "Non hai accesso ai Display Digitali",
    "Please contact Teamworks customer support to request access":
      "Si prega di contattare il supporto clienti di Teamworks per richiedere l'accesso",
    "Sign out": "Disconnettersi",
    "Customer support": "Supporto clienti",
    Reload: "Ricarica",
  },
  DeviceGroupsTab: {
    "Delete group": "Elimina gruppo",
    "Are you sure you want to delete this group?":
      "Sei sicuro di voler eliminare questo gruppo?",
    "Device group deleted successfully":
      "Gruppo di dispositivi eliminato correttamente",
    "Something went wrong": "Qualcosa è andato storto",
    "Device group created successfully":
      "Gruppo di dispositivi creato correttamente",
    "Group updated": "Gruppo aggiornato",
    "New group": "Nuovo gruppo",
    "Device groups allow you to group your display devices under one name.":
      "I gruppi di dispositivi ti consentono di raggruppare i tuoi dispositivi di visualizzazione sotto un unico nome.",
    "Device groups": "Gruppi di dispositivi",
    "{{count}} Device group": "{{count}} Gruppo di dispositivi",
    "{{count}} Device group_other": "{{count}} Gruppi di dispositivi",
    "Search groups...": "Cerca gruppi...",
    "Group name": "Nome del gruppo",
    "Device count": "Conteggio dispositivi",
    "Fetching groups": "Recupero di gruppi",
    "No groups": "Nessun gruppo",
    "Group name is required": "Il nome del gruppo è obbligatorio",
    "Please select at least 2 devices": "Seleziona almeno 2 dispositivi",
    "Create group": "Creare un gruppo",
    "Update group": "Aggiorna gruppo",
    "Fetching devices...": "Recupero dispositivi...",
    "Select devices": "Seleziona i dispositivi",
    Devices: "Dispositivi",
    Groups: "Gruppi",
    "Number devices": "Numero di dispositivi",
  },
  ErrorState: {
    "Something went wrong": "Qualcosa è andato storto",
    "Please try again, or contact Teamworks support if the issue persists":
      "Per favore riprova, o contatta il supporto di Teamworks se il problema persiste",
  },
  ProductToursProvider: {
    Back: "Indietro",
    Next: "Avanti",
    Finish: "Fine",
  },
  Drawer: {
    Close: "Chiudi",
  },
  InitialSearchParamsProvider: {
    "Initializing URL params": "Inizializzazione dei parametri URL",
  },
  PreviewsProvider: {
    "Redirecting to preview": "Reindirizzamento all'anteprima",
  },
  PlaylistsScreen: {
    Playlists: "Playlist",
    "New playlist": "Nuova playlist",
    "{{count}} Playlist": "{{count}} Playlist",
    "{{count}} Playlist_other": "{{count}} Playlist",
    Name: "Nome",
    Tags: "Tag",
    "Loading playlists": "Caricamento delle playlist",
    "No playlists have been setup yet": "Nessuna playlist è stata configurata",
    "Playlists allow you to join multiple configurations together.":
      "Le playlist permettono di unire più configurazioni.",
    "No configurations selected": "Nessuna configurazione selezionata",
    "Add configuration": "Aggiungi configurazione",
    Cancel: "Annulla",
    "Create playlist": "Crea playlist",
    "Delete playlist": "Elimina playlist",
    "Are you sure you want to delete this playlist?":
      "Sei sicuro di voler eliminare questa playlist?",
    "Loading playlist": "Caricamento della playlist",
    "Save changes": "Salva modifiche",
    "Configuration already in playlist": "Configurazione già nella playlist",
    "The selected configuration is already part of this playlist, do you want to add it again?":
      "La configurazione selezionata è già parte di questa playlist, vuoi aggiungerla di nuovo?",
    "Remove from playlist": "Rimuovi dalla playlist",
    Configuration: "Configurazione",
    "Number loops": "Numero di cicli",
    Active: "Attivo",
    "Playlist updated": "Playlist aggiornata",
    "Something went wrong": "Qualcosa è andato storto",
    "Last updated": "Ultimo aggiornamento",
    l_layout_loop_description:
      "Gli elementi multimediali si ripeteranno un numero determinato di volte prima di passare alla configurazione successiva",
    media_only_loop_description:
      "Gli elementi multimediali si ripeteranno un numero determinato di volte prima di passare alla configurazione successiva",
    basic_loop_description:
      "Il programma si ripeterà un numero determinato di volte prima di passare alla configurazione successiva",
    multi_column_schedule_loop_description:
      "Il programma si ripeterà un numero determinato di volte prima di passare alla configurazione successiva",
    multi_day_schedule_loop_description:
      "Il programma si ripeterà un numero determinato di volte prima di passare alla configurazione successiva",
    schedule_only_loop_description:
      "Il programma si ripeterà un numero determinato di volte prima di passare alla configurazione successiva",
    "%future added value_loop_description":
      "Tipo di configurazione sconosciuto",
    mvp_loop_description: "Tipo di configurazione sconosciuto",
    nutrition_loop_description:
      "Il menu o la stazione di ristorazione verrà ripetuto un certo numero di volte prima di passare alla configurazione successiva",
    weather_loop_description: "Visualizza il meteo per più località",
    timed_description:
      "La configurazione rimarrà sullo schermo per un tempo determinato prima di passare alla configurazione successiva",
    Looped: "In loop",
    Timed: "Temporizzato",
    "Duration (minutes)": "Durata (minuti)",
    "Search playlists...": "Cerca playlist...",
    "Edit configuration": "Modifica configurazione",
    "New configuration": "Nuova configurazione",
    "Loading configuration": "Caricamento configurazione",
    "Add an existing configuration to the playlist":
      "Aggiungi una configurazione esistente alla playlist",
    "Create a new configuration to add to the playlist":
      "Crea una nuova configurazione da aggiungere alla playlist",
    "No playlists found for '{{searchText}}'":
      "Nessuna playlist trovata per '{{searchText}}'",
    "Last active at {{lastActiveAt}}": "Ultimo attivo alle {{lastActiveAt}}",
    "Device count": "Conteggio dispositivi",
    "Loading screen devices": "Dispositivi schermata di caricamento",
    "Announcements with matching tags will be displayed on screen":
      "Gli annunci con tag corrispondenti saranno visualizzati sullo schermo",
  },
  PlaylistPicker: {
    Playlist: "Elenco di riproduzione",
    "Clear playlist": "Cancella playlist",
    "No playlist selected": "Nessuna playlist selezionata",
    "Searching playlists": "Ricerca nelle playlist",
    "No playlists found for '{{searchText}}'":
      "Nessuna playlist trovata per '{{searchText}}'",
    "No playlists found": "Nessuna playlist trovata",
  },
  TableSearchField: {
    "Clear search text": "Cancella testo di ricerca",
  },
  SettingsScreen: {
    "Organisation settings": "Impostazioni dell'organizzazione",
    "Permission groups": "Gruppi di permessi",
    "User access": "Accesso utente",
    "IP Addresses": "Indirizzi IP",
    "Loading settings": "Caricamento impostazioni",
  },
  PermissionGroupsTab: {
    "New permission group": "Nuovo gruppo di permessi",
    "Permission groups allow you to specify which hub profiles a user has access to when setting up a schedule":
      "I gruppi di permessi consentono di specificare a quali profili hub un utente ha accesso durante la configurazione di un programma",
    "{{count}} Permission group": "{{count}} Gruppo di autorizzazione",
    "{{count}} Permission group_other": "{{count}} Gruppi di permessi",
    "Search permission groups...": "Cerca gruppi di permessi...",
    Name: "Nome",
    "Last updated": "Ultimo aggiornamento",
    "Loading permission groups": "Caricamento gruppi di permessi",
    "No permission groups yet": "Nessun gruppo di permessi ancora",
    "No permission groups found for '{{searchText}}'":
      "Nessun gruppo di permessi trovato per '{{searchText}}'",
    "Permission groups allow you set what hub profiles a user has access to":
      "I gruppi di permessi consentono di impostare a quali profili hub un utente ha accesso",
    "{{count}} hub profile": "{{count}} profilo hub",
    "{{count}} hub profile_other": "{{count}} profili hub",
    "Permission group created": "Gruppo di permessi creato",
    "Something went wrong": "Qualcosa è andato storto",
    "Loading permission group": "Caricamento gruppo di permessi",
    "Delete permission group": "Elimina gruppo di permessi",
    "Are you sure you want to delete this permission group?":
      "Sei sicuro di voler eliminare questo gruppo di permessi?",
    "Permission group deleted": "Gruppo di permessi eliminato",
    "Permission group updated": "Gruppo di permessi aggiornato",
    "Save changes": "Salva modifiche",
    "Permission group name": "Nome del gruppo di permessi",
    "Permitted hub profiles": "Profili hub consentiti",
    "Only permitted profiles will appear for users to select when managing display schedules":
      "Solo i profili consentiti appariranno agli utenti per la selezione durante la gestione dei programmi di visualizzazione",
    "Hub access": "Accesso Hub",
    "Nutrition access": "Accesso Nutrition",
    Hub: "Hub",
    Nutrition: "Nutrition",
    "Accessible apps": "App accessibili",
  },
  UsersTab: {
    "Contact Teamworks support to add or remove users":
      "Contatta il supporto di Teamworks per aggiungere o rimuovere utenti",
    "New user": "Nuovo utente",
    "This is a list of users who have access to Digital Displays":
      "Questa è una lista di utenti che hanno accesso ai Display Digitali",
    "{{count}} User": "{{count}} Utente",
    "{{count}} User_other": "{{count}} Utenti",
    "Search users...": "Cerca utenti...",
    Name: "Nome",
    "Last updated": "Ultimo aggiornamento",
    "Loading users": "Caricamento utenti",
    "Loading user": "Caricamento utente",
    "No users yet": "Nessun utente ancora",
    "No users found for '{{searchText}}'":
      "Nessun utente trovato per '{{searchText}}'",
    "Super admin": "Super amministratore",
    "{{count}} permission group": "{{count}} gruppo di autorizzazioni",
    "{{count}} permission group_other": "{{count}} gruppi di permessi",
    "User updated": "Utente aggiornato",
    "Something went wrong": "Qualcosa è andato storto",
    "Save changes": "Salva modifiche",
    superadmin_description:
      "Questo utente è un super amministratore. Ha accesso a tutti i profili del hub, indipendentemente dai gruppi di permessi assegnati.",
    admin_description:
      "Questo utente ha accesso solo ai profili del hub assegnati tramite i gruppi di permessi.",
    "%future added value_description": "Ruolo sconosciuto",
  },
  ManageIpAddressesTab: {
    "Edit IP addresses": "Modifica indirizzi IP",
    "For systems which do not support our QR setup, these permitted IP addresses can be used to allow you access to hardcoded URLs.":
      "Per i sistemi che non supportano la nostra configurazione QR, questi indirizzi IP consentiti possono essere utilizzati per consentire l'accesso agli URL codificati.",
    "{{count}} Permitted IP address": "{{count}} Indirizzo IP consentito",
    "{{count}} Permitted IP address_other": "{{count}} Indirizzi IP consentiti",
    "No IP addresses": "Nessun indirizzo IP",
    "You have not added any permitted IP addresses yet":
      "Non hai ancora aggiunto nessun indirizzo IP consentito",
    "Permitted IP addresses updated": "Indirizzi IP consentiti aggiornati",
    "Something went wrong": "Qualcosa è andato storto",
    "Save changes": "Salva modifiche",
    "No permitted IPs yet": "Nessun IP consentito ancora",
    "Add another": "Aggiungi un altro",
  },
  PermissionGroupPicker: {
    "Add permission groups": "Aggiungi gruppi di permessi",
    "No permission groups added": "Nessun gruppo di permessi aggiunto",
    "Show {{count}} more": "Mostra {{count}} di più",
    "Clear search text": "Cancella testo di ricerca",
    "Search permission groups...": "Cerca gruppi di permessi...",
    "Loading permission groups": "Caricamento gruppi di permessi",
    "{{count}} hub profile": "{{count}} profilo del hub",
    "{{count}} hub profile_other": "{{count}} profili del hub",
    "Remove permission group": "Rimuovi gruppo di permessi",
    "No users found": "Nessun utente trovato",
    "We couldn't find any users matching '{{searchText}}'":
      "Non abbiamo trovato utenti corrispondenti a '{{searchText}}'",
    "No permission groups found": "Nessun gruppo di permessi trovato",
    "Confirm selection": "Conferma la selezione",
  },
  TableProvider: {
    "{{count}} Selected": "{{count}} Selezionato",
  },
  ComponentPicker: {
    "Pick a component": "Scegli un componente",
    media: "Media",
    schedule: "Pianificazione",
    cards: "Carte",
    multi_day_schedule: "Pianificazione di più giorni",
    multi_column_schedule: "Pianificazione a più colonne",
    nutrition_dining_station: "Postazione di visualizzazione della nutrizione",
    nutrition_menu: "Menu nutrizionale",
    weather: "Tempo atmosferico",
    "%future added value": "Componente sconosciuto",
    Add: "Aggiungere",
  },
  TagPicker: {
    "Add tags": "Aggiungi tag",
    Clear: "Cancella",
    "Searching tags": "Ricerca dei tag",
    "No tags selected": "Nessun tag selezionato",
    "Remove tag": "Rimuovi tag",
    "No tags found matching '{{searchText}}'":
      "Nessun tag trovato corrispondente a '{{searchText}}'",
    "Something went wrong": "Qualcosa è andato storto",
    "Create tag '{{tagName}}'": "Crea tag '{{tagName}}'",
    "Start typing to create a new tag":
      "Inizia a digitare per creare un nuovo tag",
    "Delete tag": "Elimina tag",
    "Are you sure you want to delete the tag '{{tagName}}'?":
      "Sei sicuro di voler eliminare il tag '{{tagName}}'?",
    "Tag deleted": "Tag eliminato",
  },
  DeviceScheduleScreen: {
    Devices: "Dispositivi",
    "Device schedule": "Pianificazione del dispositivo",
    "Schedule item created": "Elemento di pianificazione creato",
    "Something went wrong": "Qualcosa è andato storto",
    "Please correct any errors and try again":
      "Correggi eventuali errori e riprova",
    "Create schedule item": "Crea elemento di pianificazione",
    "Schedule item updated": "Elemento di pianificazione aggiornato",
    "Update schedule item": "Aggiorna elemento di pianificazione",
    "End time should be after the start time":
      "L'orario di fine deve essere dopo l'orario di inizio",
    "Schedule item cannot be in the past":
      "L'elemento di pianificazione non può essere nel passato",
    "Starts at": "Inizia alle",
    "Ends at": "Termina alle",
    "Add content": "Aggiungi contenuto",
    "Loading schedule": "Caricamento della pianificazione",
    "Delete schedule item": "Elimina elemento di pianificazione",
    "Are you sure you want to delete this schedule item?":
      "Sei sicuro di voler eliminare questo elemento di pianificazione?",
    "Schedule item deleted": "Elemento di pianificazione eliminato",
    "Saving this event will shorten or replace {{count}} item. Are you sure you want to continue?":
      "Salvare questo evento accorcerà o sostituirà {{count}} elemento. Sei sicuro di voler continuare?",
    "Saving this event will shorten or replace {{count}} item. Are you sure you want to continue?_other":
      "Salvare questo evento accorcerà o sostituirà {{count}} elementi. Sei sicuro di voler continuare?",
    "Conflicts found": "Conflitti trovati",
  },
  Calendar: {
    Previous: "Precedente",
    Today: "Oggi",
    Next: "Successivo",
    View: "Visualizza",
    month: "Mese",
    week: "Settimana",
    work_week: "Settimana lavorativa",
    day: "Giorno",
    agenda: "Agenda",
  },
  ScheduleConflictChecker: {
    "Checking for conflicts": "Verifica di conflitti",
    "No conflicts found": "Nessun conflitto trovato",
    "{{count}} conflict found": "{{count}} conflitto trovato",
    "{{count}} conflict found_other": "{{count}} conflitti trovati",
    "Conflicts found": "Conflitti trovati",
  },
  LocationPicker: {
    "Search locations": "Cerca località",
    "Clear search text": "Cancella testo di ricerca",
    "Searching locations": "Ricerca località",
    "No results": "Nessun risultato",
  },
  NutritionMenuField: {
    "Please select a menu": "Si prega di selezionare un menu",
    "Include images": "Includi immagini",
    "Include descriptions": "Includi descrizioni",
    "Include macros": "Includi macronutrienti",
    "Unknown menu": "Menu sconosciuto",
    "Please select a dining station":
      "Si prega di selezionare una stazione di ristorazione",
    "Unknown dining station": "Stazione di ristorazione sconosciuta",
  },
  NutritionMenuPicker: {
    "Unknown menu date": "Data del menu sconosciuta",
    "Must be later than the 'after' time": "Deve essere dopo l'orario 'dopo'",
    "Menu date": "Data del menu",
    "Search menus": "Cerca menù",
    "Nutrition menu": "Menu nutrizionale",
    "Remove menu": "Rimuovi menu",
    "Searching menus": "Ricerca di menu",
    "{{count}} menu found": "{{count}} menu trovato",
    "{{count}} menu found_other": "{{count}} menu trovati",
    "No menus found": "Nessun menu trovato",
    "Try changing the date range and search again":
      "Prova a modificare l'intervallo di date e cerca di nuovo",
    "Dining station": "Stazione di ristorazione",
    "Loading stations": "Caricamento delle stazioni",
    "Failed to fetch menus": "Impossibile recuperare i menu",
    "Something went wrong, please try again":
      "Qualcosa è andato storto, per favore riprova",
    "Failed to fetch menu dining stations":
      "Impossibile recuperare le stazioni di ristorazione del menu",
  },
  WeatherFormField: {
    "Number of days ahead to fetch weather. Max 7":
      "Numero di giorni per ottenere le previsioni meteo. Massimo 7",
    "Number of days": "Numero di giorni",
    "Add location": "Aggiungi posizione",
    "Remove location": "Rimuovi posizione",
    "Are you sure you want to remove this location?":
      "Sei sicuro di voler rimuovere questa posizione?",
    Date: "Data",
    "Default location": "Posizione predefinita",
    Location: "Posizione",
    "Below, you can add different locations for certain dates, which will override the default location for those days.":
      "Di seguito, puoi aggiungere diverse località per determinate date, che sostituiranno la località predefinita per quei giorni.",
    "This is the default location for displaying weather, unless overridden by another location on a given date.":
      "Questa è la località predefinita per visualizzare il meteo, a meno che non venga sostituita da un'altra località in una data specifica.",
  },
  ScreenConfigFrom: {
    "Dates must be unique": "Dates must be unique",
    "Card text must be at least 4 characters long":
      "Card text must be at least 4 characters long",
  },
};
