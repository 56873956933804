import * as Sentry from "@sentry/react";
import { config } from "../config";
import appConfig from "../assets/app.json";

export const errorReporting = {
  initialize: async () => {
    if (config.isDevelopment) {
      console.log("errorReporting: init");
    } else {
      Sentry.init({
        dsn: "https://21ef91536a0b9b53b507bd9acb5d7def@o379212.ingest.us.sentry.io/4507095523000320",
        debug: config.isDevelopment,
        enabled: !config.isDevelopment,
      });

      Sentry.setTag("app_version", appConfig.version);
    }
  },
  setUserData: (userId: string, orgId: string) => {
    if (config.isDevelopment) {
      console.log("errorReporting: Set user data");
    } else {
      Sentry.setTag("user_id", userId);
      Sentry.setTag("org_id", orgId);
    }
  },
  sendError: (error: Error) => {
    if (config.isDevelopment) {
      console.error("errorReporting: sendError", { error });
    } else {
      Sentry.captureException(error);
    }
  },
  sendMessage: (message: string, data?: object) => {
    if (config.isDevelopment) {
      console.warn("errorReporting: sendMessage", { message, data });
    } else {
      Sentry.captureException(
        new Error(`${message}: ${JSON.stringify(data ?? {})}`),
      );
    }
  },
};
