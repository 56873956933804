import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { omit } from "lodash";
import { useTranslation } from "react-i18next";
import { Dialog, DialogActions } from "@mui/material";
import { ID } from "../helpers/types";
import { v4 as uuid } from "uuid";
import { AppText, Heading } from "../components/Typography";
import { Column } from "../components/Flex";
import { Button, ButtonProps } from "../components/Button";
import { Logout } from "@mui/icons-material";
import { useScreenSize } from "./ScreenSizeProvider";
import { Spacer } from "../components/Spacer";

type DialogContextType = {
  alert: (config: {
    title: string;
    body?: ReactNode;
    onOk?: () => void;
    confirmLabel?: string;
  }) => void;
  confirm: (config: {
    title: string;
    body: ReactNode;
    cancelLabel?: string;
    confirmLabel?: string;
    confirmVariant?: ButtonProps["variant"];
    confirmColor?: ButtonProps["color"];
    onCancel?: () => void;
    onConfirm: () => Promise<void> | void;
  }) => void;
  beforeClose: (config: {
    title?: string;
    body?: ReactNode;
    isDirty: boolean;
    onClose: () => void;
    onCancel?: () => void;
  }) => void;
};

type RenderDialogProps = { confirming: boolean; closing: boolean };

type Dialog = {
  id: ID;
  render: (props: RenderDialogProps) => ReactNode;
};

const DialogContext = createContext<DialogContextType>(null!);

export function DialogProvider(props: { children: ReactNode }) {
  const { t } = useTranslation("DialogProvider");
  const screenSize = useScreenSize();
  const [dialogs, setDialogs] = useState<Array<Dialog>>([]);
  const [confirmingDialogs, setConfirmingDialogs] = useState<{
    [key: string]: true;
  }>({});
  const [closingDialogs, setClosingDialogs] = useState<{ [key: string]: true }>(
    {},
  );
  const removeDialog = useCallback((id: ID) => {
    setClosingDialogs((closingDialogs) => ({
      ...closingDialogs,
      [id]: true,
    }));

    // Give the dialog time to fade out
    setTimeout(() => {
      // remove the dialog from our array
      setDialogs((dialogs) => dialogs.filter((dialog) => dialog.id !== id));
      // remove it from the closing state
      setClosingDialogs((closingDialogs) => omit(closingDialogs, id));
    }, 150);
  }, []);

  const value: DialogContextType = useMemo(() => {
    return {
      alert({ title, body, onOk, confirmLabel }) {
        setDialogs((dialogs) => {
          const id = uuid() as ID;
          return [
            ...dialogs,
            {
              id,
              render: ({ closing }) => (
                <Dialog
                  key={id}
                  onClose={() => removeDialog(id)}
                  open={!closing}
                  fullWidth
                  onClick={(ev) => ev.stopPropagation()}
                >
                  <Column padding="medium" gap="small">
                    <Heading>{title}</Heading>
                    {typeof body === "string" ? (
                      <AppText>{body}</AppText>
                    ) : (
                      body
                    )}
                  </Column>
                  <DialogActions>
                    <Button
                      autoFocus
                      onClick={() => {
                        removeDialog(id);
                        onOk?.();
                      }}
                    >
                      {confirmLabel ?? t("Ok")}
                    </Button>
                  </DialogActions>
                </Dialog>
              ),
            },
          ];
        });
      },
      confirm({
        title,
        body,
        onConfirm,
        onCancel,
        confirmLabel,
        confirmVariant,
        confirmColor,
        cancelLabel,
      }) {
        const id = uuid() as ID;
        return setDialogs((dialogs) => [
          ...dialogs,
          {
            id,
            render: ({ confirming, closing }: RenderDialogProps) => {
              return (
                <Dialog
                  fullWidth
                  key={id}
                  onClose={() => {
                    if (confirming) {
                      return;
                    }
                    removeDialog(id);
                    onCancel?.();
                  }}
                  open={!closing}
                  onClick={(ev) => ev.stopPropagation()}
                >
                  <Column padding="small" gap="small">
                    <Heading>{title}</Heading>
                    <AppText>{body}</AppText>
                  </Column>
                  <DialogActions>
                    <Button
                      disabled={confirming}
                      variant="secondary"
                      onClick={() => {
                        removeDialog(id);
                        onCancel?.();
                      }}
                    >
                      {cancelLabel ?? t("Cancel")}
                    </Button>
                    <Button
                      color={confirmColor}
                      loading={confirming}
                      onClick={() => {
                        if (confirming) {
                          return;
                        }
                        const result = onConfirm() ?? {};
                        if (!("then" in result)) {
                          removeDialog(id);
                        } else {
                          setConfirmingDialogs((confirming) => ({
                            ...confirming,
                            [id]: true,
                          }));
                          (result as Promise<void>).then(() => {
                            setConfirmingDialogs((confirming) =>
                              omit(confirming, id),
                            );
                            removeDialog(id);
                          });
                        }
                      }}
                      variant={confirmVariant ?? "default"}
                    >
                      {confirmLabel ?? t("Confirm")}
                    </Button>
                  </DialogActions>
                </Dialog>
              );
            },
          },
        ]);
      },
      beforeClose({ title, body, isDirty, onClose, onCancel }) {
        if (!isDirty) {
          return onClose();
        }

        setDialogs((dialogs) => {
          const id = uuid() as ID;
          return [
            ...dialogs,
            {
              id,
              render: ({ closing }) => (
                <Dialog
                  fullWidth
                  key={id}
                  maxWidth="sm"
                  onClose={() => {
                    onCancel?.();
                    removeDialog(id);
                  }}
                  open={!closing}
                  onClick={(ev) => ev.stopPropagation()}
                >
                  <Column padding="small">
                    <Heading>{title ?? t("Close without saving?")}</Heading>
                    <Spacer size="smaller" />
                    <AppText>
                      {body ?? t("Any unsaved changes will be lost")}
                    </AppText>
                    <Spacer size="medium" />
                    <Column
                      direction={screenSize === "sm" ? "column" : "row"}
                      justifyContent="flex-end"
                      gap="small"
                    >
                      <Button
                        autoFocus
                        variant="secondary"
                        onClick={() => {
                          onCancel?.();
                          removeDialog(id);
                        }}
                      >
                        {t("Back to form")}
                      </Button>
                      <Button
                        autoFocus
                        onClick={() => {
                          removeDialog(id);
                          onClose?.();
                        }}
                        color="destructive"
                        startIcon={Logout}
                      >
                        {t("Close without saving")}
                      </Button>
                    </Column>
                  </Column>
                </Dialog>
              ),
            },
          ];
        });
      },
    };
  }, [removeDialog, t, screenSize]);

  return (
    <DialogContext.Provider value={value}>
      {props.children}
      {dialogs.map((dialog) =>
        dialog.render({
          confirming: confirmingDialogs[dialog.id],
          closing: closingDialogs[dialog.id],
        }),
      )}
    </DialogContext.Provider>
  );
}

export function useDialog() {
  const context = useContext(DialogContext);
  if (context == null) {
    throw new Error(
      "Used useDialog outside of a component tree warpped in a DialogProvider",
    );
  }
  return context;
}
