import { type Localisation } from "./en";

export const spanish: Localisation = {
  dateFormats: {
    datePickerLocale: "es",
    timeFormat: "HH:mm", // 14:30
    dayFormat: "do", // 12th
    dayMonthFormat: "do MMMM", // 1st April
    dayMonthYearFormat: "do MMMM yyyy", // 11th January 1988
    dayDateMonthYearFormat: "E, do MMMM yyyy", // Mon, 1st April 2024
    dayMonthYearTimeFormat: "do MMMM yyyy, HH:mm", // 11th January 1988, 14:30
  },
  App: {
    "Digital Displays": "Pantallas Digitales",
    Loading: "Cargando",
    "Checking authentication": "Verificando autenticación",
    "Redirecting to login": "Redirigiendo al inicio de sesión",
    "Sign in with Teamworks": "Iniciar sesión con Teamworks",
    Initializing: "Inicializando",
    "Verifing authentication": "Verificando autenticación",
    Redirecting: "Redirigiendo",
    "Welcome back!": "¡Bienvenido de nuevo!",
    "Login to get started": "Inicia sesión para comenzar",
  },
  AppSwitcher: {
    arms: "Compliance + Recruiting",
    hub: "Hub",
    inflcr: "Influencer",
    academics: "Academics",
    communities: "Communities",
    pulse: "Pulse",
    pathways: "Pathways",
    nutrition: "Nutrition",
    ams: "AMS",
    tv: "Digital Displays",
    trac: "Inventory Management",
    strength: "Strength + Conditioning",
    wallet: "Wallet",
    gm: "General Manager",
  },
  Layout: {
    "Checking authentication": "Comprobando la autenticación",
    Dashboard: "Panel",
    Configurations: "Configuraciones",
    Devices: "Dispositivos",
    Themes: "Temas",
    Announcements: "Announcements",
    "Organisation settings": "Configuración de la organización",
    "Teamworks Help": "Ayuda de Teamworks",
    "Teamworks Apps": "Aplicaciones de Teamworks",
    Settings: "Ajustes",
    "Privacy policy": "Política de privacidad",
    Logout: "Cerrar sesión",
    "Loading, please wait...": "Cargando, por favor espere...",
    Users: "Usuarios",
    "Switch organisation": "Cambiar organización",
    "Signing you out": "Cerrando sesión",
    "Open Menu": "Abrir Menú",
    "Close Menu": "Cerrar Menú",
    Playlists: "Listas de reproducción",
  },
  ConfigsScreen: {
    "{{count}} Configuration": "{{count}} Configuración",
    "{{count}} Configuration_other": "{{count}} Configuraciones",
    "New configuration": "Nueva configuración",
    "Configurations allow you to customise the look and content of your displays.":
      "Las configuraciones te permiten personalizar el aspecto y contenido de tus pantallas.",
    "Loading configurations": "Cargando configuraciones",
    Configurations: "Configuraciones",
    Name: "Nombre",
    Tags: "Etiquetas",
    Template: "Plantilla",
    Theme: "Tema",
    "Device count": "Recuento de dispositivos",
    "Created at": "Creado en",
    "No configurations have been setup yet":
      "No se han configurado configuraciones aún",
    "No configurations found for '{{searchText}}'":
      "No se encontraron configuraciones para '{{searchText}}'",
    "Displays allow you to cast scheduled events and media to any device with an internet browser":
      "Las pantallas te permiten transmitir eventos programados y medios a cualquier dispositivo con un navegador de internet",
    "Create one now": "Crea uno ahora",
    "Create configuration": "Crear configuración",
    "Add custom videos, YouTube videos and images":
      "Añadir vídeos personalizados, vídeos de YouTube e imágenes",
    "Filter calendar to present a live schedule":
      "Filtrar calendario para presentar un horario en vivo",
    "Display weather for multiple locations":
      "Mostrar el clima para múltiples ubicaciones",
    "A general overview for up to a week in advance":
      "Una visión general de hasta una semana de anticipación",
    "Show temperature, wind and probability of precipitation":
      "Mostrar temperatura, viento y probabilidad de precipitación",
    "Cycle through important updates on cards":
      "Recorrer actualizaciones importantes en tarjetas",
    "Includes team crest, time and date":
      "Incluye escudo del equipo, hora y fecha",
    "Display a schedule over multiple days":
      "Mostrar un horario durante varios días",
    "Display daily schedule for different teams and groups.":
      "Mostrar horario diario para diferentes equipos y grupos.",
    "Configuration deleted": "Configuración eliminada",
    "Are you sure you want to delete this configuration?":
      "¿Estás seguro de que quieres eliminar esta configuración?",
    "Delete configuration": "Eliminar configuración",
    "Fetching configuration": "Obteniendo configuración",
    "Something went wrong": "Algo salió mal",
    "Edit configuration": "Editar configuración",
    "Includes background image from theme": "Incluye imagen de fondo del tema",
    "Last active at {{lastActiveAt}}": "Último activo en {{lastActiveAt}}",
    "Loading screen devices": "Dispositivos de pantalla de carga",
    "Search configurations...": "Buscar configuraciones...",
    "Save changes": "Guardar cambios",
    "Last updated": "Última actualización",
    "Clear template": "Borrar plantilla",
    "No template selected": "Ninguna plantilla seleccionada",
    "Display a full menu or an individual station":
      "Mostrar un menú completo o una estación individual",
    "Optionally show images, descriptions and macros":
      "Opcionalmente mostrar imágenes, descripciones y macronutrientes",
  },
  DashboardScreen: { Dashboard: "Panel" },
  DevicesScreen: {
    Devices: "Dispositivos",
    Groups: "Grupos",
    "{{count}} Device": "{{count}} Dispositivo",
    "{{count}} Device_other": "{{count}} Dispositivos",
    "New device": "Nuevo dispositivo",
    "Devices are the physical displays you have linked to your account.":
      "Los dispositivos son las pantallas físicas que ha vinculado a su cuenta.",
    "No devices have been setup yet": "Aún no se han configurado dispositivos",
    "No devices found for '{{searchText}}'":
      "No se encontraron dispositivos para '{{searchText}}'",
    "Device name": "Nombre del dispositivo",
    Facility: "Instalación",
    Configuration: "Configuración",
    "Facility not set": "Instalación no configurada",
    "Config not set": "Configuración no establecida",
    "Create device": "Crear dispositivo",
    "Update device": "Actualizar dispositivo",
    "Screen device updated": "Dispositivo de pantalla actualizado",
    "Something went wrong": "Algo salió mal",
    "Adding new display device":
      "Agregar un nuevo dispositivo de visualización",
    addNewDeviceDescription:
      "Para agregar un nuevo dispositivo, visita <0>{{url}}</0> en el dispositivo que deseas vincular.",
    "You will be shown a QR code which can be scanned by your camera.":
      "Se te mostrará un código QR que puede ser escaneado por tu cámara.",
    "Start scanning": "Comenzar a escanear",
    Ok: "De acuerdo",
    "Are you sure you want to delete this device?":
      "¿Estás seguro de que quieres eliminar este dispositivo?",
    "Delete device": "Eliminar dispositivo",
    "Fetching devices": "Recuperando dispositivos",
    Timezone: "Zona horaria",
    "Display name is required": "Se requiere el nombre de visualización",
    "Display name must be at least 3 characters long":
      "El nombre de visualización debe tener al menos 3 caracteres",
    "Device setup successful": "Configuración del dispositivo exitosa",
    "Switch organisation": "Cambiar organización",
    "Search devices...": "Buscar dispositivos...",
    "Device not found": "Dispositivo no encontrado",
    "It may have been deleted, or you do not have permission to view it.":
      "Puede que haya sido eliminado o que no tengas permiso para verlo.",
    "Loading device": "Cargando dispositivo",
    "Last updated": "Última actualización",
    "Playlist not set": "Lista de reproducción no configurada",
    "Bulk actions": "Acciones masivas",
    "Edit selected": "Editar seleccionado",
    "Delete selected": "Eliminar seleccionado",
    "Delete {{count}} devices": "Eliminar {{count}}  dispositivos",
    "Are you sure you want to delete these devices?":
      "¿Estás seguro de que deseas eliminar estos dispositivos?",
    "Devices deleted": "Dispositivos eliminados",
    "Screen devices updated": "Dispositivos de pantalla actualizados",
    "Update {{count}} devices": "Actualizar {{count}} dispositivos",
    "Device deleted": "Dispositivo eliminado",
    "Select all": "Seleccionar todo",
    "Deselect all": "Deseleccionar todo",
    "Create virtual device": "Crear dispositivo virtual",
    "Virtual device URL": "URL del dispositivo virtual",
    "This URL can be used to integrate with systems that do not support our QR code setup.":
      "Esta URL se puede utilizar para integrarse con sistemas que no admiten nuestra configuración de código QR.",
    "Note: This URL will only be accessable from networks with an approved IP address.":
      "Nota: Esta URL solo será accesible desde redes con una dirección IP aprobada.",
    or: "o",
    "Provides a hardcoded URL, for use with systems which do not support the QR setup":
      "Proporciona una URL codificada, para su uso con sistemas que no admiten la configuración de QR",
    "Manage schedule": "Gestionar horario",
    "Please correct any errors and try again":
      "Corrija los errores y vuelva a intentarlo",
    "Skip interaction screen": "Omitir pantalla de interacción",
    skip_interaction_description:
      "La pantalla de interacción muestra un botón que debe presionarse antes de mostrar cualquier contenido. Esto ayuda a evitar problemas de reproducción automática en navegadores modernos, pero puede no ser necesario en algunos sistemas.",
  },
  DeviceModePicker: {
    "Device mode": "Modo del dispositivo",
    "Should this device display a configuration or a playlist?":
      "¿Debe este dispositivo mostrar una configuración o una lista de reproducción?",
    "Should this device display a configuration or a playlist?_other":
      "¿Deben estos dispositivos mostrar una configuración o una lista de reproducción?",
    config: "Configuración",
    playlist: "Lista de reproducción",
  },
  NotFoundScreen: {
    "Oops! You seem to be lost.": "Oops! You seem to be lost.",
    "Return to dashboard": "Volver al panel",
  },
  ThemeScreen: {
    "New theme": "Nuevo tema",
    "Themes allow you to customise the colours of your configurations.":
      "Los temas te permiten personalizar los colores de tus configuraciones.",
    Themes: "Temas",
    "You don't have any themes yet": "Todavía no tienes ningún tema",
    "Themes allow you to customise the colours used by your templates":
      "Los temas te permiten personalizar los colores utilizados por tus plantillas",
    "Create one now": "Crear una ahora",
    "Theme name": "Nombre del tema",
    "Last updated": "Última actualización",
    "Create theme": "Crear tema",
    "Update theme": "Actualizar tema",
    background: "Fondo",
    backgroundText: "Texto de fondo",
    accent: "Acento",
    accentText: "Texto acentuado",
    announcementBar: "Barra de anuncios",
    announcementBarText: "Texto de la barra de anuncios",
    "Invalid hex code": "Código hexadecimal no válido",
    "New theme created": "Nuevo tema creado",
    "Theme updated": "Tema actualizado",
    "Screen theme deleted": "Tema de pantalla eliminado",
    "Something went wrong": "Algo salió mal",
    "Are you sure you want to delete this theme?":
      "¿Estás seguro de que deseas eliminar este tema?",
    "Delete theme": "Eliminar tema",
    "Loading themes": "Cargando temas",
    "Upload logo": "Subir logotipo",
    "Remove logo": "Eliminar logotipo",
    "Upload background image": "Subir imagen de fondo",
    "Remove image": "Quita la imagen",
    "Theme not found": "Tema no encontrado",
    "It may have been deleted, or you do not have permission to view it":
      "Puede que haya sido eliminado o que no tengas permiso para verlo",
    "Loading theme": "Cargando tema",
    "Search themes...": "Buscar temas...",
    "{{count}} Theme": "{{count}} Tema",
    "{{count}} Theme_other": "{{count}} Temas",
    "What's a theme?": "¿Qué es un tema?",
    "Themes allow you to customise the appearance of your configurations":
      "Los temas te permiten personalizar la apariencia de tus configuraciones",
    "Clicking this button will begin the process of creating a new one.":
      "Hacer clic en este botón iniciará el proceso de creación de uno nuevo.",
    "Give your theme a memorable name": "Dale a tu tema un nombre memorable",
    "This will help you find it later when assigning it to a configuration.":
      "Esto te ayudará a encontrarlo más tarde cuando lo asignes a una configuración.",
    "Upload a logo (optional)": "Subir un logo (opcional)",
    "If you do not upload a logo, a Teamworks logo will appear in its place.":
      "Si no subes un logo, aparecerá un logo de Teamworks en su lugar.",
    "Upload a background image (optional)":
      "Subir una imagen de fondo (opcional)",
    "Some configurations make use of a background image. If an image is not uploaded, the background colour selected below will be used.":
      "Algunas configuraciones utilizan una imagen de fondo. Si no se sube una imagen, se usará el color de fondo seleccionado a continuación.",
    "Customise the colours you want to use":
      "Personaliza los colores que quieres usar",
    "The 'text' options should be a contrasting colour, to ensure it can be easily read.":
      "Las opciones de 'texto' deben ser de un color contrastante, para asegurar que se puedan leer fácilmente.",
    "Once you are ready, save the theme by clicking this button":
      "Una vez que estés listo, guarda el tema haciendo clic en este botón",
    "Your theme will then be available to be selected when managing a configuration.":
      "Tu tema estará disponible para ser seleccionado al gestionar una configuración.",
    "Theme contrast": "Theme contrast",
    "Some content appears in containers that are semi transparent and overlayed on your chosen background colour.":
      "Parte del contenido aparece en contenedores que son semitransparentes y se superponen al color de fondo elegido.",
    "Would you like to lighten or darken your background?":
      "¿Quieres aclarar u oscurecer tu fondo?",
    Dark: "Oscuro",
    Light: "Ligero",
    "Theme preview": "Vista previa del tema",
    Close: "Cerca",
    Preview: "Avance",
    "No themes found for '{{searchText}}'":
      "No se encontraron temas para '{{searchText}}'",
    or: "o",
    "Use organisation logo": "Usar el logotipo de la organización",
  },
  ScreenTemplateNames: {
    "L shape with media": "Forma de L con medios",
    "Media only": "Solo medios",
    "Schedule only": "Sólo programar",
    "Multi day schedule": "Horario de varios días",
    "Multi column schedule": "Horario de varias columnas",
    Basic: "Básico",
    Nutrition: "Nutrición",
    Weather: "Clima",
  },
  DialogProvider: {
    Cancel: "Cancelar",
    Confirm: "Confirmar",
    Close: "Cerrar",
    Ok: "Aceptar",
    "Close without saving?": "¿Cerrar sin guardar?",
    "Close without saving": "Cerrar sin guardar",
    "Any unsaved changes will be lost": "Se perderán los cambios no guardados",
    "Back to form": "Volver al formulario",
  },
  ConfigurationPicker: {
    Configuration: "Configuración",
    "No configurations found for '{{searchText}}'":
      "No se encontraron configuraciones para '{{searchText}}'",
    "No configurations found": "No se encontraron configuraciones",
    "No configuration selected": "No se ha seleccionado ninguna configuración",
    "Clear configuration": "Borrar configuración",
    "Searching configurations": "Buscando configuraciones",
  },
  ErrorScreen: {
    "Something went wrong": "Algo salió mal",
    "Sorry for the inconvenience, our engineers have been notified and will be working on a fix":
      "Lo sentimos por los inconvenientes, nuestros ingenieros han sido notificados y estarán trabajando en una solución",
    "If this issue persists, please contact Teamworks customer support":
      "Si este problema persiste, por favor contacta al soporte al cliente de Teamworks",
    Reload: "Recargar",
    "Customer support": "Soporte al cliente",
  },
  ThemePicker: {
    Theme: "Tema",
    "Clear theme": "Limpiar tema",
    "Screen theme": "Tema de pantalla",
    "No theme selected": "No se ha seleccionado ningún tema",
    "No themes found matching '{{searchText}}'":
      "No se encontraron temas que coincidan con '{{searchText}}'",
    "No themes found": "No se encontraron temas",
    "Loading themes...": "Cargando temas...",
  },
  MarkdownField: {
    Preview: "Avance",
    Remove: "Eliminar",
    "Nothing to preview": "Nada que previsualizar",
    Write: "Escribir",
  },
  OrganisationSwitcher: {
    "Fetching organisations": "Obteniendo organizaciones",
    "Select an organisation": "Seleccionar una organización",
    "Switching profile": "Cambiando perfil",
  },
  TimeZonePicker: {
    Timezone: "Zona horaria",
  },
  MatchesDrawer: {
    "Add match": "Agregar partido",
    "Fetching event": "Obteniendo evento",
    "Event title": "Título del evento",
    "Starts at": "Comienza a las",
    "Create event": "Crear evento",
  },
  FileUploadField: {
    "Upload file": "Subir archivo",
    "Remove file": "Eliminar archivo",
  },
  HubProfilePicker: {
    Search: "Buscar",
    "Clear search text": "Borrar texto de búsqueda",
    "Search text too short": "Texto de búsqueda demasiado corto",
    "Please add at least 3 characters to begin a search":
      "Por favor, añade al menos 3 caracteres para comenzar una búsqueda",
    "No calendars found": "No se encontraron calendarios",
    "We couldn't find any calendars matching '{{searchText}}'":
      "No pudimos encontrar ningún calendario que coincida con '{{searchText}}'",
    "Search...": "Buscar...",
    "Confirm selection": "Confirmar selección",
    Close: "Cerca",
    "Add Teamworks calendars": "Añadir calendarios de Teamworks",
    "Loading profiles": "Cargando perfiles",
    "Search text must have at least 3 characters":
      "El texto de búsqueda debe tener al menos 3 caracteres.",
    "No results found": "No se han encontrado resultados",
    "Calendar details": "Detalles del calendario",
    "Remove calendar": "Eliminar calendario",
    "Are you sure you want to remove this calendar?":
      "¿Estás seguro de que quieres eliminar este calendario?",
    "No calendars added": "No se han añadido calendarios",
    "Unknown profile": "Perfil desconocido",
    "You don't have access to this hub profile":
      "No tienes acceso a este perfil de hub",
    "Your administrator may need to review your permission groups.":
      "Es posible que tu administrador deba revisar tus grupos de permisos.",
    "Show {{count}} more": "Mostrar {{count}} más",
  },
  ScreenEventsPicker: {
    "Loading events": "Cargando eventos",
    "Event countdown": "Cuenta regresiva del evento",
    "Create event": "Crear evento",
    "Update event": "Actualizar evento",
    "Event title": "Título del evento",
    "Starts at": "Comienza a las",
    "Add opponent badge": "Añadir insignia de oponente",
    "Remove opponent badge": "Eliminar insignia de oponente",
    "Delete event": "Eliminar evento",
    "Are you sure you want to delete this event?":
      "¿Estás seguro de que quieres eliminar este evento?",
    "Delete now": "Eliminar ahora",
    "Opponent badge position": "Posición de la insignia del oponente",
    "Should the opponent badge be on the left or right?":
      "¿Debe estar la insignia del oponente a la izquierda o a la derecha?",
    "i.e are you playing home or away?":
      "es decir, ¿estás jugando en casa o fuera?",
    "Edit event": "Editar evento",
  },
  ScreenConfigDrawer: {
    "Loading configuration": "Cargando configuración",
    "Number of days the schedule should span. Max 5":
      "Número de días que debe abarcar el horario. Máx. 5",
    "Number of days": "Número de días",
    "Delete configuration": "Eliminar configuración",
    "Are you sure you want to delete this configuration?":
      "¿Estás seguro de que deseas eliminar esta configuración?",
    "Configuration deleted": "Configuración eliminada",
    "Something went wrong": "Algo salió mal",
    "Save changes": "Guardar cambios",
    "Configuration updated": "Configuración actualizada",
    cards: "Tarjetas",
    cards_desc: "Selecciona tarjetas para añadir texto o imágenes",
    media: "Medios",
    media_desc: "Selecciona de videos, imágenes y contenido de YouTube",
    multi_column_schedule: "Horario de varias columnas",
    multi_column_schedule_desc:
      "Selecciona los calendarios de Teamworks para mostrar en la pantalla",
    multi_day_schedule: "Horario de varios días",
    multi_day_schedule_desc:
      "Selecciona los calendarios de Teamworks para mostrar en la pantalla",
    schedule: "Horario",
    schedule_desc:
      "Selecciona los calendarios de Teamworks para mostrar en la pantalla",
    nutrition_menu: "Menú de nutrición",
    nutrition_menu_desc: "Seleccione el menú que desea mostrar",
    nutrition_dining_station: "Estación de nutrición",
    nutrition_dining_station_desc:
      "Seleccione la estación de comida que desea mostrar",
    weather: "Clima",
    weather_desc: "Mostrar el clima para múltiples ubicaciones",
    "%future added value": "Componente desconocido",
    "%future added value_desc": "Componente desconocido",
    "Configuration name": "Nombre de configuración",
    "Add event countdown": "Añadir cuenta regresiva del evento",
    "A countdown will be displayed until the event starts":
      "Se mostrará una cuenta regresiva hasta que comience el evento",
    "Not saved": "No guardado",
    "Please fix any errors and try again":
      "Por favor, corrija los errores y vuelva a intentarlo",
    "Give the configuration a name": "Dale un nombre a la configuración",
    "Try to give it a relevant name, to help you quickly find it":
      "Intenta darle un nombre relevante para ayudarte a encontrarlo rápidamente",
    "Pick a theme": "Elige un tema",
    "The theme will set colours and logo for your configuration":
      "El tema establecerá los colores y el logotipo para tu configuración",
    "Add an event (optional)": "Añadir un evento (opcional)",
    "A countdown will be displayed on the configuration until the event starts":
      "Se mostrará una cuenta regresiva en la configuración hasta que comience el evento",
    "Add a Teamworks schedule": "Añadir un horario de Teamworks",
    "Upload media": "Subir medios",
    "Add cards (optional)": "Añadir tarjetas (opcional)",
    "Add multiple Teamworks schedules": "Añadir varios horarios de Teamworks",
    "Unknown component": "Componente desconocido",
    "Events from the selected profiles will be shown on the configuration":
      "Los eventos de los perfiles seleccionados se mostrarán en la configuración",
    "You can upload images, videos or add YouTube videos":
      "Puedes subir imágenes, videos o añadir videos de YouTube",
    "The configuration will rotate through the cards one at a time":
      "La configuración rotará por las tarjetas una a la vez",
    "Each schedule will have its own column on the configuration":
      "Cada horario tendrá su propia columna en la configuración",
    "A multiday schedule will display the events from the selected profiles across multiple days":
      "Un horario de varios días mostrará los eventos de los perfiles seleccionados a lo largo de varios días",
    "Save the configuration": "Guardar la configuración",
    "Once you are happy with your updates, save the changes":
      "Una vez que estés satisfecho con tus actualizaciones, guarda los cambios",
    "Take a tour": "Haz un recorrido",
    "Remove component": "Eliminar componente",
    "Are you sure you want to remove this component?":
      "¿Está seguro de que desea eliminar este componente?",
    "Add weather": "Agregar clima",
    "A weather widget will be displayed to show current weather":
      "Se mostrará un widget del tiempo para mostrar el tiempo actual",
    "Hide past events": "Ocultar eventos pasados",
    "Announcements with matching tags will be displayed on screen":
      "Los anuncios con etiquetas coincidentes se mostrarán en la pantalla",
    "Display weather information for multiple locations across different days":
      "Mostrar información meteorológica para múltiples ubicaciones en diferentes días",
    "Grace period (minutes)": "Período de gracia (minutos)",
    "Number of minutes to keep events on screen after they have ended":
      "Número de minutos para mantener eventos en pantalla después de que hayan terminado",
  },
  AnnouncementsScreen: {
    "Announcement text is required": "Se requiere el texto del anuncio",
    "Announcements updated": "Anuncios actualizados",
    "Something went wrong": "Algo salió mal",
    "Announcements allow you to post important information to be displayed in banners across all devices.":
      "Los anuncios le permiten publicar información importante que se mostrará en pancartas en todos los dispositivos.",
    Announcements: "Anuncios",
    "Loading announcements": "Cargando anuncios",
    "Manage announcements": "Administrar anuncios",
    "Announcements are displayed on all screens, at all times":
      "Los anuncios se muestran en todas las pantallas, en todo momento.",
    "No announcements yet": "Aún no hay anuncios",
    "New announcement": "Nuevo anuncio",
    "Save announcement": "Guardar anuncio",
    "Note: announcements must be a single line. New lines will be ignored.":
      "Nota: los anuncios deben ser de una sola línea. Se ignorarán las líneas nuevas.",
    "Delete announcement": "Eliminar anuncio",
    "Are you sure you want to remove this announcement?":
      "¿Estás seguro/a de que deseas eliminar este anuncio?",
    "{{count}} Announcement": "{{count}} Anuncio",
    "{{count}} Announcement_other": "{{count}} Anuncios",
    Content: "Contenido",
    "Last updated": "Última actualización",
    "Display mode": "Modo de visualización",
    "Should the announcement be displayed on all playlists and configurations, or only on those with matching tags?":
      "¿Debe mostrarse el anuncio en todas las listas de reproducción y configuraciones, o solo en aquellas con etiquetas coincidentes?",
    "Display on all": "Mostrar en todas",
    Tagged: "Etiquetado",
    Tags: "Etiquetas",
    "Add start and end times": "Agregar hora de inicio y de finalización",
    "Set the start and end time of the announcement":
      "Establecer la hora de inicio y finalización del anuncio",
    "Starts at": "Empieza en",
    "Ends at": "Termina en",
    "End time should be after the start time":
      "La hora de finalización debe ser posterior a la hora de inicio",
    "End time cannot be in the past":
      "La hora de finalización no puede ser pasada",
  },
  CardsFormField: {
    "Add card": "Añadir tarjeta",
    "Add image": "Añadir imagen",
    "Remove image": "Eliminar imagen",
    "Remove card": "Eliminar tarjeta",
    "Are you sure you want to remove this card?":
      "¿Estás seguro de que quieres eliminar esta tarjeta?",
    "No cards added": "No se han añadido tarjetas",
    "Card title": "Título de la tarjeta",
    "Drag and drop to re-order cards":
      "Arrastrar y soltar para reordenar las tarjetas",
    "Move up": "Mover hacia arriba",
    "Move down": "Mover hacia abajo",
    Scheduled: "Programado",
    "Starts at": "Comienza a las",
    "Ends at": "Termina a las",
  },
  MediaFormField: {
    "Invalid YouTube link, please try again":
      "Enlace de YouTube no válido, por favor inténtalo de nuevo",
    "File not supported, please upload an image or video":
      "Archivo no compatible, por favor sube una imagen o un video",
    "Upload image/video": "Subir imagen/video",
    "Add YouTube": "Agregar YouTube",
    "Add YouTube video": "Agregar video de YouTube",
    "Video title": "Título del video",
    "YouTube link": "Enlace de YouTube",
    Image: "Imagen",
    Video: "Video",
    YouTube: "YouTube",
    "Something went wrong, file not uploaded":
      "Algo salió mal, archivo no subido",
    "Cancel file upload?": "¿Cancelar subida de archivo?",
    "Are you sure you want to cancel this upload?":
      "¿Estás seguro de que deseas cancelar esta subida?",
    "Upload image": "Subir imagen",
    "Upload video": "Subir video",
    "Uploading {{uploadPct}}%": "Subiendo {{uploadPct}}%",
    "Upload complete": "Subida completada",
    Name: "Nombre",
    "Number seconds image displays before moving to next media item":
      "Número de segundos que la imagen se muestra antes de pasar al siguiente elemento multimedia",
    "Duration (seconds)": "Duración (segundos)",
    "Add image": "Agregar imagen",
    "Add video": "Agregar video",
    "Delete media": "Eliminar medios",
    "Are you sure you want to delete this media?":
      "¿Estás seguro de que deseas eliminar estos medios?",
    "Update duration": "Actualizar duración",
    "Edit duration": "Editar duración",
    "Remove media": "Eliminar medios",
    "No media added": "No se ha añadido ningún medio",
  },
  MultiColumnScheduleFormField: {
    "Add column": "Agregar columna",
    "Column title": "Título de la columna",
    "Remove column": "Eliminar columna",
    "Are you sure you want to remove this column?":
      "¿Estás seguro de que deseas eliminar esta columna?",
    "Hide past events": "Ocultar eventos pasados",
    "Grace period (minutes)": "Período de gracia (minutos)",
    "Number of minutes to keep events on screen after they have ended":
      "Número de minutos para mantener eventos en pantalla después de que hayan terminado",
  },
  AuthProvider: {
    "Fetching active users": "Obteniendo usuarios activos",
    "Setting organisations": "Configurando organizaciones",
    "Logging you in": "Iniciando sesión",
  },
  AccessDeniedScreen: {
    "You don't have access to Digital Displays":
      "No tienes acceso a las Pantallas Digitales",
    "Please contact Teamworks customer support to request access":
      "Por favor, contacta al soporte al cliente de Teamworks para solicitar acceso",
    "Sign out": "Cerrar sesión",
    "Customer support": "Soporte al cliente",
    Reload: "Recargar",
  },
  DeviceGroupsTab: {
    "Delete group": "Eliminar grupo",
    "Are you sure you want to delete this group?":
      "¿Estás seguro de que deseas eliminar este grupo?",
    "Device group deleted successfully":
      "Grupo de dispositivos eliminado correctamente",
    "Something went wrong": "Algo salió mal",
    "Device group created successfully":
      "Grupo de dispositivos creado correctamente",
    "Group updated": "Grupo actualizado",
    "New group": "Nuevo grupo",
    "Device groups allow you to group your display devices under one name.":
      "Los grupos de dispositivos le permiten agrupar sus dispositivos de visualización bajo un solo nombre.",
    "Device groups": "Grupos de dispositivos",
    "{{count}} Device group": "{{count}} Grupos de dispositivos",
    "{{count}} Device group_other": "{{count}} Grupos de dispositivos",
    "Search groups...": "Buscar grupos...",
    "Group name": "Nombre del grupo",
    "Device count": "Recuento de dispositivos",
    "Fetching groups": "Obteniendo grupos",
    "No groups": "Sin grupos",
    "Group name is required": "El nombre del grupo es obligatorio.",
    "Please select at least 2 devices":
      "Por favor seleccione al menos 2 dispositivos",
    "Create group": "Crea un grupo",
    "Update group": "Grupo de actualización",
    "Fetching devices...": "Recuperando dispositivos...",
    "Select devices": "Seleccionar dispositivos",
    Devices: "Dispositivos",
    Groups: "Grupos",
    "Number devices": "Número de dispositivos",
  },
  ErrorState: {
    "Something went wrong": "Algo salió mal",
    "Please try again, or contact Teamworks support if the issue persists":
      "Por favor, inténtalo de nuevo, o contacta al soporte de Teamworks si el problema persiste",
  },
  ProductToursProvider: {
    Back: "Atrás",
    Next: "Siguiente",
    Finish: "Finalizar",
  },
  Drawer: {
    Close: "Cerrar",
  },
  InitialSearchParamsProvider: {
    "Initializing URL params": "Inicializando parámetros de URL",
  },
  PreviewsProvider: {
    "Redirecting to preview": "Redirigiendo a la vista previa",
  },
  PlaylistsScreen: {
    Playlists: "Listas de reproducción",
    "New playlist": "Nueva lista de reproducción",
    "{{count}} Playlist": "{{count}} Lista de reproducción",
    "{{count}} Playlist_other": "{{count}} Listas de reproducción",
    Name: "Nombre",
    Tags: "Etiquetas",
    "Loading playlists": "Cargando listas de reproducción",
    "No playlists have been setup yet":
      "No se han configurado listas de reproducción",
    "Playlists allow you to join multiple configurations together.":
      "Las listas de reproducción permiten unir múltiples configuraciones.",
    "No configurations selected": "No se han seleccionado configuraciones",
    "Add configuration": "Agregar configuración",
    Cancel: "Cancelar",
    "Create playlist": "Crear lista de reproducción",
    "Delete playlist": "Eliminar lista de reproducción",
    "Are you sure you want to delete this playlist?":
      "¿Estás seguro de que quieres eliminar esta lista de reproducción?",
    "Loading playlist": "Cargando lista de reproducción",
    "Save changes": "Guardar cambios",
    "Configuration already in playlist":
      "Configuración ya en la lista de reproducción",
    "The selected configuration is already part of this playlist, do you want to add it again?":
      "La configuración seleccionada ya es parte de esta lista de reproducción, ¿quieres agregarla de nuevo?",
    "Remove from playlist": "Eliminar de la lista de reproducción",
    Configuration: "Configuración",
    "Number loops": "Número de bucles",
    Active: "Activo",
    "Playlist updated": "Lista de reproducción actualizada",
    "Something went wrong": "Algo salió mal",
    "Last updated": "Última actualización",
    l_layout_loop_description:
      "Los elementos multimedia se repetirán un número determinado de veces antes de cambiar a la siguiente configuración",
    media_only_loop_description:
      "Los elementos multimedia se repetirán un número determinado de veces antes de cambiar a la siguiente configuración",
    basic_loop_description:
      "El horario se repetirá un número determinado de veces antes de cambiar a la siguiente configuración",
    multi_column_schedule_loop_description:
      "El horario se repetirá un número determinado de veces antes de cambiar a la siguiente configuración",
    multi_day_schedule_loop_description:
      "El horario se repetirá un número determinado de veces antes de cambiar a la siguiente configuración",
    schedule_only_loop_description:
      "El horario se repetirá un número determinado de veces antes de cambiar a la siguiente configuración",
    "%future added value_loop_description": "Tipo de configuración desconocido",
    mvp_loop_description: "Tipo de configuración desconocido",
    weather_loop_description: "Mostrar el clima para múltiples ubicaciones",
    nutrition_loop_description:
      "El menú o la estación de comida se repetirá un número determinado de veces antes de cambiar a la siguiente configuración",
    timed_description:
      "La configuración permanecerá en pantalla durante un tiempo determinado antes de cambiar a la siguiente configuración",
    Looped: "En bucle",
    Timed: "Temporizado",
    "Duration (minutes)": "Duración (minutos)",
    "Search playlists...": "Buscar listas de reproducción...",
    "Edit configuration": "Editar configuración",
    "New configuration": "Nueva configuración",
    "Loading configuration": "Cargando configuración",
    "Add an existing configuration to the playlist":
      "Agregar una configuración existente a la lista de reproducción",
    "Create a new configuration to add to the playlist":
      "Crear una nueva configuración para agregar a la lista de reproducción",
    "No playlists found for '{{searchText}}'":
      "No se han encontrado listas de reproducción para '{{searchText}}'",
    "Last active at {{lastActiveAt}}":
      "Última actividad a las {{lastActiveAt}}",
    "Device count": "Recuento de dispositivos",
    "Loading screen devices": "Pantalla de carga de dispositivos",
    "Announcements with matching tags will be displayed on screen":
      "Los anuncios con etiquetas coincidentes se mostrarán en la pantalla",
  },
  PlaylistPicker: {
    Playlist: "Lista de reproducción",
    "Clear playlist": "Borrar lista de reproducción",
    "No playlist selected": "No hay ninguna lista de reproducción seleccionada",
    "Searching playlists": "Buscando listas de reproducción",
    "No playlists found for '{{searchText}}'":
      "No se encontraron listas de reproducción para '{{searchText}}'",
    "No playlists found": "No se encontraron listas de reproducción",
  },
  TableSearchField: {
    "Clear search text": "Borrar texto de búsqueda",
  },
  SettingsScreen: {
    "Organisation settings": "Configuración de la organización",
    "Permission groups": "Grupos de permisos",
    "User access": "Acceso de usuario",
    "IP Addresses": "Direcciones IP",
    "Loading settings": "Cargando configuración",
  },
  PermissionGroupsTab: {
    "New permission group": "Nuevo grupo de permisos",
    "Permission groups allow you to specify which hub profiles a user has access to when setting up a schedule":
      "Los grupos de permisos te permiten especificar a qué perfiles de hub tiene acceso un usuario al configurar un horario",
    "{{count}} Permission group": "{{count}} Grupo de permisos",
    "{{count}} Permission group_other": "{{count}} Grupos de permisos",
    "Search permission groups...": "Buscar grupos de permisos...",
    Name: "Nombre",
    "Last updated": "Última actualización",
    "Loading permission groups": "Cargando grupos de permisos",
    "No permission groups yet": "Aún no hay grupos de permisos",
    "No permission groups found for '{{searchText}}'":
      "No se encontraron grupos de permisos para '{{searchText}}'",
    "Permission groups allow you set what hub profiles a user has access to":
      "Los grupos de permisos te permiten configurar a qué perfiles de hub tiene acceso un usuario",
    "{{count}} hub profile": "{{count}} perfil de buje",
    "{{count}} hub profile_other": "{{count}} perfiles de hub",
    "Permission group created": "Grupo de permisos creado",
    "Something went wrong": "Algo salió mal",
    "Loading permission group": "Cargando grupo de permisos",
    "Delete permission group": "Eliminar grupo de permisos",
    "Are you sure you want to delete this permission group?":
      "¿Estás seguro de que quieres eliminar este grupo de permisos?",
    "Permission group deleted": "Grupo de permisos eliminado",
    "Permission group updated": "Grupo de permisos actualizado",
    "Save changes": "Guardar cambios",
    "Permission group name": "Nombre del grupo de permisos",
    "Permitted hub profiles": "Perfiles de hub permitidos",
    "Only permitted profiles will appear for users to select when managing display schedules":
      "Solo los perfiles permitidos aparecerán para que los usuarios los seleccionen al gestionar horarios de visualización",
    "Hub access": "Acceso a Hub",
    "Nutrition access": "Acceso a Nutrition",
    Hub: "Hub",
    Nutrition: "Nutrition",
    "Accessible apps": "Aplicaciones accesibles",
  },
  UsersTab: {
    "Contact Teamworks support to add or remove users":
      "Contacta con el soporte de Teamworks para añadir o eliminar usuarios",
    "New user": "Nuevo usuario",
    "This is a list of users who have access to Digital Displays":
      "Esta es una lista de usuarios que tienen acceso a Pantallas Digitales",
    "{{count}} User": "{{count}} Usuario",
    "{{count}} User_other": "{{count}} Usuarios",
    "Search users...": "Buscar usuarios...",
    Name: "Nombre",
    "Last updated": "Última actualización",
    "Loading users": "Cargando usuarios",
    "Loading user": "Cargando usuario",
    "No users yet": "Aún no hay usuarios",
    "No users found for '{{searchText}}'":
      "No se encontraron usuarios para '{{searchText}}'",
    "Super admin": "Super administrador",
    "{{count}} permission group": "{{count}} grupo de permisos",
    "{{count}} permission group_other": "{{count}} grupos de permisos",
    "User updated": "Usuario actualizado",
    "Something went wrong": "Algo salió mal",
    "Save changes": "Guardar cambios",
    superadmin_description:
      "Este usuario es un superadministrador. Tiene acceso a todos los perfiles del hub, independientemente de los grupos de permisos asignados.",
    admin_description:
      "Este usuario solo tiene acceso a los perfiles del hub asignados a él a través de grupos de permisos.",
    "%future added value_description": "Rol desconocido",
  },
  ManageIpAddressesTab: {
    "Edit IP addresses": "Editar direcciones IP",
    "For systems which do not support our QR setup, these permitted IP addresses can be used to allow you access to hardcoded URLs.":
      "Para sistemas que no admiten nuestra configuración QR, estas direcciones IP permitidas pueden usarse para permitirte acceder a URLs codificadas.",
    "{{count}} Permitted IP address": "{{count}} Dirección IP permitida",
    "{{count}} Permitted IP address_other":
      "{{count}} Direcciones IP permitidas",
    "No IP addresses": "No hay direcciones IP",
    "You have not added any permitted IP addresses yet":
      "Aún no has agregado ninguna dirección IP permitida",
    "Permitted IP addresses updated": "Direcciones IP permitidas actualizadas",
    "Something went wrong": "Algo salió mal",
    "Save changes": "Guardar cambios",
    "No permitted IPs yet": "Aún no hay IPs permitidas",
    "Add another": "Añadir otra",
  },
  PermissionGroupPicker: {
    "Add permission groups": "Agregar grupos de permisos",
    "No permission groups added": "No se agregaron grupos de permisos",
    "Show {{count}} more": "Mostrar {{count}} más",
    "Clear search text": "Borrar texto de búsqueda",
    "Search permission groups...": "Buscar grupos de permisos...",
    "Loading permission groups": "Cargando grupos de permisos",
    "{{count}} hub profile": "{{count}} Perfil de buje",
    "{{count}} hub profile_other": "{{count}} perfiles de hub",
    "Remove permission group": "Eliminar grupo de permisos",
    "No users found": "No se encontraron usuarios",
    "We couldn't find any users matching '{{searchText}}'":
      "No pudimos encontrar ningún usuario que coincida con '{{searchText}}'",
    "No permission groups found": "No se encontraron grupos de permisos",
    "Confirm selection": "Confirmar selección",
  },
  TableProvider: {
    "{{count}} Selected": "{{count}} Seleccionado",
  },
  ComponentPicker: {
    "Pick a component": "Seleccione un componente",
    media: "Medios",
    schedule: "Horario",
    cards: "Tarjetas",
    multi_day_schedule: "Horario de varios días",
    multi_column_schedule: "Horario de varias columnas",
    nutrition_dining_station: "Estación de visualización de nutrición",
    nutrition_menu: "Menú de nutrición",
    weather: "Clima",
    "%future added value": "Componente desconocido",
    Add: "Añadir",
  },
  TagPicker: {
    "Add tags": "Añadir etiquetas",
    Clear: "Limpiar",
    "Searching tags": "Buscando etiquetas",
    "No tags selected": "No se seleccionaron etiquetas",
    "Remove tag": "Eliminar etiqueta",
    "No tags found matching '{{searchText}}'":
      "No se encontraron etiquetas que coincidan con '{{searchText}}'",
    "Something went wrong": "Algo salió mal",
    "Create tag '{{tagName}}'": "Crear etiqueta '{{tagName}}'",
    "Start typing to create a new tag":
      "Empiece a escribir para crear una nueva etiqueta",
    "Delete tag": "Eliminar etiqueta",
    "Are you sure you want to delete the tag '{{tagName}}'?":
      "¿Está seguro de que desea eliminar la etiqueta '{{tagName}}'?",
    "Tag deleted": "Etiqueta eliminada",
  },
  DeviceScheduleScreen: {
    Devices: "Dispositivos",
    "Device schedule": "Horario del dispositivo",
    "Schedule item created": "Elemento del horario creado",
    "Something went wrong": "Algo salió mal",
    "Please correct any errors and try again":
      "Corrija los errores y vuelva a intentarlo",
    "Create schedule item": "Crear elemento de horario",
    "Schedule item updated": "Elemento del horario actualizado",
    "Update schedule item": "Actualizar elemento de horario",
    "End time should be after the start time":
      "La hora de finalización debe ser después de la hora de inicio",
    "Schedule item cannot be in the past":
      "El elemento de horario no puede estar en el pasado",
    "Starts at": "Comienza a las",
    "Ends at": "Termina a las",
    "Add content": "Añadir contenido",
    "Loading schedule": "Cargando horario",
    "Delete schedule item": "Eliminar elemento del horario",
    "Are you sure you want to delete this schedule item?":
      "¿Está seguro de que desea eliminar este elemento del horario?",
    "Schedule item deleted": "Elemento del horario eliminado",
    "Saving this event will shorten or replace {{count}} item. Are you sure you want to continue?":
      "Guardar este evento acortará o reemplazará {{count}} elemento. ¿Está seguro de que desea continuar?",
    "Saving this event will shorten or replace {{count}} item. Are you sure you want to continue?_other":
      "Guardar este evento acortará o reemplazará {{count}} elementos. ¿Está seguro de que desea continuar?",
    "Conflicts found": "Conflictos encontrados",
  },
  Calendar: {
    Previous: "Anterior",
    Today: "Hoy",
    Next: "Siguiente",
    View: "Vista",
    month: "Mes",
    week: "Semana",
    work_week: "Semana laboral",
    day: "Día",
    agenda: "Agenda",
  },
  ScheduleConflictChecker: {
    "Checking for conflicts": "Comprobando conflictos",
    "No conflicts found": "No se encontraron conflictos",
    "{{count}} conflict found": "{{count}} conflicto encontrado",
    "{{count}} conflict found_other": "{{count}} conflictos encontrados",
    "Conflicts found": "Conflictos encontrados",
  },
  LocationPicker: {
    "Search locations": "Buscar ubicaciones",
    "Clear search text": "Borrar texto de búsqueda",
    "Searching locations": "Buscando ubicaciones",
    "No results": "Sin resultados",
  },
  NutritionMenuField: {
    "Please select a menu": "Por favor, seleccione un menú",
    "Include images": "Incluir imágenes",
    "Include descriptions": "Incluir descripciones",
    "Include macros": "Incluir macronutrientes",
    "Unknown menu": "Menú desconocido",
    "Please select a dining station":
      "Por favor, seleccione una estación de comida",
    "Unknown dining station": "Estación de comida desconocida",
  },
  NutritionMenuPicker: {
    "Unknown menu date": "Fecha de menú desconocida",
    "Must be later than the 'after' time":
      "Debe ser después de la hora 'después'",
    "Menu date": "Fecha de menú",
    "Search menus": "Buscar menús",
    "Nutrition menu": "Menú de nutrición",
    "Remove menu": "Eliminar menú",
    "Searching menus": "Buscando menús",
    "{{count}} menu found": "{{count}} menú encontrado",
    "{{count}} menu found_other": "{{count}} menús encontrados",
    "No menus found": "No se encontraron menús",
    "Try changing the date range and search again":
      "Intente cambiar el rango de fechas y busque de nuevo",
    "Dining station": "Estación de comida",
    "Loading stations": "Cargando estaciones",
    "Failed to fetch menus": "No se pudieron obtener los menús",
    "Something went wrong, please try again":
      "Algo salió mal, por favor intente de nuevo",
    "Failed to fetch menu dining stations":
      "No se pudieron obtener las estaciones de comida del menú",
  },
  WeatherFormField: {
    "Number of days ahead to fetch weather. Max 7":
      "Número de días para obtener el clima. Máximo 7",
    "Number of days": "Número de días",
    "Add location": "Agregar ubicación",
    "Remove location": "Eliminar ubicación",
    "Are you sure you want to remove this location?":
      "¿Estás seguro de que quieres eliminar esta ubicación?",
    Date: "Fecha",
    "Default location": "Ubicación predeterminada",
    Location: "Ubicación",
    "Below, you can add different locations for certain dates, which will override the default location for those days.":
      "A continuación, puede agregar diferentes ubicaciones para ciertas fechas, lo que anulará la ubicación predeterminada para esos días.",
    "This is the default location for displaying weather, unless overridden by another location on a given date.":
      "Esta es la ubicación predeterminada para mostrar el clima, a menos que sea reemplazada por otra ubicación en una fecha determinada.",
  },
  ScreenConfigFrom: {
    "Dates must be unique": "Dates must be unique",
    "Card text must be at least 4 characters long":
      "Card text must be at least 4 characters long",
  },
};
