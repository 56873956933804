import React, { Suspense, useEffect, useState } from "react";
import { useCurrentUser } from "../providers/CurrentProvider";
import { Alert, Popover } from "@mui/material";
import { AppText } from "./Typography";
import { Column, Row } from "./Flex";
import { useTranslation } from "react-i18next";
import { AccessibleWebApp as GeneratedAccessibleWebApp } from "../providers/__generated__/CurrentProviderQuery.graphql";
import {
  borderRadius,
  colors,
  fontSize,
  iconSize,
  spacing,
} from "../helpers/theme";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { CheckRounded } from "@mui/icons-material";
import { LoadingSpinner } from "./LoadingSpinner";
import { graphql, useLazyLoadQuery } from "react-relay";
import { AppSwitcherQuery } from "./__generated__/AppSwitcherQuery.graphql";
import { useScreenSize } from "../providers/ScreenSizeProvider";
import { AppIcon } from "./TeamworksAppIcons";
import { LocalStorage } from "../lib/LocalStorage";

type AccessibleWebApp = Exclude<
  GeneratedAccessibleWebApp,
  "%future added value"
>;

const useStyles = makeStyles({
  listWrapper: {
    minWidth: 288,
    padding: spacing.smaller,
  },
  appRow: {
    padding: spacing.smallest,
    background: colors.white,
    gap: spacing.smallest,
    borderRadius: borderRadius.small,
    alignItems: "center",
    "&:hover": {
      background: colors.gray2,
      cursor: "pointer",
    },
  },
  appRowActive: {
    "&:hover": {
      cursor: "default",
    },
  },
  appIcon: {
    width: iconSize.medium,
    height: iconSize.medium,
  },
  appText: {
    fontSize: fontSize.small,
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
});

const appOrder: AccessibleWebApp[] = [
  "hub",
  "trac",
  "tv",
  "arms",
  "inflcr",
  "gm",
  "ams",
  "nutrition",
  "strength",
  "academics",
  "pathways",
  "pulse",
  "wallet",
  "communities",
];

export const AppSwitcher = (props: {
  anchorEl?: Element;
  onClose: () => void;
}) => {
  const { accessibleWebApps } = useCurrentUser();
  const screenSize = useScreenSize();
  const { t } = useTranslation("AppSwitcher");
  const classes = useStyles();
  const [state, setState] = useState<
    { tag: "idle" } | { tag: "redirecting"; app: AccessibleWebApp }
  >({ tag: "idle" });

  const impersonating = LocalStorage.get("impersonating") ?? false;

  return (
    <Popover
      transformOrigin={{ horizontal: "left", vertical: "bottom" }}
      anchorOrigin={{
        horizontal: "right",
        vertical: screenSize === "sm" ? "top" : "bottom",
      }}
      anchorEl={props.anchorEl}
      open={props.anchorEl != null}
      onClose={props.onClose}
    >
      {state.tag === "redirecting" && (
        <Suspense>
          <HandleRedirect
            app={state.app}
            onComplete={() => {
              setState({ tag: "idle" });
              props.onClose();
            }}
          />
        </Suspense>
      )}

      {impersonating ? (
        <Alert severity="warning">
          App switching is disabled while impersonating
        </Alert>
      ) : (
        <Column className={classes.listWrapper}>
          {appOrder.map((app) =>
            accessibleWebApps.includes(app) ? (
              <Row
                key={app}
                className={classNames(
                  classes.appRow,
                  app === "tv" ? classes.appRowActive : undefined,
                )}
                onClick={() => {
                  if (app !== "tv" && state.tag === "idle") {
                    setState({ tag: "redirecting", app });
                  }
                }}
              >
                <AppIcon app={app} className={classes.appIcon} />
                <Column grow={1}>
                  <AppText className={classes.appText}>{t(app)}</AppText>
                </Column>
                {app === "tv" && <CheckRounded />}
                {state.tag === "redirecting" && state.app === app && (
                  <LoadingSpinner size="medium" />
                )}
              </Row>
            ) : null,
          )}
        </Column>
      )}
    </Popover>
  );
};

const HandleRedirect = ({
  app,
  onComplete,
}: {
  app: AccessibleWebApp;
  onComplete: () => void;
}) => {
  const data = useLazyLoadQuery<AppSwitcherQuery>(
    graphql`
      query AppSwitcherQuery($app: AccessibleWebApp!) {
        currentUser {
          webAppRedirectUri(app: $app)
        }
      }
    `,
    {
      app,
    },
  );

  useEffect(() => {
    if (data.currentUser.webAppRedirectUri == null) {
      throw new Error("Failed to return webapp redirect uri");
    }

    // is PWA
    if (window.matchMedia("(display-mode: standalone)").matches) {
      onComplete();
      window.open(data.currentUser.webAppRedirectUri);
    } else {
      window.location.href = data.currentUser.webAppRedirectUri;
    }
  }, [data, onComplete]);

  return null;
};
