import React from "react";
import { Row } from "../components/Flex";
import { AppText, Heading, HelperText } from "../components/Typography";
import { iconSize } from "../helpers/theme";
import { useTranslation } from "react-i18next";
import { Button } from "../components/Button";
import { Spacer } from "../components/Spacer";
import { TeamworksWordmark } from "../assets/TeamworksLogo";
import { config } from "../config";
import { ErrorLayout } from "../components/ErrorLayout";
import { Refresh, SupportAgent } from "@mui/icons-material";
import appJson from "../assets/app.json";

export const ErrorScreen = () => {
  const { t } = useTranslation("ErrorScreen");

  return (
    <ErrorLayout>
      <Spacer size="gigantic" />
      <Row>
        <TeamworksWordmark style={{ height: iconSize.medium }} />
      </Row>
      <Spacer size="medium" />
      <Heading>{t("Something went wrong")}</Heading>
      <Spacer size="medium" />
      <AppText>
        {t(
          "Sorry for the inconvenience, our engineers have been notified and will be working on a fix",
        )}
      </AppText>
      <HelperText>
        {t("If this issue persists, please contact Teamworks customer support")}
      </HelperText>
      <Spacer size="large" />
      <Row>
        <Button startIcon={Refresh} onClick={() => window.location.reload()}>
          {t("Reload")}
        </Button>
      </Row>
      <Spacer size="gigantic" />
      <Row alignItems="flex-end" justifyContent="space-between" gap="small">
        <Button
          variant="secondary"
          startIcon={SupportAgent}
          onClick={() => window.open(config.customerSupportLink, "_blank")}
        >
          {t("Customer support")}
        </Button>
        <HelperText>v{appJson.version}</HelperText>
      </Row>
    </ErrorLayout>
  );
};
