import { useCallback } from "react";
import { Region, validRegions } from "../helpers/types";
import { LocalStorage } from "../lib/LocalStorage";
import { defer } from "lodash";
import { useOktaAuth } from "@okta/okta-react";
import { analytics } from "../helpers/analytics";

type LoginWithTokenArgs = {
  secureToken: string;
  orgId: string;
  region: Region;
  afterLoginPath?: string;
};

export const useAuthHelpers = () => {
  const { oktaAuth, authState: oktaAuthState } = useOktaAuth();

  const loginWithToken = useCallback(
    ({ secureToken, orgId, region, afterLoginPath }: LoginWithTokenArgs) => {
      if (!validRegions.includes(region)) {
        throw new Error(`Invalid region provided: ${region}`);
      }

      LocalStorage.set("secureToken", secureToken);
      LocalStorage.set("currentOrgId", orgId.toString());
      LocalStorage.set("currentRegion", region);
      LocalStorage.set("impersonating", false);

      defer(() => {
        // we are using the DD auth now, so we can sign out of okta
        // window will redirect automatically to "/login" path
        if (oktaAuthState?.isAuthenticated) {
          oktaAuth.signOut();
        } else if (afterLoginPath != null) {
          window.location.replace(afterLoginPath);
        } else {
          window.location.reload();
        }
      });
    },
    [oktaAuth, oktaAuthState?.isAuthenticated],
  );

  const handleLogout = () => {
    LocalStorage.clear();
    analytics.reset();

    if (oktaAuthState?.isAuthenticated) {
      oktaAuth.signOut();
    } else {
      defer(() => window.location.reload());
    }
  };

  return {
    loginWithToken,
    handleLogout,
  };
};
