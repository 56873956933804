import { type Localisation } from "./en";

export const french: Localisation = {
  dateFormats: {
    datePickerLocale: "fr",
    timeFormat: "HH:mm", // 14:30
    dayFormat: "do", // 12th
    dayMonthFormat: "do MMMM", // 1st April
    dayMonthYearFormat: "do MMMM yyyy", // 11th January 1988
    dayDateMonthYearFormat: "E, do MMMM yyyy", // Mon, 1st April 2024
    dayMonthYearTimeFormat: "do MMMM yyyy, HH:mm", // 11th January 1988, 14:30
  },
  App: {
    "Digital Displays": "Affichages Numériques",
    Loading: "Chargement",
    "Checking authentication": "Vérification de l'authentification",
    "Redirecting to login": "Redirection vers la connexion",
    "Sign in with Teamworks": "Se connecter avec Teamworks",
    Initializing: "Initialisation",
    "Verifing authentication": "Vérification de l'authentification",
    Redirecting: "Redirection",
    "Welcome back!": "Bon retour!",
    "Login to get started": "Connectez-vous pour commencer",
  },
  AppSwitcher: {
    arms: "Compliance + Recruiting",
    hub: "Hub",
    inflcr: "Influencer",
    academics: "Academics",
    communities: "Communities",
    pulse: "Pulse",
    pathways: "Pathways",
    nutrition: "Nutrition",
    ams: "AMS",
    tv: "Digital Displays",
    trac: "Inventory Management",
    strength: "Strength + Conditioning",
    wallet: "Wallet",
    gm: "General Manager",
  },
  Layout: {
    "Checking authentication": "Vérification de l'authentification",
    Dashboard: "Tableau de bord",
    Configurations: "Configurations",
    Devices: "Dispositifs",
    Themes: "Thèmes",
    Settings: "Paramètres",
    Announcements: "Announcements",
    "Organisation settings": "Paramètres de l'organisation",
    "Teamworks Help": "Aide de Teamworks",
    "Teamworks Apps": "Applications de Teamworks",
    "Privacy policy": "Politique de confidentialité",
    Logout: "Déconnexion",
    "Loading, please wait...": "Chargement, veuillez patienter...",
    Users: "Utilisateurs",
    "Switch organisation": "Changer d'organisation",
    "Signing you out": "Déconnexion en cours",
    "Open Menu": "Ouvrir le menu",
    "Close Menu": "Fermer le menu",
    Playlists: "Listes de lecture",
  },
  ConfigsScreen: {
    "{{count}} Configuration": "{{count}} Configuration",
    "{{count}} Configuration_other": "{{count}} Configurations",
    "New configuration": "Nouvelle configuration",
    "Configurations allow you to customise the look and content of your displays.":
      "Les configurations vous permettent de personnaliser l'apparence et le contenu de vos affichages.",
    "Loading configurations": "Chargement des configurations",
    Configurations: "Configurations",
    Name: "Nom",
    Tags: "Tags",
    Template: "Modèle",
    Theme: "Thème",
    "Device count": "Nombre d'appareils",
    "Created at": "Créé à",
    "No configurations have been setup yet":
      "Aucune configuration n'a été configurée pour le moment",
    "No configurations found for '{{searchText}}'":
      "Aucune configuration trouvée pour '{{searchText}}'",
    "Displays allow you to cast scheduled events and media to any device with an internet browser":
      "Les affichages vous permettent de diffuser des événements programmés et des médias sur n'importe quel appareil avec un navigateur internet",
    "Create one now": "Créez-en un maintenant",
    "Create configuration": "Créer une configuration",
    "Add custom videos, YouTube videos and images":
      "Ajouter des vidéos personnalisées, des vidéos YouTube et des images",
    "Filter calendar to present a live schedule":
      "Filtrer le calendrier pour présenter un emploi du temps en direct",
    "Display weather for multiple locations":
      "Afficher la météo pour plusieurs emplacements",
    "A general overview for up to a week in advance":
      "Un aperçu général jusqu'à une semaine à l'avance",
    "Show temperature, wind and probability of precipitation":
      "Afficher la température, le vent et la probabilité de précipitations",
    "Cycle through important updates on cards":
      "Parcourir les mises à jour importantes sur les cartes",
    "Includes team crest, time and date":
      "Inclut l'écusson de l'équipe, l'heure et la date",
    "Display a schedule over multiple days":
      "Afficher un emploi du temps sur plusieurs jours",
    "Display daily schedule for different teams and groups.":
      "Afficher l'emploi du temps quotidien pour différentes équipes et groupes.",
    "Configuration deleted": "Configuration supprimée",
    "Are you sure you want to delete this configuration?":
      "Êtes-vous sûr de vouloir supprimer cette configuration?",
    "Delete configuration": "Supprimer la configuration",
    "Fetching configuration": "Récupération de la configuration",
    "Something went wrong": "Quelque chose s'est mal passé",
    "Edit configuration": "Modifier la configuration",
    "Includes background image from theme": "Inclut l'image de fond du thème",
    "Last active at {{lastActiveAt}}": "Dernier actif à {{lastActiveAt}}",
    "Loading screen devices": "Chargement des appareils à écran",
    "Search configurations...": "Rechercher des configurations...",
    "Save changes": "Enregistrer les modifications",
    "Last updated": "Dernière mise à jour",
    "Clear template": "Effacer le modèle",
    "No template selected": "Aucun modèle sélectionné",
    "Display a full menu or an individual station":
      "Afficher un menu complet ou une station individuelle",
    "Optionally show images, descriptions and macros":
      "Afficher éventuellement des images, des descriptions et des macros",
  },
  DashboardScreen: { Dashboard: "Tableau de bord" },
  DevicesScreen: {
    Devices: "Dispositifs",
    Groups: "Groupes",
    "{{count}} Device": "{{count}} Appareil",
    "{{count}} Device_other": "{{count}} Appareils",
    "New device": "Nouvel appareil",
    "Devices are the physical displays you have linked to your account.":
      "Les appareils sont les écrans physiques que vous avez liés à votre compte.",
    "No devices have been setup yet": "Aucun appareil n'a encore été configuré",
    "No devices found for '{{searchText}}'":
      "Aucun appareil trouvé pour '{{searchText}}'",
    "Device name": "Nom de l'appareil",
    Facility: "l' aptitude",
    Configuration: "Configuration",
    "Facility not set": "Installation non définie",
    "Config not set": "Configuration non définie",
    "Create device": "Créer un appareil",
    "Update device": "Mettre à jour l'appareil",
    "Screen device updated": "Appareil à écran mis à jour",
    "Something went wrong": "Quelque chose s'est mal passé",
    "Adding new display device": "Ajout d'un nouveau périphérique d'affichage",
    addNewDeviceDescription:
      "Pour ajouter un nouvel appareil, rendez-vous sur <0>{{url}}</0> sur l'appareil que vous souhaitez lier.",
    "You will be shown a QR code which can be scanned by your camera.":
      "Un code QR vous sera présenté, que vous pourrez scanner avec votre appareil photo.",
    "Start scanning": "Commencer à scanner",
    Ok: "Le bien",
    "Are you sure you want to delete this device?":
      "Êtes-vous sûr de vouloir supprimer cet appareil ?",
    "Delete device": "Supprimer l'appareil",
    "Fetching devices": "Récupération des appareils",
    Timezone: "Fuseau horaire",
    "Display name is required": "Le nom d'affichage est requis",
    "Display name must be at least 3 characters long":
      "Le nom d'affichage doit comporter au moins 3 caractères",
    "Device setup successful": "Configuration de l'appareil réussie",
    "Switch organisation": "Changer d'organisation",
    "Search devices...": "Rechercher des appareils...",
    "Device not found": "Appareil non trouvé",
    "It may have been deleted, or you do not have permission to view it.":
      "Il se peut qu'il ait été supprimé ou que vous n'ayez pas la permission de le voir.",
    "Loading device": "Chargement de l'appareil",
    "Last updated": "Dernière mise à jour",
    "Playlist not set": "Liste de lecture non définie",
    "Bulk actions": "Actions groupées",
    "Edit selected": "Modifier la sélection",
    "Delete selected": "Supprimer la sélection",
    "Delete {{count}} devices": "Supprimer {{count}} appareils",
    "Are you sure you want to delete these devices?":
      "Etes-vous sûr de vouloir supprimer ces appareils ?",
    "Devices deleted": "Appareils supprimés",
    "Screen devices updated": "Périphériques d'écran mis à jour",
    "Update {{count}} devices": "Mettre à jour {{count}} appareils",
    "Device deleted": "Appareil supprimé",
    "Select all": "Sélectionner tout",
    "Deselect all": "Désélectionner tout",
    "Create virtual device": "Créer un appareil virtuel",
    "Virtual device URL": "URL de l'appareil virtuel",
    "This URL can be used to integrate with systems that do not support our QR code setup.":
      "Cette URL peut être utilisée pour s'intégrer à des systèmes qui ne prennent pas en charge notre configuration de code QR.",
    "Note: This URL will only be accessable from networks with an approved IP address.":
      "Remarque : Cette URL ne sera accessible qu'à partir de réseaux avec une adresse IP approuvée.",
    or: "ou",
    "Provides a hardcoded URL, for use with systems which do not support the QR setup":
      "Fournit une URL codée en dur, à utiliser avec les systèmes qui ne prennent pas en charge la configuration QR",
    "Manage schedule": "Gérer le programme",
    "Please correct any errors and try again":
      "Veuillez corriger les erreurs et réessayer",
    "Skip interaction screen": "Passer l'écran d'interaction",
    skip_interaction_description:
      "L'écran d'interaction affiche un bouton qui doit être pressé avant d'afficher tout contenu. Cela permet d'éviter les problèmes de lecture automatique sur les navigateurs modernes, mais peut ne pas être nécessaire pour certains systèmes.",
  },
  DeviceModePicker: {
    "Device mode": "Mode de l'appareil",
    "Should this device display a configuration or a playlist?":
      "Cet appareil doit-il afficher une configuration ou une playlist ?",
    "Should this device display a configuration or a playlist?_other":
      "Ces appareils doivent-ils afficher une configuration ou une playlist ?",
    config: "Configuration",
    playlist: "Playlist",
  },
  NotFoundScreen: {
    "Oops! You seem to be lost.": "Oops! Vous semblez perdu.",
    "Return to dashboard": "Retour au tableau de bord",
  },
  ThemeScreen: {
    "New theme": "Nouveau thème",
    "Themes allow you to customise the colours of your configurations.":
      "Les thèmes vous permettent de personnaliser les couleurs de vos configurations.",
    Themes: "Thèmes",
    "You don't have any themes yet": "Vous n'avez pas encore de thèmes",
    "Themes allow you to customise the colours used by your templates":
      "Les thèmes vous permettent de personnaliser les couleurs utilisées par vos modèles",
    "Create one now": "Créez-en un maintenant",
    "Theme name": "Nom du thème",
    "Last updated": "Dernière mise à jour",
    "Create theme": "Créer un thème",
    "Update theme": "Mettre à jour le thème",
    background: "Arrière-plan",
    backgroundText: "Texte de fond",
    accent: "L' accent",
    accentText: "Texte accentué",
    announcementBar: "Barre d'annonce",
    announcementBarText: "Texte de la barre d'annonce",
    "Invalid hex code": "Code hexadécimal invalide",
    "New theme created": "Nouveau thème créé",
    "Theme updated": "Thème mis à jour",
    "Screen theme deleted": "Thème d'écran supprimé",
    "Something went wrong": "Quelque chose s'est mal passé",
    "Are you sure you want to delete this theme?":
      "Êtes-vous sûr de vouloir supprimer ce thème ?",
    "Delete theme": "Supprimer le thème",
    "Loading themes": "Chargement des thèmes",
    "Upload logo": "Télécharger un logo",
    "Remove logo": "Supprimer le logo",
    "Upload background image": "Télécharger l'image d'arrière-plan",
    "Remove image": "Supprimer l'image",
    "Theme not found": "Thème non trouvé",
    "It may have been deleted, or you do not have permission to view it":
      "Il se peut qu'il ait été supprimé ou que vous n'ayez pas la permission de le voir",
    "Loading theme": "Chargement du thème",
    "Search themes...": "Rechercher des thèmes...",
    "{{count}} Theme": "{{count}} Thème",
    "{{count}} Theme_other": "{{count}} Thèmes",
    "What's a theme?": "Qu'est-ce qu'un thème ?",
    "Themes allow you to customise the appearance of your configurations":
      "Les thèmes vous permettent de personnaliser l'apparence de vos configurations",
    "Clicking this button will begin the process of creating a new one.":
      "Cliquer sur ce bouton commencera le processus de création d'un nouveau thème.",
    "Give your theme a memorable name": "Donnez à votre thème un nom mémorable",
    "This will help you find it later when assigning it to a configuration.":
      "Cela vous aidera à le retrouver plus tard lors de l'affectation à une configuration.",
    "Upload a logo (optional)": "Télécharger un logo (optionnel)",
    "If you do not upload a logo, a Teamworks logo will appear in its place.":
      "Si vous ne téléchargez pas de logo, un logo Teamworks apparaîtra à sa place.",
    "Upload a background image (optional)":
      "Télécharger une image de fond (optionnel)",
    "Some configurations make use of a background image. If an image is not uploaded, the background colour selected below will be used.":
      "Certaines configurations utilisent une image de fond. Si aucune image n'est téléchargée, la couleur de fond sélectionnée ci-dessous sera utilisée.",
    "Customise the colours you want to use":
      "Personnalisez les couleurs que vous souhaitez utiliser",
    "The 'text' options should be a contrasting colour, to ensure it can be easily read.":
      "Les options de 'texte' doivent être d'une couleur contrastante, pour s'assurer qu'elles soient facilement lisibles.",
    "Once you are ready, save the theme by clicking this button":
      "Une fois prêt, enregistrez le thème en cliquant sur ce bouton",
    "Your theme will then be available to be selected when managing a configuration.":
      "Votre thème sera alors disponible pour être sélectionné lors de la gestion d'une configuration.",
    "Theme contrast": "Theme contrast",
    "Some content appears in containers that are semi transparent and overlayed on your chosen background colour.":
      "Certains contenus apparaissent dans des conteneurs semi-transparents et superposés sur la couleur d'arrière-plan de votre choix.",
    "Would you like to lighten or darken your background?":
      "Souhaitez-vous éclaircir ou assombrir votre arrière-plan ?",
    Dark: "Sombre",
    Light: "Lumière",
    "Theme preview": "Aperçu du thème",
    Close: "Fermer",
    Preview: "Aperçu",
    "No themes found for '{{searchText}}'":
      "Aucun thème trouvé pour '{{searchText}}'",
    or: "ou",
    "Use organisation logo": "Utiliser le logo de l'organisation",
  },
  ScreenTemplateNames: {
    "L shape with media": "Forme en L avec média",
    "Media only": "Médias uniquement",
    "Schedule only": "Horaire seulement",
    "Multi day schedule": "Horaire sur plusieurs jours",
    "Multi column schedule": "Calendrier multi-colonnes",
    Basic: "Basique",
    Nutrition: "Nutrition",
    Weather: "Météo",
  },
  DialogProvider: {
    Cancel: "Annuler",
    Confirm: "Confirmer",
    Close: "Fermer",
    Ok: "Ok",
    "Close without saving?": "Fermer sans enregistrer ?",
    "Close without saving": "Fermer sans enregistrer",
    "Any unsaved changes will be lost":
      "Toutes les modifications non enregistrées seront perdues",
    "Back to form": "Retour au formulaire",
  },
  ConfigurationPicker: {
    Configuration: "Configuration",
    "No configurations found for '{{searchText}}'":
      "Aucune configuration trouvée pour '{{searchText}}'",
    "No configurations found": "Aucune configuration trouvée",
    "No configuration selected": "Aucune configuration sélectionnée",
    "Clear configuration": "Effacer la configuration",
    "Searching configurations": "Recherche de configurations",
  },
  ErrorScreen: {
    "Something went wrong": "Quelque chose s'est mal passé",
    "Sorry for the inconvenience, our engineers have been notified and will be working on a fix":
      "Désolé pour le dérangement, nos ingénieurs ont été informés et travailleront sur une solution",
    "If this issue persists, please contact Teamworks customer support":
      "Si ce problème persiste, veuillez contacter le support client de Teamworks",
    Reload: "Recharger",
    "Customer support": "Support client",
  },
  ThemePicker: {
    Theme: "Thème",
    "Clear theme": "Effacer le thème",
    "Screen theme": "Thème de l'écran",
    "No theme selected": "Aucun thème sélectionné",
    "No themes found matching '{{searchText}}'":
      "Aucun thème trouvé correspondant à '{{searchText}}'",
    "No themes found": "Aucun thème trouvé",
    "Loading themes...": "Chargement des thèmes...",
  },
  MarkdownField: {
    Preview: "Aperçu",
    Remove: "Retirer",
    "Nothing to preview": "Rien à prévisualiser",
    Write: "Écrire",
  },
  OrganisationSwitcher: {
    "Fetching organisations": "Récupération des organisations",
    "Select an organisation": "Sélectionner une organisation",
    "Switching profile": "Changement de profil",
  },
  TimeZonePicker: {
    Timezone: "Fuseau horaire",
  },
  MatchesDrawer: {
    "Add match": "Ajouter un match",
    "Fetching event": "Récupération de l'événement",
    "Event title": "Titre de l'événement",
    "Starts at": "Débute à",
    "Create event": "Créer un événement",
  },
  FileUploadField: {
    "Upload file": "Télécharger un fichier",
    "Remove file": "Supprimer le fichier",
  },
  HubProfilePicker: {
    Search: "Recherche",
    "Clear search text": "Effacer le texte de recherche",
    "Search text too short": "Texte de recherche trop court",
    "Please add at least 3 characters to begin a search":
      "Veuillez ajouter au moins 3 caractères pour commencer une recherche",
    "No calendars found": "Aucun calendrier trouvé",
    "We couldn't find any calendars matching '{{searchText}}'":
      "Nous n'avons trouvé aucun calendrier correspondant à '{{searchText}}'",
    "Search...": "Recherche...",
    "Confirm selection": "Confirmer la sélection",
    Close: "Fermer",
    "Add Teamworks calendars": "Ajouter des calendriers Teamworks",
    "Loading profiles": "Chargement des profils",
    "Search text must have at least 3 characters":
      "Le texte de recherche doit comporter au moins 3 caractères",
    "No results found": "Aucun résultat trouvé",
    "Calendar details": "Détails du calendrier",
    "Remove calendar": "Supprimer le calendrier",
    "Are you sure you want to remove this calendar?":
      "Êtes-vous sûr de vouloir supprimer ce calendrier ?",
    "No calendars added": "Aucun calendrier ajouté",
    "Unknown profile": "Profil inconnu",
    "You don't have access to this hub profile":
      "Vous n'avez pas accès à ce profil de hub",
    "Your administrator may need to review your permission groups.":
      "Votre administrateur pourrait devoir vérifier vos groupes d'autorisations.",
    "Show {{count}} more": "Afficher {{count}} de plus",
  },
  ScreenEventsPicker: {
    "Loading events": "Chargement des événements",
    "Event countdown": "Compte à rebours de l'événement",
    "Create event": "Créer un événement",
    "Update event": "Mettre à jour l'événement",
    "Event title": "Titre de l'événement",
    "Starts at": "Débute à",
    "Add opponent badge": "Ajouter un badge d'adversaire",
    "Remove opponent badge": "Supprimer le badge d'adversaire",
    "Delete event": "Supprimer l'événement",
    "Are you sure you want to delete this event?":
      "Êtes-vous sûr de vouloir supprimer cet événement ?",
    "Delete now": "Supprimer maintenant",
    "Opponent badge position": "Position de l'insigne de l'adversaire",
    "Should the opponent badge be on the left or right?":
      "L'insigne de l'adversaire doit-il être à gauche ou à droite?",
    "i.e are you playing home or away?":
      "c'est-à-dire, jouez-vous à domicile ou à l'extérieur?",
    "Edit event": "Éditer l'événement",
  },
  ScreenConfigDrawer: {
    "Loading configuration": "Chargement de la configuration",
    "Number of days the schedule should span. Max 5":
      "Nombre de jours que l'horaire devrait couvrir. Max 5",
    "Number of days": "Nombre de jours",
    "Delete configuration": "Supprimer la configuration",
    "Are you sure you want to delete this configuration?":
      "Êtes-vous sûr de vouloir supprimer cette configuration ?",
    "Configuration deleted": "Configuration supprimée",
    "Something went wrong": "Quelque chose s'est mal passé",
    "Save changes": "Enregistrer les modifications",
    "Configuration updated": "Configuration mise à jour",
    cards: "Cartes",
    cards_desc: "Sélectionnez des cartes pour ajouter du texte ou des images",
    media: "Médias",
    media_desc:
      "Sélectionnez parmi des vidéos, des images et du contenu YouTube",
    multi_column_schedule: "Horaire à colonnes multiples",
    multi_column_schedule_desc:
      "Sélectionnez les calendriers Teamworks à afficher à l'écran",
    multi_day_schedule: "Horaire de plusieurs jours",
    multi_day_schedule_desc:
      "Sélectionnez les calendriers Teamworks à afficher à l'écran",
    schedule: "Horaire",
    nutrition_menu: "Menu de nutrition",
    nutrition_menu_desc: "Sélectionnez le menu que vous souhaitez afficher",
    nutrition_dining_station: "Station de nutrition",
    nutrition_dining_station_desc:
      "Sélectionnez la station de restauration que vous souhaitez afficher",
    weather: "Météo",
    weather_desc: "Afficher la météo pour plusieurs emplacements",
    schedule_desc:
      "Sélectionnez les calendriers Teamworks à afficher à l'écran",
    "%future added value": "Composant inconnu",
    "%future added value_desc": "Composant inconnu",
    "Configuration name": "Nom de la configuration",
    "Add event countdown": "Ajouter un compte à rebours de l'événement",
    "A countdown will be displayed until the event starts":
      "Un compte à rebours sera affiché jusqu'au début de l'événement",
    "Not saved": "Non enregistré",
    "Please fix any errors and try again":
      "Veuillez corriger les erreurs et réessayer",
    "Give the configuration a name": "Donnez un nom à la configuration",
    "Try to give it a relevant name, to help you quickly find it":
      "Essayez de lui donner un nom pertinent pour vous aider à le trouver rapidement",
    "Pick a theme": "Choisissez un thème",
    "The theme will set colours and logo for your configuration":
      "Le thème définira les couleurs et le logo de votre configuration",
    "Add an event (optional)": "Ajouter un événement (facultatif)",
    "A countdown will be displayed on the configuration until the event starts":
      "Un compte à rebours sera affiché sur la configuration jusqu'au début de l'événement",
    "Add a Teamworks schedule": "Ajouter un calendrier Teamworks",
    "Upload media": "Télécharger des médias",
    "Add cards (optional)": "Ajouter des cartes (facultatif)",
    "Add multiple Teamworks schedules":
      "Ajouter plusieurs calendriers Teamworks",
    "Unknown component": "Composant inconnu",
    "Events from the selected profiles will be shown on the configuration":
      "Les événements des profils sélectionnés seront affichés sur la configuration",
    "You can upload images, videos or add YouTube videos":
      "Vous pouvez télécharger des images, des vidéos ou ajouter des vidéos YouTube",
    "The configuration will rotate through the cards one at a time":
      "La configuration fera défiler les cartes une par une",
    "Each schedule will have its own column on the configuration":
      "Chaque calendrier aura sa propre colonne sur la configuration",
    "A multiday schedule will display the events from the selected profiles across multiple days":
      "Un calendrier sur plusieurs jours affichera les événements des profils sélectionnés sur plusieurs jours",
    "Save the configuration": "Enregistrer la configuration",
    "Once you are happy with your updates, save the changes":
      "Une fois que vous êtes satisfait de vos mises à jour, enregistrez les modifications",
    "Take a tour": "Faites une visite",
    "Remove component": "Supprimer le composant",
    "Are you sure you want to remove this component?":
      "Êtes-vous sûr de vouloir supprimer ce composant ?",
    "Add weather": "Ajouter la météo",
    "A weather widget will be displayed to show current weather":
      "Un widget météo s'affichera pour afficher la météo actuelle",
    "Hide past events": "Masquer les événements passés",
    "Announcements with matching tags will be displayed on screen":
      "Les annonces avec des tags correspondants seront affichées à l'écran",
    "Display weather information for multiple locations across different days":
      "Afficher les informations météorologiques pour plusieurs emplacements à travers différents jours",
    "Grace period (minutes)": "Période de grâce (minutes)",
    "Number of minutes to keep events on screen after they have ended":
      "Nombre de minutes à conserver les événements sur l'écran après leur fin",
  },
  AnnouncementsScreen: {
    "Announcement text is required": "Le texte de l'annonce est obligatoire",
    "Announcements updated": "Annonces mises à jour",
    "Something went wrong": "Quelque chose s'est mal passé",
    "Announcements allow you to post important information to be displayed in banners across all devices.":
      "Les annonces vous permettent de publier des informations importantes à afficher dans des bannières sur tous les appareils.",
    Announcements: "Annonces",
    "Loading announcements": "Chargement des annonces",
    "Manage announcements": "Gérer les annonces",
    "Announcements are displayed on all screens, at all times":
      "Les annonces sont affichées sur tous les écrans, à tout moment",
    "No announcements yet": "Aucune annonce pour l'instant",
    "New announcement": "Nouvelle annonce",
    "Save announcement": "Enregistrer l'annonce",
    "Note: announcements must be a single line. New lines will be ignored.":
      "Remarque: les annonces doivent être sur une seule ligne. Les nouvelles lignes seront ignorées.",
    "Delete announcement": "Supprimer l'annonce",
    "Are you sure you want to remove this announcement?":
      "Êtes-vous sûr(e) de vouloir supprimer cette annonce ?",
    "{{count}} Announcement": "{{count}} Annonce",
    "{{count}} Announcement_other": "{{count}} Annonces",
    Content: "Contenu",
    "Last updated": "Dernière mise à jour",
    "Display mode": "Mode d'affichage",
    "Should the announcement be displayed on all playlists and configurations, or only on those with matching tags?":
      "L'annonce doit-elle être affichée sur toutes les playlists et configurations, ou uniquement sur celles avec des tags correspondants ?",
    "Display on all": "Afficher sur tout",
    Tagged: "Étiqueté",
    Tags: "Tags",
    "Add start and end times": "Ajouter les heures de début et de fin",
    "Set the start and end time of the announcement":
      "Définir l'heure de début et de fin de l'annonce",
    "Starts at": "Commence à",
    "Ends at": "Se termine à",
    "End time should be after the start time":
      "L'heure de fin doit être postérieure à l'heure de début",
    "End time cannot be in the past":
      "L'heure de fin ne peut pas être dans le passé",
  },
  CardsFormField: {
    "Add card": "Ajouter une carte",
    "Add image": "Ajouter une image",
    "Remove image": "Supprimer l'image",
    "Remove card": "Supprimer la carte",
    "Are you sure you want to remove this card?":
      "Êtes-vous sûr de vouloir supprimer cette carte ?",
    "No cards added": "Aucune carte ajoutée",
    "Card title": "Titre de la carte",
    "Drag and drop to re-order cards":
      "Faites glisser et déposez pour réorganiser les cartes",
    "Move up": "Déplacer vers le haut",
    "Move down": "Déplacer vers le bas",
    Scheduled: "Planifié",
    "Starts at": "Commence à",
    "Ends at": "Se termine à",
  },
  MediaFormField: {
    "Invalid YouTube link, please try again":
      "Lien YouTube invalide, veuillez réessayer",
    "File not supported, please upload an image or video":
      "Fichier non pris en charge, veuillez télécharger une image ou une vidéo",
    "Upload image/video": "Télécharger une image/vidéo",
    "Add YouTube": "Ajouter YouTube",
    "Add YouTube video": "Ajouter une vidéo YouTube",
    "Video title": "Titre de la vidéo",
    "YouTube link": "Lien YouTube",
    Image: "Image",
    Video: "Vidéo",
    YouTube: "YouTube",
    "Something went wrong, file not uploaded":
      "Quelque chose s'est mal passé, fichier non téléchargé",
    "Cancel file upload?": "Annuler le téléchargement du fichier ?",
    "Are you sure you want to cancel this upload?":
      "Êtes-vous sûr de vouloir annuler ce téléchargement ?",
    "Upload image": "Télécharger une image",
    "Upload video": "Télécharger une vidéo",
    "Uploading {{uploadPct}}%": "Téléchargement {{uploadPct}}%",
    "Upload complete": "Téléchargement terminé",
    Name: "Nom",
    "Number seconds image displays before moving to next media item":
      "Nombre de secondes pendant lesquelles l'image est affichée avant de passer à l'élément multimédia suivant",
    "Duration (seconds)": "Durée (secondes)",
    "Add image": "Ajouter une image",
    "Add video": "Ajouter une vidéo",
    "Delete media": "Supprimer le média",
    "Are you sure you want to delete this media?":
      "Êtes-vous sûr de vouloir supprimer ce média ?",
    "Update duration": "Mettre à jour la durée",
    "Edit duration": "Modifier la durée",
    "Remove media": "Supprimer le média",
    "No media added": "Aucun média ajouté",
  },
  MultiColumnScheduleFormField: {
    "Add column": "Ajouter une colonne",
    "Column title": "Titre de la colonne",
    "Remove column": "Supprimer la colonne",
    "Are you sure you want to remove this column?":
      "Êtes-vous sûr de vouloir supprimer cette colonne ?",
    "Hide past events": "Masquer les événements passés",
    "Grace period (minutes)": "Période de grâce (minutes)",
    "Number of minutes to keep events on screen after they have ended":
      "Nombre de minutes à conserver les événements sur l'écran après leur fin",
  },
  AuthProvider: {
    "Fetching active users": "Récupération des utilisateurs actifs",
    "Setting organisations": "Configuration des organisations",
    "Logging you in": "Connexion en cours",
  },
  AccessDeniedScreen: {
    "You don't have access to Digital Displays":
      "Vous n'avez pas accès aux Affichages Numériques",
    "Please contact Teamworks customer support to request access":
      "Veuillez contacter le support client de Teamworks pour demander l'accès",
    "Sign out": "Déconnexion",
    "Customer support": "Support client",
    Reload: "Recharger",
  },
  DeviceGroupsTab: {
    "Delete group": "Supprimer le groupe",
    "Are you sure you want to delete this group?":
      "Êtes-vous sûr de vouloir supprimer ce groupe?",
    "Device group deleted successfully":
      "Groupe de périphériques supprimé avec succès",
    "Something went wrong": "Quelque chose s'est mal passé",
    "Device group created successfully":
      "Groupe de périphériques créé avec succès",
    "Group updated": "Groupe mis à jour",
    "New group": "Nouveau groupe",
    "Device groups allow you to group your display devices under one name.":
      "Les groupes de périphériques vous permettent de regrouper vos périphériques d'affichage sous un seul nom.",
    "Device groups": "Groupes d'appareils",
    "{{count}} Device group": "{{count}} Groupes de dispositifs",
    "{{count}} Device group_other": "{{count}} Groupes de dispositifs",
    "Search groups...": "Rechercher des groupes...",
    "Group name": "Nom de groupe",
    "Device count": "Nombre d'appareils",
    "Fetching groups": "Récupération de groupes",
    "No groups": "Aucun groupe",
    "Group name is required": "Le nom du groupe est obligatoire",
    "Please select at least 2 devices":
      "Veuillez sélectionner au moins 2 appareils",
    "Create group": "Créer un groupe",
    "Update group": "Groupe de mise à jour",
    "Fetching devices...": "Récupération des appareils...",
    "Select devices": "Sélectionnez les appareils",
    Devices: "Dispositifs",
    Groups: "Groupes",
    "Number devices": "Nombre de dispositifs",
  },
  ErrorState: {
    "Something went wrong": "Quelque chose s'est mal passé",
    "Please try again, or contact Teamworks support if the issue persists":
      "Veuillez réessayer ou contacter le support de Teamworks si le problème persiste",
  },
  ProductToursProvider: {
    Back: "Retour",
    Next: "Suivant",
    Finish: "Terminer",
  },
  Drawer: {
    Close: "Fermer",
  },
  InitialSearchParamsProvider: {
    "Initializing URL params": "Initialisation des paramètres URL",
  },
  PreviewsProvider: {
    "Redirecting to preview": "Redirection vers l'aperçu",
  },
  PlaylistsScreen: {
    Playlists: "Listes de lecture",
    "New playlist": "Nouvelle liste de lecture",
    "{{count}} Playlist": "{{count}} Liste de lecture",
    "{{count}} Playlist_other": "{{count}} Listes de lecture",
    Name: "Nom",
    Tags: "Tags",
    "Loading playlists": "Chargement des listes de lecture",
    "No playlists have been setup yet":
      "Aucune liste de lecture n'a été configurée",
    "Playlists allow you to join multiple configurations together.":
      "Les listes de lecture permettent de joindre plusieurs configurations ensemble.",
    "No configurations selected": "Aucune configuration sélectionnée",
    "Add configuration": "Ajouter une configuration",
    Cancel: "Annuler",
    "Create playlist": "Créer une liste de lecture",
    "Delete playlist": "Supprimer la liste de lecture",
    "Are you sure you want to delete this playlist?":
      "Êtes-vous sûr de vouloir supprimer cette liste de lecture ?",
    "Loading playlist": "Chargement de la liste de lecture",
    "Save changes": "Enregistrer les modifications",
    "Configuration already in playlist":
      "Configuration déjà dans la liste de lecture",
    "The selected configuration is already part of this playlist, do you want to add it again?":
      "La configuration sélectionnée fait déjà partie de cette liste de lecture, voulez-vous l'ajouter à nouveau?",
    "Remove from playlist": "Retirer de la liste de lecture",
    Configuration: "Configuration",
    "Number loops": "Nombre de boucles",
    Active: "Actif",
    "Playlist updated": "Liste de lecture mise à jour",
    "Something went wrong": "Quelque chose s'est mal passé",
    "Last updated": "Dernière mise à jour",
    l_layout_loop_description:
      "Les éléments multimédias se répéteront un certain nombre de fois avant de passer à la configuration suivante",
    media_only_loop_description:
      "Les éléments multimédias se répéteront un certain nombre de fois avant de passer à la configuration suivante",
    basic_loop_description:
      "Le calendrier se répétera un certain nombre de fois avant de passer à la configuration suivante",
    multi_column_schedule_loop_description:
      "Le calendrier se répétera un certain nombre de fois avant de passer à la configuration suivante",
    multi_day_schedule_loop_description:
      "Le calendrier se répétera un certain nombre de fois avant de passer à la configuration suivante",
    schedule_only_loop_description:
      "Le calendrier se répétera un certain nombre de fois avant de passer à la configuration suivante",
    "%future added value_loop_description": "Type de configuration inconnu",
    mvp_loop_description: "Type de configuration inconnu",
    nutrition_loop_description:
      "Le menu ou la station de restauration sera répété un certain nombre de fois avant de passer à la configuration suivante",
    weather_loop_description: "Afficher la météo pour plusieurs emplacements",
    timed_description:
      "La configuration restera à l'écran pendant un certain temps avant de passer à la configuration suivante",
    Looped: "En boucle",
    Timed: "Minuté",
    "Duration (minutes)": "Durée (minutes)",
    "Search playlists...": "SRechercher des playlists...",
    "Edit configuration": "Modifier la configuration",
    "New configuration": "Nouvelle configuration",
    "Loading configuration": "Chargement de la configuration",
    "Add an existing configuration to the playlist":
      "Ajouter une configuration existante à la playlist",
    "Create a new configuration to add to the playlist":
      "Créer une nouvelle configuration à ajouter à la playlist",
    "No playlists found for '{{searchText}}'":
      "Aucune liste de lecture n’a été trouvée pour '{{searchText}}'",
    "Last active at {{lastActiveAt}}": "Dernière activité à {{lastActiveAt}}",
    "Device count": "Nombre d'appareils",
    "Loading screen devices": "Chargement des appareils sur l'écran",
    "Announcements with matching tags will be displayed on screen":
      "Les annonces avec des tags correspondants seront affichées à l'écran",
  },
  PlaylistPicker: {
    Playlist: "Liste de lecture",
    "Clear playlist": "Effacer la playlist",
    "No playlist selected": "Aucune playlist sélectionnée",
    "Searching playlists": "Recherche de playlists",
    "No playlists found for '{{searchText}}'":
      "Aucune playlist trouvée pour '{{searchText}}'",
    "No playlists found": "Aucune playlist trouvée",
  },
  TableSearchField: {
    "Clear search text": "Effacer le texte de recherche",
  },
  SettingsScreen: {
    "Organisation settings": "Paramètres de l'organisation",
    "Permission groups": "Groupes d'autorisations",
    "User access": "Accès utilisateur",
    "IP Addresses": "Adresses IP",
    "Loading settings": "Chargement des paramètres",
  },
  PermissionGroupsTab: {
    "New permission group": "Nouveau groupe d'autorisations",
    "Permission groups allow you to specify which hub profiles a user has access to when setting up a schedule":
      "Les groupes d'autorisations vous permettent de spécifier à quels profils hub un utilisateur peut accéder lors de la création d'un programme",
    "{{count}} Permission group": "{{count}} Groupe d'autorisations",
    "{{count}} Permission group_other": "{{count}} Groupes d'autorisations",
    "Search permission groups...": "Rechercher des groupes d'autorisations...",
    Name: "Nom",
    "Last updated": "Dernière mise à jour",
    "Loading permission groups": "Chargement des groupes d'autorisations",
    "No permission groups yet": "Aucun groupe d'autorisations pour le moment",
    "No permission groups found for '{{searchText}}'":
      "Aucun groupe d'autorisations trouvé pour '{{searchText}}'",
    "Permission groups allow you set what hub profiles a user has access to":
      "Les groupes d'autorisations vous permettent de définir à quels profils hub un utilisateur peut accéder",
    "{{count}} hub profile": "{{count}} profil de moyeu",
    "{{count}} hub profile_other": "{{count}} profils hub",
    "Permission group created": "Groupe d'autorisations créé",
    "Something went wrong": "Une erreur est survenue",
    "Loading permission group": "Chargement du groupe d'autorisations",
    "Delete permission group": "Supprimer le groupe d'autorisations",
    "Are you sure you want to delete this permission group?":
      "Êtes-vous sûr de vouloir supprimer ce groupe d'autorisations ?",
    "Permission group deleted": "Groupe d'autorisations supprimé",
    "Permission group updated": "Groupe d'autorisations mis à jour",
    "Save changes": "Enregistrer les modifications",
    "Permission group name": "Nom du groupe d'autorisations",
    "Permitted hub profiles": "Profils de hub autorisés",
    "Only permitted profiles will appear for users to select when managing display schedules":
      "Seuls les profils autorisés apparaîtront pour que les utilisateurs les sélectionnent lors de la gestion des horaires d'affichage",
    "Hub access": "Accès Hub",
    "Nutrition access": "Accès Nutrition",
    Hub: "Hub",
    Nutrition: "Nutrition",
    "Accessible apps": "Applications accessibles",
  },
  UsersTab: {
    "Contact Teamworks support to add or remove users":
      "Contactez le support Teamworks pour ajouter ou supprimer des utilisateurs",
    "New user": "Nouvel utilisateur",
    "This is a list of users who have access to Digital Displays":
      "Voici une liste des utilisateurs ayant accès aux Affichages Numériques",
    "{{count}} User": "{{count}} Utilisateur",
    "{{count}} User_other": "{{count}} Utilisateurs",
    "Search users...": "Rechercher des utilisateurs...",
    Name: "Nom",
    "Last updated": "Dernière mise à jour",
    "Loading users": "Chargement des utilisateurs",
    "Loading user": "Chargement de l'utilisateur",
    "No users yet": "Pas encore d'utilisateurs",
    "No users found for '{{searchText}}'":
      "Aucun utilisateur trouvé pour '{{searchText}}'",
    "Super admin": "Super admin",
    "{{count}} permission group": "{{count}} groupe d'autorisations",
    "{{count}} permission group_other": "{{count}} groupes d'autorisations",
    "User updated": "Utilisateur mis à jour",
    "Something went wrong": "Quelque chose s'est mal passé",
    "Save changes": "Enregistrer les modifications",
    superadmin_description:
      "Cet utilisateur est un super administrateur. Il a accès à tous les profils de hub, quels que soient les groupes d'autorisations qui lui sont attribués.",
    admin_description:
      "Cet utilisateur a uniquement accès aux profils de hub qui lui sont attribués via des groupes d'autorisations.",
    "%future added value_description": "Rôle inconnu",
  },
  ManageIpAddressesTab: {
    "Edit IP addresses": "Modifier les adresses IP",
    "For systems which do not support our QR setup, these permitted IP addresses can be used to allow you access to hardcoded URLs.":
      "Pour les systèmes qui ne prennent pas en charge notre configuration QR, ces adresses IP autorisées peuvent être utilisées pour vous permettre d'accéder aux URL codées en dur.",
    "{{count}} Permitted IP address": "{{count}} Adresse IP autorisée",
    "{{count}} Permitted IP address_other": "{{count}} Adresses IP autorisées",
    "No IP addresses": "Pas d'adresses IP",
    "You have not added any permitted IP addresses yet":
      "Vous n'avez pas encore ajouté d'adresses IP autorisées",
    "Permitted IP addresses updated": "Adresses IP autorisées mises à jour",
    "Something went wrong": "Quelque chose s'est mal passé",
    "Save changes": "Enregistrer les modifications",
    "No permitted IPs yet": "Pas encore d'IPs autorisées",
    "Add another": "Ajouter une autre",
  },
  PermissionGroupPicker: {
    "Add permission groups": "Ajouter des groupes d'autorisations",
    "No permission groups added": "Aucun groupe d'autorisations ajouté",
    "Show {{count}} more": "Afficher {{count}} de plus",
    "Clear search text": "Effacer le texte de recherche",
    "Search permission groups...": "Rechercher des groupes d'autorisations...",
    "Loading permission groups": "Chargement des groupes d'autorisations",
    "{{count}} hub profile": "{{count}} profil de hub",
    "{{count}} hub profile_other": "{{count}} profils de hub",
    "Remove permission group": "Supprimer le groupe d'autorisations",
    "No users found": "Aucun utilisateur trouvé",
    "We couldn't find any users matching '{{searchText}}'":
      "Nous n'avons trouvé aucun utilisateur correspondant à '{{searchText}}'",
    "No permission groups found": "Aucun groupe d'autorisations trouvé",
    "Confirm selection": "Confirmer la sélection",
  },
  TableProvider: {
    "{{count}} Selected": "{{count}} Sélectionné",
  },
  ComponentPicker: {
    "Pick a component": "Choisissez un composant",
    media: "Médias",
    schedule: "Programme",
    cards: "Cartes",
    multi_day_schedule: "Programme sur plusieurs jours",
    multi_column_schedule: "Programme multi-colonnes",
    nutrition_dining_station: "Station d'affichage de la nutrition",
    nutrition_menu: "Menu de nutrition",
    weather: "Météo",
    "%future added value": "Composant inconnu",
    Add: "Ajouter",
  },
  TagPicker: {
    "Add tags": "Ajouter des tags",
    Clear: "Effacer",
    "Searching tags": "Recherche de tags",
    "No tags selected": "Aucun tag sélectionné",
    "Remove tag": "Supprimer le tag",
    "No tags found matching '{{searchText}}'":
      "Aucun tag trouvé correspondant à '{{searchText}}'",
    "Something went wrong": "Quelque chose s'est mal passé",
    "Create tag '{{tagName}}'": "Créer le tag '{{tagName}}'",
    "Start typing to create a new tag":
      "Commencez à taper pour créer un nouveau tag",
    "Delete tag": "Supprimer le tag",
    "Are you sure you want to delete the tag '{{tagName}}'?":
      "Êtes-vous sûr de vouloir supprimer le tag '{{tagName}}' ?",
    "Tag deleted": "Tag supprimé",
  },
  DeviceScheduleScreen: {
    Devices: "Appareils",
    "Device schedule": "Programme de l'appareil",
    "Schedule item created": "Élément de programme créé",
    "Something went wrong": "Quelque chose s'est mal passé",
    "Please correct any errors and try again":
      "Veuillez corriger les erreurs et réessayer",
    "Create schedule item": "Créer un élément de programme",
    "Schedule item updated": "Élément de programme mis à jour",
    "Update schedule item": "Mettre à jour l'élément de programme",
    "End time should be after the start time":
      "L'heure de fin doit être après l'heure de début",
    "Schedule item cannot be in the past":
      "L'élément de programme ne peut pas être dans le passé",
    "Starts at": "Commence à",
    "Ends at": "Se termine à",
    "Add content": "Ajouter du contenu",
    "Loading schedule": "Chargement du programme",
    "Delete schedule item": "Supprimer l'élément de programme",
    "Are you sure you want to delete this schedule item?":
      "Êtes-vous sûr de vouloir supprimer cet élément de programme ?",
    "Schedule item deleted": "Élément de programme supprimé",
    "Saving this event will shorten or replace {{count}} item. Are you sure you want to continue?":
      "Enregistrer cet événement raccourcira ou remplacera {{count}} élément. Êtes-vous sûr de vouloir continuer ?",
    "Saving this event will shorten or replace {{count}} item. Are you sure you want to continue?_other":
      "Enregistrer cet événement raccourcira ou remplacera {{count}} éléments. Êtes-vous sûr de vouloir continuer ?",
    "Conflicts found": "Conflits trouvés",
  },
  Calendar: {
    Previous: "Précédent",
    Today: "Aujourd'hui",
    Next: "Suivant",
    View: "Vue",
    month: "Mois",
    week: "Semaine",
    work_week: "Semaine de travail",
    day: "Jour",
    agenda: "Agenda",
  },
  ScheduleConflictChecker: {
    "Checking for conflicts": "Vérification des conflits",
    "No conflicts found": "Aucun conflit trouvé",
    "{{count}} conflict found": "{{count}} conflit trouvé",
    "{{count}} conflict found_other": "{{count}} conflits trouvés",
    "Conflicts found": "Conflits trouvés",
  },
  LocationPicker: {
    "Search locations": "Rechercher des emplacements",
    "Clear search text": "Effacer le texte de recherche",
    "Searching locations": "Recherche des emplacements",
    "No results": "Aucun résultat",
  },
  NutritionMenuField: {
    "Please select a menu": "Veuillez sélectionner un menu",
    "Include images": "Inclure des images",
    "Include descriptions": "Inclure des descriptions",
    "Include macros": "Inclure les macros",
    "Unknown menu": "Menu inconnu",
    "Please select a dining station":
      "Veuillez sélectionner un poste de restauration",
    "Unknown dining station": "Poste de restauration inconnu",
  },
  NutritionMenuPicker: {
    "Unknown menu date": "Date du menu inconnu",
    "Must be later than the 'after' time": "Doit être après l'heure 'après'",
    "Menu date": "Date du menu",
    "Search menus": "Rechercher des menus",
    "Nutrition menu": "Menu de nutrition",
    "Remove menu": "Supprimer le menu",
    "Searching menus": "Recherche de menus",
    "{{count}} menu found": "{{count}} menu trouvé",
    "{{count}} menu found_other": "{{count}} menus trouvés",
    "No menus found": "Aucun menu trouvé",
    "Try changing the date range and search again":
      "Essayez de changer la plage de dates et recherchez à nouveau",
    "Dining station": "Poste de restauration",
    "Loading stations": "Chargement des stations",
    "Failed to fetch menus": "Échec du chargement des menus",
    "Something went wrong, please try again":
      "Une erreur s'est produite, veuillez réessayer",
    "Failed to fetch menu dining stations":
      "Échec du chargement des stations de restauration du menu",
  },
  WeatherFormField: {
    "Number of days ahead to fetch weather. Max 7":
      "Nombre de jours à l'avance pour récupérer la météo. Maximum 7",
    "Number of days": "Nombre de jours",
    "Add location": "Ajouter un lieu",
    "Remove location": "Supprimer un lieu",
    "Are you sure you want to remove this location?":
      "Êtes-vous sûr de vouloir supprimer ce lieu ?",
    Date: "Date",
    "Default location": "Lieu par défaut",
    Location: "Lieu",
    "Below, you can add different locations for certain dates, which will override the default location for those days.":
      "Ci-dessous, vous pouvez ajouter différents emplacements pour certaines dates, ce qui remplacera l'emplacement par défaut pour ces jours.",
    "This is the default location for displaying weather, unless overridden by another location on a given date.":
      "Ceci est l'emplacement par défaut pour afficher la météo, sauf s'il est remplacé par un autre emplacement à une date donnée.",
  },
  ScreenConfigFrom: {
    "Dates must be unique": "Dates must be unique",
    "Card text must be at least 4 characters long":
      "Card text must be at least 4 characters long",
  },
};
